import React, { useEffect, useState } from 'react';
import DatePicker from '../DatePicker/index';
import TimePicker from '../TimePicker/index';
import classes from './DateTime.module.css';
import {
  timeInStringFormat,
  dateInStringFormat,
} from '../../utils/appointmentCardValues';
import {
  TIME_FORMAT,
  DATE_FORMAT,
  MIN_MAX_DATE,
  MINUTE_STEP,
} from '../../utils/constants';
import { getDateTime } from '../../utils/appointmentCardValues';

interface StartAndEndDateTime {
  start: Date;
  end: Date;
}

interface DateTimePickerPropsType {
  defaultDate: Date;
  defaultStartTime: Date;
  defaultEndTime: Date;
  dateTimeValue: (item: StartAndEndDateTime) => void;
  minDate: Date;
  isEnabledDate: boolean;
  isEnabledTime: boolean;
}

const defaultDateTimePickerProps = {
  defaultDate: '',
  defaultStartTime: '',
  defaultEndTime: '',
  dateTimeValue: () => {},
  minDate: getDateTime(),
  isEnabledDate: true,
  isEnabledTime: true,
};

function DateAndTimePicker(props: DateTimePickerPropsType) {
  const {
    defaultDate,
    minDate,
    dateTimeValue,
    defaultEndTime,
    defaultStartTime,
    isEnabledDate,
    isEnabledTime,
  } = props;
  const [date, setDate] = useState<Date>(defaultDate);
  const [startTime, setStartTime] = useState<Date>(defaultStartTime);
  const [endTime, setEndTime] = useState<Date>(defaultEndTime);
  const [flag, setFlag] = useState(0);

  function dateHandler(selectedValue: Date) {
    setDate(selectedValue === null ? defaultDate : selectedValue);
  }

  function startTimeHandler(startTime: Date) {
    setStartTime(startTime === null ? defaultStartTime : startTime);
  }

  function endTimeHandler(endTime: Date) {
    setEndTime(endTime === null ? defaultEndTime : endTime);
  }

  if (flag === 1) {
    let startDateTime = new Date(
      dateInStringFormat(date) + ' ' + timeInStringFormat(startTime)
    );
    let endDateTime = new Date(
      dateInStringFormat(date) + ' ' + timeInStringFormat(endTime)
    );
    const startAndEndDateTime: StartAndEndDateTime = {
      start: startDateTime,
      end: endDateTime,
    };
    dateTimeValue(startAndEndDateTime);
    setFlag(0);
  }

  useEffect(() => {
    setFlag(1);
  }, [date, startTime, endTime]);

  return (
    <>
      <div className={classes.dateAndTimePicker}>
        <div className={classes.datePicker}>
          <DatePicker
            label={'Date'}
            minDate={minDate}
            maxDate={MIN_MAX_DATE.maxDate}
            format={DATE_FORMAT.MM_dd_yyyy}
            onValueChange={dateHandler}
            value={defaultDate}
            isEnabled={isEnabledDate}
          />
        </div>
        {/* <div className={classes.timePicker}> */}
        <div className={classes.startTimePicker}>
          <TimePicker
            onValueChange={startTimeHandler}
            label={'Start Time'}
            value={defaultStartTime}
            step={MINUTE_STEP.step}
            format={TIME_FORMAT.hh_mm_a}
            isEnabled={isEnabledTime}
          />
        </div>
        <div className={classes.endTimePicker}>
          <TimePicker
            onValueChange={endTimeHandler}
            label={'End Time'}
            value={defaultEndTime}
            step={MINUTE_STEP.step}
            format={TIME_FORMAT.hh_mm_a}
            isEnabled={isEnabledTime}
          />
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

DateAndTimePicker.defaultProps = defaultDateTimePickerProps;
export default DateAndTimePicker;
