import { useEffect, useMemo, useState } from 'react';
import classes from './index.module.css';
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';
import classNames from 'classnames';

export enum ToasterType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

enum BackgroundColor {
  SUCCESS = '#DCFBEA',
  WARNING = '#FFF6DD',
  INFO = '#E8EFFF',
  ERROR = '#FFDBDB',
}

enum Color {
  SUCCESS = '#13AC5A',
  WARNING = '#FBA524',
  INFO = '#558EFF',
  ERROR = '#E84242',
}
interface ToasterProps {
  title: string;
  setShowToaster: (show: boolean) => void;
  description: string;
  toasterType: ToasterType;
  timeInterval: number;
}

const defaultProps: ToasterProps = {
  title: 'Success',
  setShowToaster: () => {},
  description: 'This is a success toast.',
  toasterType: ToasterType.SUCCESS,
  timeInterval: 5000,
};

const Toaster: React.FC<ToasterProps> = (props: ToasterProps) => {
  const { title, setShowToaster, description, toasterType, timeInterval } =
    props;

  const [show, setShow] = useState(true);

  const [backgroundColor, color] = useMemo(() => {
    return [BackgroundColor[toasterType], Color[toasterType]];
  }, [toasterType]);

  const onToasterClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      setShow(false);
    }, timeInterval);

    return () => {
      clearTimeout(interval);
    };
  }, [timeInterval]);

  useEffect(() => {
    if (!show) {
      // Change the close state of toaster for parent component
      setShowToaster(show);
    }
  }, [show, setShowToaster]);

  if (!show) return null;

  return (
    <div
      className={classNames(
        classes.container,
        classes.notification,
        classes.topRight,
        classes.toast,
        { [classes.toastMoveLeft]: !show, [classes.toasterMoveRight]: show }
      )}
      style={{ backgroundColor }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <div className={classes.titleDescriptionContainer} style={{ color }}>
          <p className={classes.title}>{title}</p>
          <p className={classes.description}>{description}</p>
        </div>
        <button onClick={onToasterClose}>
          <CloseIcon style={{ fill: color }} />
        </button>
      </div>
    </div>
  );
};

Toaster.defaultProps = defaultProps;

export default Toaster;
