import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../../../components/Modal';
import classes from './index.module.css';
import { SignInContext } from '../../../contexts/signin';
import { ReactComponent as DeleteAppointmentIcon } from '../../../images/AppointmentDetailIcons/DeleteAppointmentIcon.svg';
import Button, { ButtonType } from '../../../components/Button';
import Appointment from '../../../services/Appointment';
import { ANALYTICS_EVENTS } from '../../../utils/constants';
import { StyledDiv } from '../../../components/Styled/index';

interface deleteAppointmentProps {
  appId: string;
  reRenderAppointments: () => void;
}

function DeleteAppointment(props: deleteAppointmentProps) {
  const { appId } = props;
  const { authToken, user, fontType } = useContext(SignInContext);
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );
  const [appt_Id, setAppt_Id] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [apptVersion, setApptVersion] = useState<any>();

  useEffect(() => {
    appointmentService.fetchAppointmentDetailsForDeletion(appId)
      .then((result) => {
        setAppt_Id(result.data.getAppointment.id);
        setApptVersion(result.data.getAppointment._version);
      });
  }, [authToken, appId, appointmentService]);

  function handleDelete() {
    setLoading(true);
    appointmentService.deleteAppointment(appt_Id, apptVersion, true)
      .then((result) => {
          props.reRenderAppointments();
          setShowDeleteModal(false);

        appointmentService.createUserAction1(
          user.attributes?.name,
          user.attributes?.email,
          ANALYTICS_EVENTS.APPOINTMENT.delete,
          JSON.stringify({
            appointmentId: appt_Id,
          })
        );
      })
      .finally(() => setLoading(false));
  }

  const [ShowDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => setShowDeleteModal(true)}
      >
        <DeleteAppointmentIcon />
      </div>
      {ShowDeleteModal && (
        <Modal
          isPadding={false}
          modalWidth={false}
          show={ShowDeleteModal}
          header={{
            title: 'Delete Appointment',
            actionItems: [],
          }}
          footer={{
            actionItems: [
              <div className={classes.button}>
                <Button
                  text="Cancel"
                  onClick={() => setShowDeleteModal(false)}
                />
              </div>,
              <div className={classes.button}>
                <Button
                  buttonType={ButtonType.DANGER}
                  text="Confirm"
                  loading={loading}
                  onClick={handleDelete}
                  disabled={appt_Id ? false : true}
                />
              </div>,
            ],
          }}
          onModalClose={() => {
            setShowDeleteModal(false);
          }}
          onOverlayClick={() => {}}
        >
          <StyledDiv font={fontType} className={classes.deleteMessage}>
            Are you sure you want to delete this appointment?
          </StyledDiv>
        </Modal>
      )}
    </>
  );
}

export default DeleteAppointment;
