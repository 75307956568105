export const loginRoutes = [
  {
    ALL_APPOINTMENTS: '/appointments',
  },
  {
    TODAYS_APPOITNMENTS: '/appointments/today',
  },
  {
    TOMORROWS_APPOINTMENTS: '/appointments/tomorrow',
  },
  {
    CALL_LIST: '/call-list/call',
  },
  {
    SHORT_CALL_LIST: '/call-list/short-call',
  },
  {
    REACTIVATION_LIST: '/call-list/reactivation',
  },
  {
    UNSCHEDULED_TREATMENT: '/call-list/unscheduledTreatment',
  },
  {
    SCHEDULE_APPOINTMENT: '/schedule-appointment',
  },
  {
    CANCELLED_APPOINTMENT: '/cancelled-appointment',
  },
  {
    ANALYTICS: '/analytics-chart',
  },
];
