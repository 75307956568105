import React from 'react';
import classes from './index.module.css';

interface ActionItemProps {
  children: JSX.Element | JSX.Element[] | boolean;
  icon: string | JSX.Element;
  tooltip?: string;
  onClick: () => void;
  onBlur: () => void;
}

const defaultProps = {
  children: '',
  icon: '',
  tooltip: '',
  onClick: () => null,
  onBlur: () => null,
};

const ActionItem = (props: ActionItemProps): JSX.Element => {
  const { icon, tooltip, onClick, children, onBlur } = props;
  return (
    <button className={classes.headerIcon} onClick={onClick} onBlur={onBlur}>
      {typeof icon === 'string' ? <img src={icon} alt={tooltip} /> : icon}
      <span className={classes.tooltiptext}>{tooltip}</span>
      {children}
    </button>
  );
};

ActionItem.defaultProps = defaultProps;

export default ActionItem;
