import React from 'react';
import classes from './Loader.module.css';

interface LoaderPropsType {
  type?: string;   // it may be undefined
  borderWidth: 2 | 4 | 6 | 8 | 10; 
  loaderSize: 10 | 20 | 30 | 40 | 50 | 60;
}

const defaultProps = {
  type: 'round',
  borderWidth: 2,
  loaderSize: 50
};

function Loader({ type, borderWidth, loaderSize }: LoaderPropsType) {
  return (
    <>
      <div
        style={{
          height: `${loaderSize}px`,
          width: `${loaderSize}px`,
          borderWidth: `${borderWidth}px`,
        }}
        className={
          type === 'round'
            ? classes.outer_loader_box1
            : classes.outer_loader_box2
        }
      ></div>
    </>
  );
}

Loader.defaultProps = defaultProps;
export default Loader;
