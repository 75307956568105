import React, { useCallback, useContext, useState } from 'react';
import dayjs from 'dayjs';
import { getLocaleCurrency } from '../../utils/appointmentCardValues';
import Clipboard from '../../components/Clipboard';
import { TableTypes } from '../../components/Table/tableTypes';
import classes from './index.module.css';
import _orderBy from 'lodash/orderBy';
import { compareTwoDates } from '../../utils';
import Checkbox from '../../components/form/Checkbox';
import { ReactComponent as ContactAgain } from '../../../src/images/contact_again.svg';
import Toggle from '../../components/ToggleSwitch/Toggle';
import Appointment from '../../services/Appointment';
import { SignInContext } from '../../contexts/signin';

function useAppointmentFields() {
  const { authToken } = useContext(SignInContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [contactNodeId, setContactNodeId] = useState<string>('');
  const [apptVersion, setApptVersion] = useState<number>(0);
  const [appointmentService] = useState(new Appointment(authToken!));
  const [showToggleToaster, setShowToggleToaster] = useState<boolean>(false);
  const [contactAgain, setContactAgain] = useState<boolean>(false);

  const onConfirm = useCallback(
    (apptId: string, appointmentVersion: number, confirmed: boolean) => {
      appointmentService
        .updateIsConfirmedValue(apptId, appointmentVersion, confirmed)
        .then((res: any) => {
          if (res.data.updateAppointment.id === apptId) {
            setShowToggleToaster(true);
          }
        })
        .catch((err) => console.log(err));
    },
    [appointmentService]
  );

  const getAppointmentsFields = useCallback(
    (id: string, appt: TableTypes.TableBody) => ({
      contactNotesNode: (
        <>
          <ul className={classes.contactNotes}>
            {_orderBy(appt.ContactNotes.items, ['createdAt'], 'desc').map(
              (contactNote: any) => (
                <li key={contactNote._id} className="d-flex flex-column mt-2">
                  <span className={classes.contactDate}>
                    <b>{contactNote.contactNotes.username} :</b>{' '}
                    {dayjs(contactNote.contactNotes.createdAt).format(
                      'MM/DD/YYYY hh:mm a'
                    )}
                  </span>
                  {` `}
                  <span className={classes.contactNote}>
                    {contactNote.contactNotes.noteDetails}
                  </span>
                </li>
              )
            )}
          </ul>
        </>
      ),
      contacted: {
        sortValue: compareTwoDates(appt.lastContacted),
        render: () => (
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <Checkbox
              propagateClick
              className="mx-3"
              checked={compareTwoDates(appt.lastContacted)}
              onCheck={(checked: boolean) => {
                if (checked) {
                  setContactNodeId(id);
                  setApptVersion(appt._version);
                  setShowModal(true);
                }
              }}
              isDisabled={compareTwoDates(appt.lastContacted)}
            />
            {compareTwoDates(appt.lastContacted) && (
              <ContactAgain
                title="Contact Again"
                onClick={() => {
                  setContactNodeId(id);
                  setApptVersion(appt._version);
                  setShowModal(true);
                  setContactAgain(true);
                }}
                className={classes.contact_again_icon}
              />
            )}
          </div>
        ),
      },
      isConfirmedNode: {
        sortField: 'isConfirmed',
        render: () => (
          <div>
            <Toggle
              id={id}
              checked={appt.isConfirmed}
              optionLabels={['No', 'Yes']}
              onChange={(confirmed) => onConfirm(id, appt._version, confirmed)}
            />
          </div>
        ),
      },
      lastContacted: (
        <div>{dayjs(appt.lastContacted).format('MM/DD/YYYY')}</div>
      ),
      preferredContactMethod: <span>{appt.preferredContactMethod}</span>,
      ...(appt.age
        ? {
            age: <span>{appt.age.year}</span>,
          }
        : {}),
      minutes: (
        <span>
          {dayjs(appt.apptEndDate).diff(dayjs(appt.apptDate)) / 60000}
        </span>
      ),
      patientIdNode: {
        sortField: 'Patient.patientId',
        render: () => (
          <div>
            <Clipboard
              textValueToCopy={appt.Patient?.patientId}
              timeInterval={2000}
            />
          </div>
        ),
      },
      ...(appt.insurancePartyName
        ? {
            insurancePartyName: <span>{appt.insurancePartyName}</span>,
          }
        : {}),

      ...(appt.Patient.profile.paymentInformation.balance
        ? {
            balance: (
              <span>
                {getLocaleCurrency(
                  appt.Patient.profile.paymentInformation.balance
                )}
              </span>
            ),
          }
        : {}),

      ...(appt.Patient.profile.dob
        ? {
            age: (
              <span>
                {dayjs(new Date()).diff(
                  dayjs(appt.Patient.profile.dob),
                  'year'
                )}
              </span>
            ),
            dob: (
              <span>
                {dayjs(appt.Patient.profile.dob).format('MM-DD-YYYY')}
              </span>
            ),
          }
        : {}),

      ...(appt.parentName
        ? {
            parentName: <span>{appt.parentName}</span>,
          }
        : {}),

      ...(appt.lastSeen
        ? {
            lastSeen: <span>{dayjs(appt.lastSeen).format('MM/DD/YYYY')}</span>,
          }
        : { lastSeen: <span className={classes.lastSeenNA}>{'NA'}</span> }),

      ...(appt.Patient
        ? {
            multiplePhoneNode: (
              <div>
                {appt.Patient.profile.contactInfo.phoneArr?.map(
                  (item: any, i: number) => {
                    return (
                      <div key={i}>
                        <span>{item.phoneType} : </span>
                        <span>{item.phone} </span>
                      </div>
                    );
                  }
                )}
              </div>
            ),
          }
        : {}),
    }),
    [onConfirm]
  );

  return {
    getAppointmentsFields,
    showModal,
    contactNodeId,
    apptVersion,
    showToggleToaster,
    setShowToggleToaster,
    setShowModal,
    setApptVersion,
    setContactNodeId,
    contactAgain,
  };
}

export default useAppointmentFields;
