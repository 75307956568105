import React, { useEffect, useReducer, useState } from 'react';
import {
  StyledOptionsListContainer,
  StyledUnorderedList,
  StyledListItem,
  StyledDropDownOuter,
  StyledSelectedItem,
  StyledSelectedItemText,
  StyledSelectedItemRemoveButton,
  StyledInputField,
} from './NewDropDownStyled';
import { ReactComponent as RemoveButton } from '../../images/dropDownArrow.svg';
import './NewDropDown.css';

type DropDownTypes = {
  data: string[];
  isMulti: boolean;
  isAutoSearch: boolean;
  isRequired: boolean;
  placeholder: string;
  label: string;
  defaultData: string | string[];
  isDisable: boolean;
  onInputChange: (item: string | string[]) => void;
};

const defaultProps = {
  data: [],
  isMulti: false,
  isAutoSearch: false,
  isRequired: false,
  placeholder: 'search...',
  label: '',
  defaultData: '',
  isDisable: false,
  onInputChange: () => {},
};

const initialState = {
  allData: [],
  singleSelectedValue: '',
  multipleSelectedValues: [],
  remainingValues: [],
  suggestedData: [],
  isMulti: false,
};

const reducer = (prevState: any, action: any) => {
  switch (action.type) {
    case 'SET_ALREADY_SELECTED_VALUE_AND_DATA': {
      if (action.value.isMulti) {
        return {
          allData: action.value.data,
          multipleSelectedValues: action.value.defaultData,
          singleSelectedValue: prevState.singleSelectedValue,
          remainingValues: action.value.data,
          suggestedData: action.value.data,
          isMulti: action.value.isMulti,
        };
      } else {
        return {
          allData: [...action.value.data, action.value.defaultData],
          multipleSelectedValues: prevState.multipleSelectedValues,
          singleSelectedValue: action.value.defaultData,
          remainingValues: action.value.data,
          suggestedData: action.value.data,
          isMulti: action.value.isMulti,
        };
      }
    }

    case 'ITEM_SELECTED_FROM_OPTIONS': {
      if (prevState.isMulti) {
        const temp = prevState.remainingValues.filter((i: any) => {
          return i !== action.value;
        });
        return {
          allData: prevState.allData,
          multipleSelectedValues: [
            ...prevState.multipleSelectedValues,
            action.value,
          ],
          singleSelectedValue: prevState.singleSelectedValue,
          remainingValues: temp,
          suggestedData: temp,
          isMulti: prevState.isMulti,
        };
      } else {
        return {
          allData: prevState.allData,
          multipleSelectedValues: prevState.multipleSelectedValues,
          singleSelectedValue: action.value,
          remainingValues: prevState.allData,
          suggestedData: prevState.suggestedData,
          isMulti: prevState.isMulti,
        };
      }
    }

    case 'ITEM_REMOVED_FROM_SELECTED_VALUES': {
      if (prevState.isMulti) {
        const temp = prevState.multipleSelectedValues.filter((i: any) => {
          return i !== action.value;
        });
        return {
          allData: prevState.allData,
          multipleSelectedValues: temp,
          singleSelectedValue: prevState.singleSelectedValue,
          remainingValues: [...prevState.remainingValues, action.value],
          suggestedData: [...prevState.remainingValues, action.value],
          isMulti: prevState.isMulti,
        };
      } else {
        return {
          allData: prevState.allData,
          multipleSelectedValues: prevState.multipleSelectedValues,
          singleSelectedValue: '',
          remainingValues: prevState.allData,
          suggestedData: prevState.allData,
          isMulti: prevState.isMulti,
        };
      }
    }

    case 'AUTO_SEARCH_HANDLE' : {
      const regex = new RegExp(`^${action.value}`, 'i');
      const temp = prevState.remainingValues.sort().filter((v: any) => regex.test(v));
      return {
        ...prevState,
        suggestedData: temp,
      }
    }

    default: {
      return prevState;
    }
  }
};

function NewDropDown(props: DropDownTypes) {
  const {defaultData, onInputChange, isMulti, data, isDisable, isRequired, label, placeholder, isAutoSearch} = props
  // console.log("Hii i am dropdown top", defaultData)

  const [dataObject, dispatch] = useReducer(reducer, initialState);
  const [toggleListOfData, setToggleListOfData] = useState(false);
  const [inputText, setInputText] = useState('');

  // console.log(defaultData)

  useEffect(() => {
    const handleFirstRender = () => {
      if (!isMulti && typeof defaultData !== 'string') {
        console.error(
          'please provide default value as string for single select dropdown'
        );
      } else {
        onInputChange(defaultData);
        dispatch({ type: 'SET_ALREADY_SELECTED_VALUE_AND_DATA', value: {data, isMulti, defaultData}});
      }
    }

    handleFirstRender();

  }, [defaultData, onInputChange, data, isMulti]);

  const removeData = (item: string) => {
    if (props.isMulti) {
      const temp = dataObject.multipleSelectedValues.filter((i: any) => {
        return i !== item;
      });
      props.onInputChange(temp);
      dispatch({ type: 'ITEM_REMOVED_FROM_SELECTED_VALUES', value: item });
    } else {
    }
  };

  // // below code working as blur effect
  // useEffect(() => {
  //   window.addEventListener('click', function (e) {
  //     const element = document.getElementById('optionList');
  //     const event = e.target as Element;
  //     console.log(element, event)

  //     if (
  //       element &&
  //       dataObject..current > 0 &&
  //       !element.contains(event)
  //     ) {
  //       if (suggestionsLength.current === 1.1) {
  //         setSuggestions([]);
  //       }
  //       suggestionsLength.current = 1.1;
  //     }
  //   });

  //   return () =>
  //     window.removeEventListener('click', function (e) {
  //       const element = document.getElementById('optionList');
  //       const event = e.target as Element;
  //       if (
  //         element &&
  //         suggestionsLength.current > 0 &&
  //         !element.contains(event)
  //       ) {
  //         if (suggestionsLength.current === 1.1) {
  //           setSuggestions([]);
  //         }
  //         suggestionsLength.current = 1.1;
  //       }
  //     });
  // }, []);

  const handleListItemClick = (event: any, item: any) => {
    event.stopPropagation();
    setInputText('');
    setToggleListOfData(false)
    if (props.isMulti) {
      const temp = [...dataObject.multipleSelectedValues, item];
      props.onInputChange(temp);
      dispatch({ type: 'ITEM_SELECTED_FROM_OPTIONS', value: item });
    }
    else{
      props.onInputChange(item);
      dispatch({ type: 'ITEM_SELECTED_FROM_OPTIONS', value: item });
    }
  };

  const getOptionsList = () => {
    return (
      toggleListOfData && (
        <StyledOptionsListContainer id="optionList">
          <div tabIndex={0}>
            <StyledUnorderedList>
              {dataObject.suggestedData.length
                ? dataObject.suggestedData.map((item: any) => (
                    <StyledListItem
                      key={item}
                      onClick={(e) => handleListItemClick(e, item)}
                    >
                      {item}
                    </StyledListItem>
                  ))
                : null}
            </StyledUnorderedList>
          </div>
        </StyledOptionsListContainer>
      )
    );
  };

  const handleAutoSearch = (e: any) => {
    setInputText(e.target.value);
    dispatch({type: 'AUTO_SEARCH_HANDLE', value: e.target.value})
  }

  const renderInputElementAfterSelectedValues = () => {
    return (
      <>
          {isAutoSearch && <StyledInputField
            onClick={()=>setToggleListOfData(true)}
            type="text"
            onChange={(e) => handleAutoSearch(e)}
            placeholder={dataObject.singleSelectedValue ? 'Search...' : placeholder}
            disabled={props.isDisable}
            value={inputText}
          />}
      <span
        onClick={() => setToggleListOfData((prev) => !prev)}
        className="p_t_2"
        data-testid={'dropdown_arrow'}
      ></span>
     </>
    );
  };

  const renderSelectedValuesAndThenInput = () => {
    return (
      <StyledDropDownOuter>
        {props.isMulti ? (
          dataObject.multipleSelectedValues?.length ? (
            (dataObject.multipleSelectedValues as string[]).map((item: any) => (
              <StyledSelectedItem key={item}>
                <StyledSelectedItemText>{item}</StyledSelectedItemText>{' '}
                <StyledSelectedItemRemoveButton
                  onClick={(e) => removeData(item)}
                >
                  {' '}
                  <RemoveButton data-testid={'cross_icon'} />
                </StyledSelectedItemRemoveButton>
              </StyledSelectedItem>
            ))
          ) : null
        ) : (

          <StyledSelectedItem>
            <StyledSelectedItemText>
              {dataObject.singleSelectedValue}
            </StyledSelectedItemText>{' '}
            <StyledSelectedItemRemoveButton
              onClick={(e) => removeData(dataObject.singleSelectedValue)}
            >
              {' '}
              <RemoveButton data-testid={'cross_icon'} />
            </StyledSelectedItemRemoveButton>
          </StyledSelectedItem>
        )}

        {renderInputElementAfterSelectedValues()}
      </StyledDropDownOuter>
    );
  };

  return (
    <>
  {/* {console.log("Hii i am dropdown down", defaultData)} */}

      <div
        className="outermost_dropdown_holder"
        style={
          isDisable
            ? {
                background: 'rgb(236, 236, 236)',
                pointerEvents: 'none',
              }
            : { background: '' }
        }
      >
        <p className="legend">
          {label}
          <span className="red">{isRequired ? ' *' : ''}</span>
        </p>

        {renderSelectedValuesAndThenInput()}
      </div>
      {getOptionsList()}
    </>
  );
}

NewDropDown.defaultProps = defaultProps;
export default NewDropDown;
