import React, { useContext, useState } from 'react';
import { SchedulerGridConf, giveHeight } from '../../utils/schedulerGridConf';
import classes from './Cell.module.css';
import AppointmentCard from '../AppointmentFilledCard';
import Modal from '../Modal';
import { ReactComponent as AppointmentDateIcon } from '../../images/AppointmentDetailIcons/AppointmentDateIcon.svg';
import { ReactComponent as AppointmentTimeIcon } from '../../images/AppointmentDetailIcons/AppointmentTimeIcon.svg';
import { ReactComponent as PhoneIcon } from '../../images/AppointmentDetailIcons/PhoneIcon.svg';
import { ReactComponent as DoctorIcon } from '../../images/AppointmentDetailIcons/DoctorIcon.svg';
import { ReactComponent as LocationIcon } from '../../images/AppointmentDetailIcons/LocationIcon.svg';
import CreateAppointment from '../../layouts/Appointments/CreateAppointment';
import {
  dateInStringFormat,
  getDateTime,
  timeInStringFormat,
} from '../../utils/appointmentCardValues';
import EditAppointment from '../../layouts/Appointments/EditAppointment';
import DeleteAppointment from '../../layouts/Appointments/DeleteAppointment';
import { WEEKDAYS } from '../../utils/constants';
import dayjs from 'dayjs';
import { CellProps } from './schedulerTypes';
import RankTable from '../RankTable/RankTable';
import { StyledDiv, StyledLi } from '../Styled';
import { SignInContext } from '../../contexts/signin';

const headerHeight = SchedulerGridConf.headerHeight.value;
const startTime = SchedulerGridConf.time.startTime;
const columWidth = SchedulerGridConf.columWidth.value;
const primaryUnAvailable = SchedulerGridConf.color.primary.unavailable;
const secondaryUnAvailable = SchedulerGridConf.color.secondary.unavailable;

const defaultProps: CellProps = {
  type: 'unavailable',
  timing: {
    startTime: '',
    endTime: '',
  },
  index: 0,
  view: 'Primary',
  apptCardView: 'Primary',
  align: true,
  name: 'default name',
  subName: 'default subname',
  patientId: 'default patientId',
  patientPhone: 999999999,
  apptId: 'default appt Id',
  apptDate: '',
  apptEndDate: '',
  reRenderAppointments: () => {},
  setCurrentDateOfSchedulerGrid: (val: Date) => {},
  currentDate: new Date(),
  providerName: 'default provider name',
  providerType: 'dentist',
  provider_id: 'default provider Id',
  isCancelled: false,
};

function Cell(props: CellProps) {
  // const [isModalMinimized, setIsModalMinimized] = useState(false);
  const { fontType } = useContext(SignInContext);

  const isModalMinimized = false;

  const [startTH, setStartTH] = useState<number>();
  const [startTM, setStartTM] = useState<number>();

  const [endTH, setEndTH] = useState<number>();
  const [endTM, setEndTM] = useState<number>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [y, setY] = useState<number>(0);
  const [isDown, setIsDown] = useState(false);
  const [downTimeYValue, setDownTimeYValue] = useState(0);
  const [showCreateAppointment, setShowCreateAppointment] = useState(false);
  const [showModalTable, setShowModalTable] = useState<boolean>(false);
  const oncreateAppointmentClose = () => {
    setShowCreateAppointment(false);
  };

  const getPositionXY = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const bnds = (e.target as HTMLElement).getBoundingClientRect();
    const y = Math.ceil(e.clientY - bnds.top);
    setY(y);
  };
  const getDownYValue = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const bnds = (e.target as HTMLElement).getBoundingClientRect();
    const y = Math.ceil(e.clientY - bnds.top);
    setY(y);
    setDownTimeYValue(y);
  };
  const getUpYValue = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const bnds = (e.target as HTMLElement).getBoundingClientRect();
    const y = Math.ceil(e.clientY - bnds.top);
    setY(y);
  };

  const fun1 = () => {
    const ll = Math.floor(
      6 +
        Math.round(
          (downTimeYValue + giveHeight(startTime, props.timing.startTime)) *
            (30 / 45)
        ) /
          60
    );
    setStartTH(ll);
  };
  const fun2 = () => {
    const ll =
      10 *
      Math.floor(
        Math.floor(
          Math.round(
            (downTimeYValue + giveHeight(startTime, props.timing.startTime)) *
              (30 / 45)
          ) % 60
        ) / 10
      );
    setStartTM(ll);
  };

  const fun3 = () => {
    const ll = Math.floor(
      6 +
        Math.round(
          (y + giveHeight(startTime, props.timing.startTime)) * (30 / 45)
        ) /
          60
    );
    setEndTH(ll);
  };

  const fun4 = () => {
    const ll =
      10 *
      Math.floor(
        Math.floor(
          Math.round(
            (y + giveHeight(startTime, props.timing.startTime)) * (30 / 45)
          ) % 60
        ) / 10
      );
    setEndTM(ll);
  };
  return (
    <>
      {props.type === 'booked' && (
        <div
          className={`${classes.slot_for_booked}`}
          key={props.index}
          style={{
            position: 'absolute',
            top: `${
              headerHeight +
              giveHeight(
                startTime,
                timeInStringFormat(new Date(props.apptDate))
              )
            }px`,
            height: `${giveHeight(
              timeInStringFormat(new Date(props.apptDate)),
              timeInStringFormat(new Date(props.apptEndDate))
            )}px`,
            width: `${columWidth}px`,
          }}
          onClick={() => {
            if (!props.isCancelled) {
              !isModalMinimized && setShowModal(true);
            } else {
              if (props.apptCardView === 'Danger')
                !isModalMinimized && setShowModalTable(true);
            }
            // !isModalMinimized && (!props.isCancelled && setShowModal(true))
          }}
        >
          <>
            <AppointmentCard
              startTime={
                new Date(
                  `${dateInStringFormat(
                    new Date(props.apptDate)
                  )} ${timeInStringFormat(new Date(props.apptDate))}`
                )
              }
              endTime={
                new Date(
                  `${dateInStringFormat(
                    new Date(props.apptEndDate)
                  )} ${timeInStringFormat(new Date(props.apptEndDate))}`
                )
              }
              patientName={props.name}
              doctorName={props.subName}
              viewName={props.apptCardView}
              align={props.align ? 'left' : 'right'}
              isCancelled={props.isCancelled}
            />
          </>
          {showModal && (
            <Modal
              isPadding={false}
              show={showModal}
              header={{
                title: props.name,
                actionItems: [
                  <EditAppointment
                    // editIcon={true}
                    patientName={props.name}
                    contactNumber={props.patientPhone.toString()}
                    appointmentDate={new Date('04/21/2022')}
                    appointmentStartTime={new Date('04/21/2022 10:10')}
                    appointmentEndTime={new Date('04/21/2022 12:20')}
                    dentistName={props.subName}
                    hygienistName={['Johnson', 'Smith']}
                    appointmentLocation={'B1F101'}
                    appointmentId={props.apptId}
                    patientId={props.patientId}
                    reRenderAppointments={props.reRenderAppointments}
                  />,
                  <DeleteAppointment
                    appId={props.apptId}
                    reRenderAppointments={props.reRenderAppointments}
                  />,
                ],
              }}
              onModalClose={() => {
                setShowModal(false);
              }}
              onOverlayClick={() => {}}
            >
              <div className={classes.mainContainer}>
                <div className="row">
                  <div className="col-6">
                    <div className={classes.appointmentDateTimeLocation}>
                      <StyledDiv font={fontType}>
                        <AppointmentDateIcon /> &nbsp;{' '}
                        {WEEKDAYS[new Date(props.apptDate).getDay()]},{' '}
                        {dayjs(props.apptDate).format('MMM DD')}
                      </StyledDiv>
                      <StyledDiv font={fontType}>
                        <AppointmentTimeIcon /> &nbsp;{' '}
                        {timeInStringFormat(new Date(props.apptDate))} -{' '}
                        {timeInStringFormat(new Date(props.apptEndDate))}
                      </StyledDiv>
                      <StyledDiv font={fontType}>
                        <PhoneIcon /> &nbsp; {props.patientPhone}
                      </StyledDiv>
                      <StyledDiv font={fontType}>
                        <LocationIcon />
                        &nbsp; &nbsp; B1F101
                      </StyledDiv>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className={classes.dentistsHygienistsAppointmentDetails}
                    >
                      <StyledDiv font={fontType} className={classes.doctor_hygienist}>
                        <DoctorIcon />
                        &nbsp;&nbsp; Dentists
                      </StyledDiv>
                      <ul>
                        <StyledLi font={fontType}>{props.subName}</StyledLi>
                      </ul>

                      <StyledDiv font={fontType} className={classes.doctor_hygienist}>
                        <DoctorIcon />
                        &nbsp;&nbsp; Hygienists
                      </StyledDiv>
                      <ul></ul>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}

      {props.type === 'unavailable' && (
        <div
          className={classes.slot_for_non_available}
          key={props.index}
          style={{
            position: 'absolute',
            top: `${
              headerHeight + giveHeight(startTime, props.timing.startTime)
            }px`,
            height: `${giveHeight(
              props.timing.startTime,
              props.timing.endTime
            )}px`,
            width: `${columWidth}px`,
            backgroundColor: `${
              props.view === 'Primary'
                ? primaryUnAvailable
                : secondaryUnAvailable
            }`,
          }}
        ></div>
      )}
      {props.type === 'available' && (
        <div
          className={classes.slot_for_available}
          key={props.index}
          style={{
            cursor: `${props.isCancelled ? 'not-allowed' : ''}`,
            position: 'absolute',
            top: `${
              headerHeight + giveHeight(startTime, props.timing.startTime)
            }px`,
            height: `${giveHeight(
              props.timing.startTime,
              props.timing.endTime
            )}px`,
            width: `${columWidth}px`,
            backgroundColor: `${
              props.view === 'Primary'
                ? 'rgba(122, 245, 178,0.5)'
                : 'rgba(250, 247, 173,0.5)'
            }`,
          }}
          onMouseMove={(e) => {
            !isModalMinimized && getPositionXY(e);
          }}
          onMouseDown={(e) => {
            !isModalMinimized && getDownYValue(e);
            !isModalMinimized && setIsDown(true);
          }}
          onMouseUp={(e) => {
            !isModalMinimized && getUpYValue(e);
            !isModalMinimized && setIsDown(false);
            !isModalMinimized && fun1();
            !isModalMinimized && fun2();
            !isModalMinimized && fun3();
            !isModalMinimized && fun4();
            setShowCreateAppointment(
              !props.isCancelled &&
                !isModalMinimized &&
                true &&
                y - downTimeYValue > 10
                ? true
                : false
            );
          }}
        >
          {!props.isCancelled &&
            !isModalMinimized &&
            isDown &&
            y - downTimeYValue > 5 && (
              <div
                className={classes.selectionPart}
                onKeyUp={() => console.log('Upped')}
                style={{
                  marginTop: `${downTimeYValue}px`,
                  height: `${1 * (y - downTimeYValue)}px`,
                  backgroundColor: `${isDown ? '#4F8BC3' : ''}`,
                }}
              >
                {Math.floor(
                  6 +
                    Math.round(
                      (downTimeYValue +
                        giveHeight(startTime, props.timing.startTime)) *
                        (30 / 45)
                    ) /
                      60
                )}
                :
                {10 *
                  Math.floor(
                    Math.floor(
                      Math.round(
                        (downTimeYValue +
                          giveHeight(startTime, props.timing.startTime)) *
                          (30 / 45)
                      ) % 60
                    ) / 10
                  )}
                {' '}-{' '}
                {Math.floor(
                  6 +
                    Math.round(
                      (y + giveHeight(startTime, props.timing.startTime)) *
                        (30 / 45)
                    ) /
                      60
                )}
                :
                {10 *
                  Math.floor(
                    Math.floor(
                      Math.round(
                        (y + giveHeight(startTime, props.timing.startTime)) *
                          (30 / 45)
                      ) % 60
                    ) / 10
                  )}
              </div>
            )}
        </div>
      )}
      {showModalTable && (
        <Modal
          isPadding={true}
          show={showModalTable}
          header={{
            title: 'Appointments',
            actionItems: [],
          }}
          onModalClose={() => {
            setShowModalTable(false);
          }}
          onOverlayClick={() => {}}
        >
          <RankTable />
        </Modal>
      )}
      {showCreateAppointment && (
        <>
          <CreateAppointment
            minimumDate={getDateTime()}
            appointmentDate={props.currentDate}
            appointmentStartTime={new Date(`05/10/2022 ${startTH}:${startTM}`)}
            appointmentEndTime={new Date(`05/10/2022 ${endTH}:${endTM}`)}
            showCreateAppointment={showCreateAppointment}
            oncreateAppointmentClose={oncreateAppointmentClose}
            reRenderAppointments={props.reRenderAppointments}
            providerName={props.providerName}
            providerType={props.providerType}
            provider_id={props.provider_id}
          />
        </>
      )}
    </>
  );
}
Cell.defaultProps = defaultProps;
export default Cell;
