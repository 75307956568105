import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SignInContext } from '../../contexts/signin';
import { StyledDiv } from '../Styled/index';

interface TimePickerPropsType {
  step: number;
  format: string;
  label: string;
  value: Date;
  onValueChange: (item: Date) => void;
  isEnabled: boolean;
}

function TimePicker(props: TimePickerPropsType) {
  const { step, format, label, value, onValueChange, isEnabled } = props;
  const { fontType } = useContext(SignInContext);

  const [time, setTime] = useState<Date>(value);

  useEffect(() => {
    setTime(value);
  }, [value]);

  return (
    <>
      <div
        className="timeBorder"
        style={
          isEnabled ? { background: '' } : { background: 'rgb(236, 236, 236)' }
        }
      >
        <div className="timeLabel">
          <StyledDiv font={fontType}>
            {label}
            <span>*</span>
          </StyledDiv>
        </div>
        <div
          className="time-Picker"
          style={
            isEnabled
              ? { background: '' }
              : { background: 'rgb(236, 236, 236)' }
          }
        >
          <TimePickerComponent
            placeholder="Select Time"
            step={step}
            format={format}
            value={time}
            showClearButton={false}
            onChange={(e: any) => {
              onValueChange(e.target.value);
              setTime(e.target.value);
            }}
            enabled={isEnabled}
          />
        </div>
      </div>
    </>
  );
}

export default TimePicker;
