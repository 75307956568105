import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import classes from './InputSuggestion.module.css';
import classNames from 'classnames';
import { SignInContext } from '../../contexts/signin';
import { StyledDiv, StyledInput, StyledP } from '../Styled/index';

interface InputSuggestionTypes {
  data: string[];
  placeholder: string;
  label: string;
  isRequired: boolean;
  color: string;
  onInputChange: (item: string) => void;
  noSuggestionsMessage?: string;
  defaultValue: string;
}

const defaultProps = {
  data: [],
  placeholder: '',
  label: '',
  isRequired: false,
  color: '',
  onInputChange: () => {},
  noSuggestionsMessage: 'Search Content Not Found',
  defaultValue: '',
};

const InputSuggestion: React.FC<InputSuggestionTypes> = (
  props: InputSuggestionTypes
) => {
  const {
    data,
    placeholder,
    label,
    isRequired,
    color,
    onInputChange,
    noSuggestionsMessage,
    defaultValue,
  } = props;
  const { fontType } = useContext(SignInContext);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState(defaultValue);
  const [resFound, setResFound] = useState(true);

  useEffect(() => {
    setSearchText(defaultValue);
  }, [defaultValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let searchVal = e.target.value;
    let newSuggestions: string[] = [];
    if (searchVal.length > 0) {
      newSuggestions = data
        .sort()
        .filter((e) => e.toLowerCase().includes(searchVal.toLowerCase()));
      setResFound(newSuggestions.length !== 0 ? true : false);
    }
    setSuggestions(newSuggestions);
    setSearchText(searchVal);
  };

  const suggestedText = (value: string) => {
    setSearchText(value);
    onInputChange(value);
    setSuggestions([]);
  };

  const getSuggestions = () => {
    if (suggestions.length === 0 && searchText !== '' && !resFound) {
      return (
        <div className={classes.searchContentNotFound}>
          {noSuggestionsMessage}
        </div>
      );
    }
    return (
      <div className={classes.inputSuggestions_ul}>
        <ul>
          {suggestions.map((item, index) => {
            return (
              <div
                key={item + index}
                onClick={() => suggestedText(item)}
                className={classes.input_suggestions}
              >
                <li>{item}</li>
              </div>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className={classes.inputSuggestions_search_container}>
      <StyledP 
        font={fontType}
        className={classNames(classes.label, classes.labelForSuggestions, {
          'background-white': label,
          red: color === 'red',
        })}
      >
        {label}
        <span className="red">{isRequired ? ' *' : ''}</span>
      </StyledP>
      <StyledInput
        font={fontType}
        type="text"
        placeholder={placeholder}
        value={searchText}
        onChange={handleChange}
      />
      <StyledDiv font={fontType} className={classes.getInputSuggestions}>{getSuggestions()}</StyledDiv>
    </div>
  );
};

InputSuggestion.defaultProps = defaultProps;

export default InputSuggestion;
