import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import DateAndTimePicker from '../../../components/DateAndTimePicker';
import Input, { InputFieldType } from '../../../components/Input';
import DropDown from '../../../components/DropDown';
import classes from './EditAppointment.module.css';
import Toggle from '../../../components/ToggleSwitch/Toggle';
import { SignInContext } from '../../../contexts/signin';
import { ReactComponent as EditAppointmentIcon } from '../../../images/AppointmentDetailIcons/EditAppointmentIcon.svg';
import { ANALYTICS_EVENTS, WEEKDAYS } from '../../../utils/constants';
import { timeInStringFormat } from '../../../utils/appointmentCardValues';
import Loader from '../../../components/Loader/Loader';
import Appointment from '../../../services/Appointment';
import { StyledP } from '../../../components/Styled/index';
import { getCookie } from '../../../utils'

 interface EditAppointmentPropsType {
  patientName: string;
  contactNumber: string;
  appointmentDate: Date;
  appointmentStartTime: Date;
  appointmentEndTime: Date;
  hygienistName: string[];
  appointmentLocation: string;
  dentistName: string;
  patientId: string;
  appointmentId: string;
  reRenderAppointments: () => void;
}

interface EditedData {
  apptId: string;
  apptDate: string;
  apptEndDate: string;
  doctorId: string;
  // patientId: string;
  preferredHygenistId: string;
  isAvailShortList: string;
  locationId: string;
}

const EditAppointment = (props: EditAppointmentPropsType) => {
  const { authToken, user, fontType } = useContext(SignInContext);
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );
  const {
    // patientName,
    // contactNumber,
    // appointmentDate,
    // patientId,
    appointmentStartTime,
    appointmentEndTime,
    appointmentId,
    // hygienistName,
    // dentistName,
    // appointmentLocation,
  } = props;
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);
  const [patientName, setPatientName] = useState<string>('');
  const [patientId, setPatientId] = useState<string>('');
  const [doctorName, setDoctorName] = useState<string>('');
  // const [hygienistName, setHygienistName] = useState<string[]>([]);
  const [allHygienistName, setAllHygienistName] = useState<string[]>([]);
  const [startTime, setStartTime] = useState<Date>(appointmentStartTime);
  const [endTime, setEndTime] = useState<Date>(appointmentEndTime);
  const [startTimeInString, setStartTimeInString] = useState<string>('');
  const [endTimeInString, setEndTimeInString] = useState<string>('');
  const [doctorNameList, setDoctorNameList] = useState<any>();
  const [hygienistNameList, setHygienistNameList] = useState<any>();
  const [doctorDetails, setDoctorDetails] = useState<any>();
  const [hygienistDetails, setHygienistDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [appDate, setAppDate] = useState<Date>();
  const [shortCall, setShortCall] = useState<boolean>(true);
  const [doctorId, setDoctorId] = useState<string>('');
  const [hygienistId, setHygienistId] = useState<string>('');
  // const [patient_Id, setPatient_Id] = useState<string>('');
  const [day, setDay] = useState<string>('');
  const [app_Id, setApp_Id] = useState<string>('');
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<any>();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [locationId, setLocationId] = useState<string>('');
  const [locationName, setLocationName] = useState<string>('');
  const [lomaScore, setLomaScore] = useState<number>();
  const [apptVersion, setApptVersion] = useState<any>();

  const editedData: EditedData = {
    apptId: app_Id,
    apptDate: startTime.toISOString(),
    apptEndDate: endTime.toISOString(),
    doctorId: doctorId,
    // patientId: patient_Id,
    preferredHygenistId: hygienistId,
    locationId: locationId,
    isAvailShortList: shortCall === true ? 'yes' : 'no',
  };

  const startAndEndTime = (data: any) => {
    editedData.apptDate = data.start;
    editedData.apptEndDate = data.end;
    if (data.start >= data.end) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
    let s = timeInStringFormat(data.start).split(':').join('');
    let e = timeInStringFormat(data.end).split(':').join('');
    setStartTimeInString(s);
    setEndTimeInString(e);
    setStartTime(data.start);
    setEndTime(data.end);
  };

  const locationHandler = (locationValue: string) => {
    const id = locationDetails.find((item: any) => {
      return item.identificationNumber === locationValue;
    })._id;
    locationValue !== '' ? setLocationId(id) : setLocationId('');
  };

  const hygienistNameHandler = (hygienist: string[]) => {
    // setHygienistName(hygienist);
    let allHygienistId1: any = [];
    hygienist.forEach((item1: any) => {
      hygienistDetails.forEach((item2: any) => {
        if (item1 === item2.name) {
          allHygienistId1.push(item2._id);
        }
      });
    });
    let allHygienistId = allHygienistId1.join(',');
    setHygienistId(allHygienistId);
  };

  const dentistNameHandler = (dentist: string |string[]) => {
    console.log(dentist)
    setDoctorName(dentist as string);
    const id = doctorDetails?.find((item: any) => {
      return item.name === dentist;
    })?._id;
    setDoctorId(id);
  };

  const editedDataHandler = () => {
    setLoadingButton(true);
    appointmentService.updateAppointment(
      appointmentId, apptVersion, startTime.toISOString(), endTime.toISOString()
    )
      .then((res) => {
        appointmentService.createUserAction1(
          user.attributes?.name,
          user.attributes?.email,
          ANALYTICS_EVENTS.APPOINTMENT.update,
          JSON.stringify({
            appointmentId: appointmentId,
            apptDate: startTime,
            apptEndDate: endTime
          })
        );
        props.reRenderAppointments();
        setShowEditModal(false);
      })
      .catch((error: any) => console.log(error))
      .finally(() => setLoadingButton(false));
  };

  useEffect(() => {
    appointmentService.fetchAppointmentDetails(appointmentId)
      .then((result) => {
        setPatientId(result.data.getAppointment.Patient.patientId);
        setApp_Id(result.data.getAppointment.id);
        setPatientName(result.data.getAppointment.Patient.name);
        setDoctorName(result.data.getAppointment.Provider.name);
        setDoctorId(result.data.getAppointment.Provider.providerId);
        setLomaScore(result.data.getAppointment.Patient.lomaScore);
        const prefferedHygienistList = result.data.getAppointment.Hygienist.items.map(
          (item: any) => {
            return item.provider.name;
          }
        );
        // const prefferedHygienist_Id = result.data.getAppointment.Hygienist.items.map(
        //   (item: any) => {
        //     return item.providerID;
        //   }
        // );
        setAllHygienistName(prefferedHygienistList);
        let d = new Date(result.data.getAppointment.apptDate);
        let e = new Date(result.data.getAppointment.apptEndDate);
        setAppDate(d);
        setStartTime(d);
        setEndTime(e);
        setShortCall(result.data.getAppointment.availForShortList);
        setLocationId(result.data.getAppointment.Location ? result.data.getAppointment.Location.id : '');
        setLocationName(result.data.getAppointment.Location ? result.data.getAppointment.Location.identificationNumber : "");
        setApptVersion(result.data.getAppointment._version);
      }).catch((error) => {
        console.log('error', error);
      })
      .finally(() => setLoading(false));
  }, [authToken, appointmentId, appointmentService]);

  useEffect(() => {
    setDay(WEEKDAYS[startTime.getDay()].toLowerCase());
    function fetchDoctors() {
      appointmentService
        .fetchschedulerGridProviders(day, getCookie("selectedDentalPracticeId"))
        .then((result: any) => {
          const filterResult = result.data.listProviders.items.filter(
            (item: any) => {
              if (
                (item.availableTimeSlots[0].startTime <=
                  parseInt(startTimeInString, 10) &&
                  item.availableTimeSlots[0].endTime >=
                    parseInt(endTimeInString, 10)) ||
                (item.availableTimeSlots[1].startTime <=
                  parseInt(startTimeInString, 10) &&
                  item.availableTimeSlots[1].endTime >=
                    parseInt(endTimeInString, 10))
              ) {
                return item;
              } else {
                return null;
              }
            }
          );
          const dentistDetails: any = filterResult.filter((item: any) => {
            return item.providerType === 'dentist';
          });
          setDoctorDetails(dentistDetails);
          const dentistList = dentistDetails.map((item: any) => {
            return item.name;
          });
          setDoctorNameList(dentistList);

          const hygienistDetails: any = filterResult.filter((item: any) => {
            return item.providerType === 'hygienist';
          });
          setHygienistDetails(hygienistDetails);
          const providerList = hygienistDetails.map((item: any) => {
            return item.name;
          });
          setHygienistNameList(providerList);
          // }
        })
        .catch((error: any) => {
          console.log('error', error);
        }); 
    }
    if (startTimeInString && endTimeInString && day) {
      fetchDoctors();
    } else {
      setDoctorDetails([]);
      setHygienistDetails([]);
      setDoctorNameList([]);
    }
  }, [
    appointmentService,
    day,
    endTimeInString,
    startTimeInString,
    authToken,
    startTime,
    endTime,
    // locationId,
  ]);

  // useEffect(() => {
  //   fetch(`${API_ROUTES.GET_PATIENT_BY_ID}?patientId=${patientId}`, {
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setPatient_Id(result.data?._id);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [authToken, patientId]);

  // useEffect(() => {
  //   fetch(API_ROUTES.GET_LOCATION_FILTERED_DETAILS, {
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setLocationDetails(result.data);
  //       const newLocations: any = result?.data?.map((item: any) => {
  //         return item.identificationNumber;
  //       });
  //       setLocationList(newLocations);
  //     });
  // }, [authToken]);
  useEffect(() => {
    appointmentService
      .fetchLocationList(getCookie('selectedDentalPracticeId'))
      .then((result) => {
        setLocationDetails(result.data.listLocations.items);
        const newLocations: any = result.data.listLocations.items.map(
          (item: any) => {
            return item.identificationNumber;
          }
        );
        setLocationList(newLocations);
      });
  }, [authToken, appointmentService]);

  return (
    <>
      <div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => setShowEditModal(true)}
        >
          <EditAppointmentIcon />
        </div>

        <Modal
          show={showEditModal}
          header={{
            title: 'Edit Appointment',
            actionItems: [],
          }}
          footer={{
            actionItems: [
              <div className={classes.updateBtn}>
                <Button
                  text="Update"
                  loading={loadingButton}
                  onClick={editedDataHandler}
                />
              </div>,
            ],
          }}
          onModalClose={() => setShowEditModal(false)}
          onOverlayClick={() => { }}
        >
          <div className={classes.editAppointment}>
            {loading ? (
              <div className={classes.loader}>
                <Loader type={''} />
              </div>
            ) : (
              <div>
                <div className={classes.patient}>
                  <div className={classes.patientId}>
                    <Input
                      type={InputFieldType.TEXT}
                      label="Patient ID"
                      placeholder="Patient ID"
                      isRequired={true}
                      defaultValue={patientId}
                      disabled={true}
                    />
                  </div>
                  <div className={classes.patientName}>
                    <Input
                      type={InputFieldType.TEXT}
                      label="Patient Name"
                      placeholder="Patient Name"
                      defaultValue={patientName}
                      disabled={true}
                    />
                  </div>
                  <div className={classes.lomaScoreField}>
                    <Input
                      type={InputFieldType.TEXT}
                      label="Loma Score"
                      defaultValue={lomaScore}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={classes.dateTimeAndShortCall}>
                  <div className={classes.dateAndTimePicker}>
                    <DateAndTimePicker
                      defaultDate={appDate}
                      defaultStartTime={startTime}
                      defaultEndTime={endTime}
                      dateTimeValue={startAndEndTime}
                    />
                  </div>
                  <div className={classes.shortCall}>
                    <StyledP font={fontType}>Short Call</StyledP>
                    <div className={classes.toggleSwitch}>
                      <Toggle
                        optionLabels={['No', 'Yes']}
                        checked={shortCall}
                        onChange={(checked: boolean) => setShortCall(checked)}
                      />
                    </div>
                  </div>
                </div>
                {timeError && (
                  <p className={classes.timeError}>
                    End Time should be greater than Start Time{' '}
                  </p>
                )}
                <div className={classes.dentistName}>
                  {/* <NewDropDown
                  label="Select Dentist"
                  isAutoSearch
                  isRequired
                  placeholder="Select Dentist"
                  data={doctorNameList}
                  defaultData={doctorName}
                  onInputChange={dentistNameHandler}
                  /> */}
                  <DropDown
                    label="Select Dentist"
                    isAutoSearch={true}
                    isRequired={true}
                    placeholder="Select Dentist"
                    data={doctorNameList}
                    defaultValue={doctorName}
                    onInputChange={dentistNameHandler}
                  />
                </div>
                <div className={classes.hygienistName}>
                  <DropDown
                    label="Select Hygienist"
                    isAutoSearch={true}
                    isRequired={true}
                    placeholder="Select Hygienist"
                    isMulti={true}
                    data={hygienistNameList}
                    defaultValues={allHygienistName}
                    onInputChanges={hygienistNameHandler}
                  />
                </div>
                <div className={classes.locationAndBtn}>
                  <div className={classes.editlocation}>
                    <DropDown
                      label="Physical Location"
                      isAutoSearch={true}
                      placeholder="Physical Location"
                      defaultValue={locationName}
                      data={locationList}
                      onInputChange={locationHandler}
                    // isRequired={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EditAppointment;
