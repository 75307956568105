import React, { useContext, useEffect, useState } from 'react';
import classes from './index.module.css';
import FilterData, { FilterDataTypes } from './FilterData';
import classNames from 'classnames';
import { SignInContext } from '../../contexts/signin';
import {StyledTd,StyledH5,StyledSpan} from '../Styled/index';

interface ChartFilterProps {
  onFilterChange: (value: Date | string) => void;
}

const defaultChartFilterProps: ChartFilterProps = {
  onFilterChange: (value: Date | string) => {},
};

function ChartFilter(props: ChartFilterProps) {
  const { fontType } = useContext(SignInContext);
  const { onFilterChange } = props;
  const [filterDisplayName, setFilterDisplayName] =
    useState<string>('All Data');
  const [filterValue, setFilterValue] = useState<Date | string>('All Data');
  const [flag, setFlag] = useState<number>(0);

  if (flag === 1) {
    onFilterChange(filterValue);
    setFlag(0);
  }

  useEffect(() => {
    setFlag(1);
  }, [filterValue]);

  const handleFilterClick = (item: FilterDataTypes) => {
    if (item.displayName === 'All Data') {
      setFilterDisplayName('All Data');
      setFilterValue('All Data');
    } else {
      setFilterDisplayName(item.displayName);
      var date = new Date();
      date.setDate(date.getDate() - item.value);
      setFilterValue(date);
    }
  };

  return (
    <>
      <div className={classes.chartFilter}>
        <div className={classes.headingAndFilter}>
          <StyledH5 font={fontType} className={classes.filterHeading}>Filter</StyledH5>
          <StyledSpan font={fontType} className={classes.filterStatus}>({filterDisplayName})</StyledSpan>
        </div>
        <table className={classes.chartTable}>
          <thead>
            <tr className={classes.chartFilterRow}>
              {FilterData.map((item: FilterDataTypes) => {
                return (
                  <StyledTd
                    font={fontType}
                    id={item.displayName}
                    key={item.displayName}
                    className={classNames({
                      [classes.activeFilter]:
                        item.displayName === filterDisplayName,
                      [classes.filterValue]: true
                    })}
                    onClick={() => handleFilterClick(item)}
                  >
                    {item.displayName}
                  </StyledTd>
                );
              })}
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
}

ChartFilter.defaultProps = defaultChartFilterProps;

export default ChartFilter;
