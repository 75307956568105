import React, { useContext, useState } from 'react';
import AuthService from '../../services/authService';
import Input, { InputFieldType } from '../../components/Input';
import classes from './changePassword.module.css';
import Button, { Type } from '../../components/Button';
import { SignInContext } from '../../contexts/signin';

interface changepasswordInput {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export default function ChangePassword() {
  const authService = new AuthService();
  const [email] = useState('');
  const [oldPassword] = useState('');
  const [newPassword] = useState('');
  const [confirmNewPassword] = useState('');
  const { user } = useContext(SignInContext);
  const changepasswordInput: changepasswordInput = {
    email: email,
    oldPassword: oldPassword,
    newPassword: newPassword,
    confirmNewPassword: confirmNewPassword,
  };
  const passwordHandler = (oldPassword: string) => {
    changepasswordInput.oldPassword = oldPassword;
  };
  const newPasswordHandler = (newPassword: string) => {
    changepasswordInput.newPassword = newPassword;
  };
  const confirmNewPasswordHandler = (confirmNewPassword: string) => {
    changepasswordInput.confirmNewPassword = confirmNewPassword;
  };

  const submitHandler = (event: any) => {
    authService.changePassword(
      user.attributes?.email,
      changepasswordInput.oldPassword,
      changepasswordInput.newPassword
    );
  };

  return (
    <>
      <form className={classes.width} onSubmit={submitHandler}>
        <div className={classes.marginTop}>
          <Input
            label="Old Password"
            placeholder="Enter Old Password"
            isRequired
            onValueChange={passwordHandler}
            type={InputFieldType.PASSWORD}
          />
        </div>

        <div className={classes.marginTop}>
          <Input
            label="New Password"
            placeholder="Enter New Password"
            isRequired
            onValueChange={newPasswordHandler}
            type={InputFieldType.PASSWORD}
          />
        </div>

        <div className={classes.marginTop}>
          <Input
            label="Re-confirm Password"
            placeholder="Re-Enter New Password"
            isRequired
            onValueChange={confirmNewPasswordHandler}
            type={InputFieldType.PASSWORD}
          />
        </div>

        <div className={classes.marginTop}>
          <Button type={Type.SUBMIT}>Submit</Button>
        </div>
      </form>
    </>
  );
}
