import React, { useContext } from 'react';
import classes from './Appointment.module.css';
import {
  calculateHeightOfAppointmentCard,
  cardStyle,
  padStartOfNumber,
} from '../../utils/appointmentCardValues';
import { cardConfiguration } from '../../utils/appointmentCardConfiguration';
import { SignInContext } from '../../contexts/signin';
import {StyledDiv, StyledP} from '../Styled/index'

interface PropsType {
  startTime: Date;
  endTime: Date;
  patientName: string;
  doctorName: string;
  viewName: string;
  align: string;
  isCancelled: boolean;
}

const defaultAppointmentCardProps = {
  startTime: '',
  endTime: '',
  patientName: '',
  doctorName: '',
  viewName: 'Primary',
  align: 'left',
  isCancelled: true,
};

function AppointmentCard(props: PropsType) {
  const { startTime, endTime, patientName, doctorName, viewName, align, isCancelled } =
    props;
  const { fontType } = useContext(SignInContext);
  let backgroundColor = '';
  let textColor = '';
  let cardAlign: any = align;
  let height = calculateHeightOfAppointmentCard(
    startTime,
    endTime,
    cardConfiguration.appointmentCardHeight.value
  );

  if (viewName === 'Primary') {
    backgroundColor = cardStyle.Primary.backgroundColor;
    textColor = cardStyle.Primary.textColor;
  } else if (viewName === 'Secondary') {
    backgroundColor = cardStyle.Secondary.backgroundColor;
    textColor = cardStyle.Secondary.textColor;
  }else if (viewName === 'Danger') {
    backgroundColor = cardStyle.Danger.backgroundColor;
    textColor = cardStyle.Danger.textColor;
  }

  return (
    <>
      <div
        className={`${classes.card} ${(viewName!=='Danger' && isCancelled) ?classes.normalCard:classes.cardHoverPointer}`}
        style={{
          height: height,
          width: cardConfiguration.appointmentCardWidth.value,
          background: backgroundColor,
          float: cardAlign,
        }}
      >
        <div className={classes.cardTop} style={{ color: textColor }}>
          <StyledP font={fontType} className={classes.patientName}>{patientName}</StyledP>

          <StyledP font={fontType} className={classes.middleLine} style={{ color: textColor }}>
            |
          </StyledP>

          <StyledDiv font={fontType} className={classes.timeSlot} style={{ color: textColor }}>
            {padStartOfNumber(startTime.getHours(), 2, '0')}:
            {padStartOfNumber(startTime.getMinutes(), 2, '0')} -
            {padStartOfNumber(endTime.getHours(), 2, '0')}:
            {padStartOfNumber(endTime.getMinutes(), 2, '0')}
          </StyledDiv>
        </div>

        <div className={classes.doctorName} style={{ color: textColor }}>
          <StyledP font={fontType}>{doctorName}</StyledP>
        </div>
      </div>
    </>
  );
}

AppointmentCard.defaultProps = defaultAppointmentCardProps;
export default AppointmentCard;
