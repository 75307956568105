import React, { useContext } from 'react';
import { SignInContext } from '../../contexts/signin';
import classes from './index.module.css';
import { StyledDiv, StyledSpan } from '../Styled/index'

interface TableBoxField {
  displayName: string;
  value: string | JSX.Element | null;
  colSpan?: number;
}

export interface TableBoxProps {
  title: string;
  align: 'vertical' | 'horizontal';
  fields: JSX.Element[] | TableBoxField[];
  colSpan?: number;
  [propName: string]: any;
}

const defaultTableBoxProps: TableBoxProps = {
  title: '',
  align: 'vertical',
  fields: [],
};

function TableBox(props: TableBoxProps) {
  const { title, align, fields } = props;
  const { fontType } = useContext(SignInContext);
  return (
    <>
      {align === 'vertical' ? (
        <div className={title ? classes.tableBox : classes.table}>
          <StyledDiv font={fontType} className={classes.title}>{title}:</StyledDiv>
          <div className={classes.boxContent}>
            {fields.map((item: any) => (
              <div key={item.displayName}>
                {fields.length > 1 && (
                  <StyledSpan font={fontType} className={classes.displayName}> 
                    {item.displayName}:
                  </StyledSpan>
                )}
                <StyledSpan font={fontType} className={classes.displayValue}>{item.value}</StyledSpan>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={classes.horizontalTableBox}>
          <StyledDiv font={fontType} className={classes.title}>{title}:</StyledDiv>
          <div className={classes.horizontalBoxContent}>
            {fields.map((item: any) => (
              <div key={item.displayName}>
                {fields.length > 1 && (
                  <StyledSpan font={fontType} className={classes.displayName}>
                    {item.displayName}:
                  </StyledSpan>
                )}
                <StyledSpan font={fontType} className={classes.displayValue}>{item.value}</StyledSpan>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

TableBox.defaultProps = defaultTableBoxProps;
export default TableBox;
