import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SignInContext } from '../../contexts/signin';
import Appointment from '../../services/Appointment';
import ChartComponent from '../charts/ChartComponent';
import _groupBy from 'lodash/groupBy';
import _capitalize from 'lodash/capitalize';
import classes from './index.module.css';
import ChartFilter from '../ChartFilter';
import dayjs from 'dayjs';
import { Loader } from '@aws-amplify/ui-react';
import { SAVE_CONTACT_NOTES } from '../../utils/constants';

const colors = ['#2E65F3', '#34D399', '#01B1B1', '#3FBDCD'];

function AnalyticsChart() {
  const { authToken } = useContext(SignInContext);
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<any[]>([]);
  const [filteredDate, setFilteredDate] = useState<string | Date>();
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  const onFilterChange = (filterDate: Date | string) => {
    setFilteredDate(
      filterDate === 'All Data' ? '' : dayjs(filterDate).format('YYYY-MM-DD')
    );
  };

  useEffect(() => {
    setLoading(true);
    appointmentService
      .fetchUserAction(SAVE_CONTACT_NOTES.SAVED, filteredDate)
      .then((res) => {
        const items: any[] = res?.data?.listUserActions?.items;
        if (items) {
          const groupByUsers = _groupBy(items, 'username');
          const groupByListNames = _groupBy(items, 'listType');
          const usernames = Object.keys(groupByUsers);
          const lists = Object.keys(groupByListNames);
          const newLabels = usernames.map(
            (key) => `${key} (${groupByUsers[key].length})`
          );
          setLabels(newLabels);
          const userCountsForList = lists.map((listName) => {
            const userCounts = new Array<number>(newLabels.length);
            const groupByListNameAndUsers = _groupBy(
              groupByListNames[listName],
              'username'
            );
            Object.keys(groupByListNameAndUsers).forEach((username) => {
              const index = usernames.findIndex((name) => name === username);
              if (index !== -1) {
                userCounts[index] = groupByListNameAndUsers[username].length;
              }
            });
            return userCounts.map((count) => count || 0);
          });
          const datasets = userCountsForList.map((userCountArr, index) => ({
            label: _capitalize(lists[index]),
            data: userCountArr,
            fill: false,
            borderColor: 'red',
            backgroundColor: colors[index],
          }));
          setDatasets(datasets);
        }
        items.sort(function (a: any, b: any) {
          return a.username.toLowerCase().trim() >
            b.username.toLowerCase().trim()
            ? 1
            : -1;
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [appointmentService, filteredDate]);

  return (
    <div className={classes.analyticsChartContainer}>
      <ChartFilter onFilterChange={onFilterChange} />
      <div>
        {' '}
        {loading ? (
          <div className="d-flex align-items-center justify-content-center">
            <Loader size="large" style={{ height: '10vh' }} />
          </div>
        ) : (
          <div className={classes.analyticsChart}>
            <ChartComponent
              uniqueChartId="barChart1"
              type="bar"
              labels={labels}
              datasets={datasets}
              options={{
                scales: {
                  yAxis: {
                    title: {
                      display: true,
                      text: 'No. of contacted patients',
                      font: {
                        size: 18,
                      },
                    },
                  },
                  xAxis: {
                    title: {
                      display: true,
                      text: 'Scheduling managers',
                      font: {
                        size: 18,
                      },
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AnalyticsChart;
