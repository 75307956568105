import React, { useRef, MouseEvent, useState, useEffect, FC, useContext } from 'react';
import classes from './index.module.css';
import { ReactComponent as CopyIcon } from '../../images/CopyIcon.svg';
import { ReactComponent as CopiedIcon } from '../../images/CopiedIcon.svg';
import classNames from 'classnames';
import { SignInContext } from '../../contexts/signin';
import {StyledInput, StyledDiv} from '../Styled/index';

interface ClipboardProps {
  textValueToCopy: string;
  timeInterval?: number;
}

const defaultProps: ClipboardProps = {
  textValueToCopy: 'Copy',
  timeInterval: 2000,
};

const Clipboard: FC<ClipboardProps> = (props: ClipboardProps) => {
  const { fontType } = useContext(SignInContext);
  const { textValueToCopy, timeInterval } = props;
  const [showCopyIcon, setShowCopyIcon] = useState<boolean>(true);
  const textAreaRef = useRef<HTMLInputElement>(null);
  const [display, setDisplay] = useState(true);

  const [value, setValue] = useState<string>(textValueToCopy);

  useEffect(() => {
    setValue(textValueToCopy);
  }, [textValueToCopy]);

  const handleCopyText = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    textAreaRef.current?.select();
    navigator.clipboard.writeText(textValueToCopy);
    setShowCopyIcon(false);
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      setShowCopyIcon(true);
    }, timeInterval);

    return () => {
      clearTimeout(interval);
    };
  }, [showCopyIcon, timeInterval]);

  return (
    <>
      <div className={classes.clip}>
        <StyledDiv font={fontType} className={classes.clipBoard}>
          <div
            className={classNames(classes.inputText, {
              [classes.fullText]: !display,
            })}
          >
            <StyledInput
              font= {fontType}
              type="text"
              value={value}
              ref={textAreaRef}
              onChange={() => setValue(value)}
              onMouseEnter={() => setDisplay(false)}
              onMouseLeave={() => setDisplay(true)}
            />
          </div>
          {display && (
            <div className={classes.copyIcon}>
              <button onClick={handleCopyText}>
                {showCopyIcon && (
                  <span>
                    <CopyIcon />
                  </span>
                )}
                {!showCopyIcon && (
                  <span>
                    <CopiedIcon />
                  </span>
                )}
              </button>
            </div>
          )}
        </StyledDiv>
      </div>
    </>
  );
};

Clipboard.defaultProps = defaultProps;

export default Clipboard;
