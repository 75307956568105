import Checkbox from '../form/Checkbox';
import classes from './TableHeaderBar.module.css';
import { ReactComponent as EditIcon } from '../../images/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete_icon.svg';

interface TableHeaderBarProps {
  nRowsSelected: number;
}

const TableHeaderBar = ({ nRowsSelected }: TableHeaderBarProps) => {
  return (
    <div className={`d-flex align-items-center justify-content-between mb-3`}>
      <div className="d-flex align-items-center">
        <Checkbox checked />
        <span className={classes.rowsSelectedtext}>
          <strong>{nRowsSelected}</strong>
          {` `}rows selected
        </span>
        <div className="box-testing d-flex align-items-center ml-5">
          <div className={classes.actionButton} onClick={() => {}}>
            <EditIcon />
          </div>
          <div className={`${classes.actionButton} mx-2`} onClick={() => {}}>
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableHeaderBar;
