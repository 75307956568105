import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { CognitoUserAmplify } from '@aws-amplify/ui';
import AnalyticsMixpanel from '../services/analyticsMixPanel';
import { ANALYTICS_EVENTS, SESSION } from '../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import Appointment from '../services/Appointment';
import AuthService from '../services/authService';
import { removeCookie, setCookie } from '../utils';

interface UserState {
  user: null | CognitoUserAmplify;
  metadata: {
    sessionId: string;
    [other: string]: any;
  };
  userLoading: boolean;
}

interface SignInContextProviderProps {
  children: string | JSX.Element | JSX.Element[] | null;
}

export interface SignInContextType {
  isSignedIn: boolean;
  userLoading: boolean;
  authToken?: string;
  sessionId?: string;
  user: CognitoUserAmplify;
  analytics: AnalyticsMixpanel;
  getUserRolesAndPermissions?: any;
  theme: string;
  onThemeChange: (val: string) => void;
  broadcastChannel?: any;
  onSignIn: (user: CognitoUserAmplify, getUserRoleAndPermissions: any) => void;
  onSignOut: () => void;
  onSignUp: (user: CognitoUserAmplify) => void;
  fontType: string;
  onFontChange: (val: string) => void;
}

export const SignInContext = createContext({
  isSignedIn: false,
  userLoading: true,
} as SignInContextType);

const SignInContextProvider = ({ children }: SignInContextProviderProps) => {
  const [userInfo, setUserInfo] = useState<UserState>({
    user: null,
    metadata: {
      sessionId: uuidv4(),
    },
    userLoading: true,
  });

  const broadcastChannel = useMemo(
    () => new BroadcastChannel('BroadcastChannel'),
    []
  );

  const analytics = useMemo(
    () =>
      new AnalyticsMixpanel({
        ip: true,
        debug: false,
        autotrack: true,
        save_referrer: true,
      }),
    []
  );

  const [theme, setTheme] = useState('light');
  const [fontType, setFontType] = useState('');
  const [payLoad, setPayLoad] = useState<any>(null);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [getUserRolesAndPermissions, setGetUserRolesAndPermissions] =
    useState<any>(null);
  const { authToken } = useContext(SignInContext);
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  const authService = useMemo(() => new AuthService(), []);

  useEffect(() => {
    authService
      .getAuthenticatedUser()
      .then((user) => {
        setUserInfo((userInfo) => ({
          ...userInfo,
          user,
          userLoading: false,
        }));
      })
      .catch((err) => {
        setUserInfo((userInfo) => ({
          ...userInfo,
          userLoading: false,
        }));
        console.log(err);
      });
  }, [authService]);

  const onThemeChange = (val: string) => {
    setTheme(val);
  };

  const onFontChange = (val: string) => {
    setFontType(val);
  };

  const onSignIn = useCallback(
    (signedInUser: CognitoUserAmplify, getUserRoleAndPermissions: any) => {
      setCookie(
        'OrganizationId',
        getUserRoleAndPermissions.userRolesAndPermissionsOrganizationId
      );
      setGetUserRolesAndPermissions(getUserRoleAndPermissions);
      setUserInfo((userInfo) => ({
        ...userInfo,
        user: signedInUser,
      }));
      setPayLoad({
        email: signedInUser.attributes?.email,
        username: signedInUser.username,
        sessionId: userInfo.metadata.sessionId,
      });
      const newUser = {} as CognitoUserAmplify;
      analytics.trackEvent(ANALYTICS_EVENTS.SESSION.SESSION_START, {
        message: 'Session Started',
      });
      analytics.identify(signedInUser.username!);
      analytics.trackEvent(ANALYTICS_EVENTS.AUTH.SIGNIN, userInfo.user!);
      newUser.username = signedInUser.username;
      newUser.attributes = {
        phone_number: '',
        email: signedInUser.attributes?.email || '',
      };
      appointmentService.signInEventLog(ANALYTICS_EVENTS.AUTH.SIGNIN, {
        email: signedInUser.attributes?.email,
        username: signedInUser.username,
        sessionId: userInfo.metadata.sessionId,
      });
    },
    [
      userInfo.user,
      analytics,
      userInfo.metadata.sessionId,
      appointmentService,
    ]
  );

  const onSignUp = useCallback(
    (user: CognitoUserAmplify) => {
      const newUser = {} as CognitoUserAmplify;
      analytics.trackEvent(ANALYTICS_EVENTS.AUTH.SIGNUP, user);
      newUser.username = user.username;
      newUser.attributes = {
        phone_number: '',
        email: user.attributes?.email!,
        password: user.attributes?.password || '',
      };
    },
    [analytics]
  );

  const onSignOut = useCallback(async () => {
    analytics.trackEvent(ANALYTICS_EVENTS.AUTH.SIGNOUT, userInfo.user!);
    analytics.trackEvent(ANALYTICS_EVENTS.SESSION.SESSION_END, {
      message: 'Session Ended',
    });
    broadcastChannel.postMessage(SESSION.LOGOUT);
    try {
      const user = await authService.getAuthenticatedUser();
      setUserInfo((userInfo) => ({
        ...userInfo,
        user: user,
        metadata: {
          ...userInfo.metadata,
          sessionId: '',
        },
      }));
    } catch (error) {
      setUserInfo((userInfo) => ({
        ...userInfo,
        user: null,
        metadata: {
          ...userInfo.metadata,
          sessionId: '',
        },
      }));
    }
    removeCookie('dentalPracticeId');
    setIntervalId(null);
    analytics.resetSession();
  }, [analytics, userInfo.user, authService, broadcastChannel]);

  useEffect(() => {
    if (userInfo.user && payLoad && intervalId === null) {
      setIntervalId(
        setInterval(
          () =>
            appointmentService.signInEventLog(
              ANALYTICS_EVENTS.HEARTBEAT.Heartbeat,
              payLoad
            ),
          30000
        )
      );
    }
    setPayLoad(null);
    return () => clearInterval(intervalId);
  }, [
    userInfo.user,
    appointmentService,
    userInfo.metadata.sessionId,
    payLoad,
    intervalId,
  ]);
  // using useMemo to get better performance, useMemo takes a callback as argument
  const signInValue = useMemo<SignInContextType>(
    () => ({
      user: userInfo.user!,
      isSignedIn: !!userInfo.user,
      authToken: userInfo.user
        ? userInfo.user.getSignInUserSession()?.getAccessToken().getJwtToken()
        : '',
      getUserRolesAndPermissions: getUserRolesAndPermissions,
      theme,
      fontType,
      onFontChange,
      onThemeChange,
      onSignIn,
      onSignOut,
      onSignUp,
      analytics,
      sessionId: userInfo.metadata.sessionId,
      userLoading: userInfo.userLoading,
      broadcastChannel: broadcastChannel,
    }),
    // dependency for useMemo (when signInValue needs to be changed)
    [
      theme,
      fontType,
      onSignIn,
      onSignOut,
      onSignUp,
      userInfo,
      analytics,
      getUserRolesAndPermissions,
      broadcastChannel,
    ]
  );

  return (
    // providing context value: signInValue to all the children/s of caller Component
    <SignInContext.Provider value={signInValue}>
      {children}
    </SignInContext.Provider>
  );
};

export default SignInContextProvider;
