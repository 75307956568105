import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './Sidebar.module.css';
import SidebarContext from '../../contexts/sideBar';
import { SidebarItemType } from './models/SidebarItemTypes';
import SidebarItem from './SidebarItem/SidebarItem';
import { SignInContext } from '../../contexts/signin';
import Loader from '../Loader/Loader';
import styled from 'styled-components';

interface SidebarProps {
  sidebarData: SidebarItemType[];
  openedBy: string;
}

const defaultProps: SidebarProps = {
  sidebarData: [],
  openedBy: '',
};

const StyledDiv = styled.div<{ toggleSideBar: boolean }>`
  @media (min-width: 0px) and (max-width: 500px) {
    display: ${({ toggleSideBar }) => (toggleSideBar ? 'inline' : 'none')};
  }
`;

const Sidebar = ({ sidebarData, openedBy }: SidebarProps) => {
  const { opened, setOpened } = useContext(SidebarContext);
  const { getUserRolesAndPermissions, theme } = useContext(SignInContext);
  const [loading, setLoading] = useState(true);

  let permissions = useRef<string[]>();
  useEffect(() => {
    permissions.current =
      getUserRolesAndPermissions?.UserRoles?.UserPermissions?.modularPermission;
    setLoading(false);
  }, [getUserRolesAndPermissions, setLoading]);

  if (sidebarData.length === 0 && window.innerWidth > 500) {
    return <div className={classes.loader}>
    <Loader loaderSize={30}/>
  </div>;
    
  }
  return (
    <>
      <StyledDiv
        toggleSideBar={opened}
        style={{
          width: `${
            opened ? (openedBy === 'hamburger-click' ? 215 : 215) : 65
          }px`,
        }}
        className={
          theme === 'light'
            ? classes.sideBarContainer
            : classes.darkSideBarContainer
        }
        onMouseEnter={() => setOpened(true, 'hover')}
        onMouseLeave={() => setOpened(false, 'hover')}
      >
        {loading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          sidebarData
            .filter((item) => permissions.current?.indexOf(item.id) !== -1)
            .map((item: SidebarItemType) => {
              return <SidebarItem key={item.id} item={item} />;
            })
        )}
        <br />
        <br />
        {opened ? (
          <div className={classes.copyRight}>©2022 by Truro Inc.</div>
        ) : (
          <div className={classes.copyRight}>©</div>
        )}
      </StyledDiv>
    </>
  );
};
Sidebar.defaultProps = defaultProps;

export default Sidebar;
