import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { SignInContext } from '../../contexts/signin';
import { ReactComponent as Avatar } from '../../images/avatar.svg';
import classes from './profilePage.module.css';
import { StyledSpan } from '../../components/Styled/index';

export default function ProfilePage() {
  const { user, theme, fontType } = useContext(SignInContext);
  return (
    <div
      className={`${classes.profilePageContainer} ${
        theme === 'dark' ? classes.dark : null
      }`}
    >
      <div className={classes.avatar}>
        <Avatar />
      </div>
      <div className={classes.detailsContainer}>
        <div className={classes.details}>
          <StyledSpan font={fontType}
            className={theme === 'light' ? classes.title : classes.darkTitle}
          >
            Name:
          </StyledSpan>{' '}
          <StyledSpan font={fontType} id="userName" className={classes.subtitle}>
            {user.attributes?.name}
          </StyledSpan>
        </div>

        <div className={classes.details}>
          <StyledSpan font={fontType}
            className={theme === 'light' ? classes.title : classes.darkTitle}
          >
            Email:
          </StyledSpan>{' '}
          <StyledSpan font={fontType} id="userEmail" className={classes.subtitle}>
            {user.attributes?.email}
          </StyledSpan>
        </div>

        <div className={classes.details}>
          <StyledSpan font={fontType}
            className={theme === 'light' ? classes.title : classes.darkTitle}
          >
            Designation:
          </StyledSpan>{' '}
          <StyledSpan font={fontType} className={classes.subtitle}>Scheduler</StyledSpan>
        </div>
      </div>
      <div className={classes.buttons}>
        <div className={classes.changePassButton}>
          <Link to="/changePassword">
            <Button>Change Password</Button>
          </Link>
        </div>
        
      </div>
    </div>
  );
}
