import classes from '../Sidebar.module.css';
import { SidebarItemType } from '../models/SidebarItemTypes';
import { ReactComponent as TotalAppointmentsIcon } from '../../../images/Total_Appointments.svg';
import { ReactComponent as TodayAppointmentsIcon } from '../../../images/TodayAppointments.svg';
import { ReactComponent as TomrrowAppointmentsIcon } from '../../../images/Tomrrow_Appointments.svg';
import { ReactComponent as CallListIcon } from '../../../images/CallList.svg';
import { ReactComponent as ShortCallListIcon } from '../../../images/ShortCallList.svg';
import { ReactComponent as ReactivationListIcon } from '../../../images/ReactivationList.svg';
import { ReactComponent as ScheduleAppointmentIcon } from '../../../images/ScheduleAppointment.svg';
import { ReactComponent as UnscheduledTreatmentIcon } from '../../../images/unscheduledTreatment.svg';

import { APP_ROUTES } from '../../../utils/constants';

export const SidebarData: SidebarItemType[] = [
  {
    id: 'ALL_APPOINTMENTS',
    title: 'All Appointments',
    path: [APP_ROUTES.appointments, '/'],
    icon: <TotalAppointmentsIcon className={classes.svgIcon} />,
  },
  {
    id: "TODAYS_APPOINTMENTS",
    title: "Today's Appointments",
    path: [APP_ROUTES.todayAppointments],
    icon: <TodayAppointmentsIcon className={classes.svgIcon} />,
  },
  {
    id: "TOMORROWS_APPOINTMENTS",
    title: "Tomorrow's Appointments",
    path: [APP_ROUTES.tomorrowAppointments],
    icon: <TomrrowAppointmentsIcon className={classes.svgIcon} />,
  },
  {
    id: 'CALL_LIST',
    title: 'Call List',
    path: [APP_ROUTES.callList],
    icon: <CallListIcon className={classes.svgIcon} />,
  },
  {
    id: 'SHORT_CALL_LIST',
    title: 'Short-call List',
    path: [APP_ROUTES.shortCallList],
    icon: <ShortCallListIcon className={classes.svgIcon} />,
  },
  {
    id: 'REACTIVATION_LIST',
    title: 'Reactivation List',
    path: [APP_ROUTES.reactivationList],
    icon: <ReactivationListIcon className={classes.svgIcon} />,
  },
  {
    id: 'UNSCHEDULED_TREATMENT',
    title: 'Unscheduled Treatment',
    path: [APP_ROUTES.unscheduledTreatment],
    icon: <UnscheduledTreatmentIcon className={classes.svgIcon} />,
  },
  {
    id: 'SCHEDULE_APPOINTMENT',
    title: 'Schedule Appointment',
    path: [APP_ROUTES.scheduleAppt],
    icon: <ScheduleAppointmentIcon className={classes.svgIcon} />,
  },
  {
    id: 'CANCELLED_APPOINTMENT',
    title: 'Cancelled Appointment',
    path: [APP_ROUTES.cancelledAppt],
    icon: <ScheduleAppointmentIcon className={classes.svgIcon} />,
  },
  {
    id: 'ANALYTICS',
    title: 'Analytics',
    path: [APP_ROUTES.analyticsChart],
    icon: <ScheduleAppointmentIcon className={classes.svgIcon} />,
  },
];
