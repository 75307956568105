import React, { useState } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import classes from './CalenderDate.module.css';

interface DatePickerPropsType {
  minDate: Date;
  maxDate: Date;
  format: string;
  value: Date;
  onValueChange: (item: Date) => void;
}

function CalenderDatePicker(props: DatePickerPropsType) {
  const { minDate, maxDate, format, value, onValueChange } = props;

  const [date, setDate] = useState<Date>(value);

  return (
    <>
      <div className={classes.calenderDate}>
        <DatePickerComponent
          placeholder="Select Date"
          min={minDate}
          max={maxDate}
          format={format}
          value={date}
          showClearButton={false}
          onChange={(e: any) => {
            onValueChange(e.target.value);
            setDate(e.target.value);
          }}
        />
      </div>
    </>
  );
}

export default CalenderDatePicker;
