import React, { useContext } from 'react';
import classes from './Legend.module.css';
import { legendLabel } from '../../utils/legendConfiguration';
import { SignInContext } from '../../contexts/signin';
import { StyledDiv } from '../Styled/index';

export enum Align {
  hor = 'horizontally',
  ver = 'vertically',
}

interface LegendItem {
  text: string;
  color: string;
  shape: string;
}

interface PropsType {
  align: string;
  legendData: LegendItem[];
}

function Legend({ align, legendData }: PropsType) {
  const { fontType } = useContext(SignInContext);
  return (
    <>
      <div className={classes.outer}>
        <div className={align === Align.hor ? 'd-flex' : 'd-block'}>
          {legendData.map((item: LegendItem) => {
            return (
              <div className={classes.legend} key={item.text}>
                <div
                  className={classes.boxColor}
                  style={{ ...legendLabel[item.shape], background: item.color }}
                ></div>
                <StyledDiv font={fontType} className={classes.boxName}>{item.text} </StyledDiv>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Legend;
