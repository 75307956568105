import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Appointments from './layouts/Appointments';
import Layouts from './layouts';
import React from 'react';
import Login from './layouts/LoginPage';
import SignUp from './layouts/SignupPage';
import ForgotPassword from './layouts/Forgot Password';
// import CallList from './layouts/CallList';
import AuthProvider from './hooks/AuthProvider';
import ProfilePage from './layouts/profilePage/profilePage';
import ChangePassword from './layouts/ChangePassword/changePassword';
import AnalyticsChart from './components/AnalyticsChart';
import Settings from './components/Settings/Settings';
import Notifications from './components/Notifications/Notifications';
import UnscheduledList from './layouts/UnscheduledList';
import ReactivationList from './layouts/ReactivationList';
import CallAndShortCallList from './layouts/CallAndShortCallList';
import NoRouteFound from './components/NoRouteFound/NoRouteFound';
import NewCalendarView from './components/SchedulerGrid/NewCalenderView';

function App() {
  return (
    <>
      <div className="mainContainer">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <AuthProvider>
                  <Layouts />
                </AuthProvider>
              }
            >
              <Route index element={<Appointments />} />
              <Route path="appointments" element={<Appointments />} />
              <Route path="appointments/:apptSlot" element={<Appointments />} />
              {/* <Route path="call-list/:type" element={<CallList />} /> */}
              <Route path="call-list/:type" element={<CallAndShortCallList />} />
              <Route path="call-list/reactivation" element={<ReactivationList />} />
              <Route path="call-list/unscheduledTreatment" element={<UnscheduledList />} />
              
              <Route
                path="schedule-appointment"
                element={<NewCalendarView isCancelled={false} />}
              />
              <Route
                path="cancelled-appointment"
                element={<NewCalendarView isCancelled={true} />}
              />
              <Route path="analytics-chart" element={<AnalyticsChart />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/setting" element={<Settings />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="*" element={<NoRouteFound />}/>

            </Route>
            <Route
              path="/login"
              element={
                <AuthProvider>
                  <Login />
                </AuthProvider>
              }
            />
            <Route
              path="/signup"
              element={
                <AuthProvider>
                  <SignUp />
                </AuthProvider>
              }
            />
            <Route
              path="/forgotPassword"
              element={
                <AuthProvider>
                  <ForgotPassword />
                </AuthProvider>
              }
            />
            
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;