import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import DateAndTimePicker from '../../../components/DateAndTimePicker';
import DropDown from '../../../components/DropDown';
import classes from './CreateAppointment.module.css';
import { SignInContext } from '../../../contexts/signin';
import { ANALYTICS_EVENTS, WEEKDAYS } from '../../../utils/constants';
import { getDateTime } from '../../../utils/appointmentCardValues';
import Toggle from '../../../components/ToggleSwitch/Toggle';
import { timeInStringFormat } from '../../../utils/appointmentCardValues';
import Toaster, { ToasterType } from '../../../components/Toaster';
import InputSuggestion from '../../../components/InputSuggestion';
import Input from '../../../components/Input';
import Appointment from '../../../services/Appointment';
import { getCookie } from '../../../utils';
import { StyledP } from '../../../components/Styled/index';

interface CreateAppointmentPropsType {
  appointmentDate: Date;
  appointmentStartTime: Date;
  appointmentEndTime: Date;
  minimumDate: Date;
  showCreateAppointment: boolean;
  oncreateAppointmentClose: () => void;
  reRenderAppointments: () => void;

  providerName: string;
  providerType: 'dentist' | 'hygienist';
  provider_id: string;
}

interface CreatedValues {
  appointmentPatientId: string;
  apptDate: Date;
  apptEndDate: Date;
  appointmentProviderId: string;
  preferredHygenistId: string;
  appointmentLocationId: string;
  isAvailShortList: boolean;
}

const CreateAppointment = (props: CreateAppointmentPropsType) => {
  const { authToken, user, fontType } = useContext(SignInContext);
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  const {
    appointmentDate,
    appointmentStartTime,
    appointmentEndTime,
    provider_id,
    providerName,
  } = props;
  const [patientId, setPatientId] = useState<string>('');
  const [patientName, setPatientName] = useState<string>('');
  const [pId, setPId] = useState<string>('');
  const [startTime, setStartTime] = useState<Date>(appointmentStartTime);
  const [endTime, setEndTime] = useState<Date>(appointmentEndTime);
  const [dentistName, setDentistName] = useState<string>('');
  const [dentistId, setDentistId] = useState<string>('');
  const [hygienistName, setHygienistName] = useState<string[]>([]);
  const [hygienistId, setHygienistId] = useState<string>('');
  const [timeError, setTimeError] = useState<boolean>(false);
  const [patientError, setPatientError] = useState<boolean>(false);
  const [doctorNameList, setDoctorNameList] = useState<any>();
  const [providerNameList, setProviderNameList] = useState<any>();
  const [doctorDetails, setDoctorDetails] = useState<any>();
  const [hygienistDetails, setHygienistDetails] = useState<any>();
  const [shortCall, setShortCall] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<any>();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [locationId, setLocationId] = useState<string>('');
  const [startTimeInString, setStartTimeInString] = useState<string>('');
  const [endTimeInString, setEndTimeInString] = useState<string>('');
  const [successToaster, setSuccessToaster] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState<any>();
  const [patientIdList, setPatientIdList] = useState<any>();
  const [patientNameList, setPatientNameList] = useState<any>();
  const [lomaScore, setLomaScore] = useState<number>();

  const day = useMemo(() => {
    return WEEKDAYS[startTime.getDay()].toLowerCase();
  }, [startTime]);

  const isEnabled =
    pId &&
    dentistName.length > 0 &&
    hygienistName.length > 0 &&
    !timeError &&
    !patientError;

  const createdAppointmentValues: CreatedValues = {
    appointmentPatientId: pId,
    apptDate: startTime,
    apptEndDate: endTime,
    appointmentProviderId: dentistId,
    preferredHygenistId: hygienistId,
    appointmentLocationId: locationId,
    isAvailShortList: shortCall,
  };

  const startAndEndTime = (data: any) => {
    if (data.start >= data.end) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
    let s = timeInStringFormat(data.start).split(':').join('');
    let e = timeInStringFormat(data.end).split(':').join('');
    setStartTimeInString(s);
    setEndTimeInString(e);
    setStartTime(data.start);
    setEndTime(data.end);
  };

  const hygienistNameHandler = (hygienist: string[] | string) => {
    setHygienistName(hygienist as string[]);
    let allHygienistId1: any = [];
    (hygienist as string[]).forEach((item1: any) => {
      hygienistDetails.forEach((item2: any) => {
        if (item1 === item2.name) {
          allHygienistId1.push(item2.id);
        }
      });
    });
    let allHygienistId = allHygienistId1.join(',');
    setHygienistId(allHygienistId);
  };

  useEffect(() => {
    if (props.providerType === 'dentist') {
      setDentistId(provider_id);
      setDentistName(providerName);
    } else if (props.providerType === 'hygienist') {
      setHygienistId(provider_id);
      setHygienistName([providerName]);
    }
  }, [doctorDetails, provider_id, props.providerType, providerName]);

  const dentistNameHandler = (dentist: string | string[]) => {
    setDentistName(dentist as string);
    const id = doctorDetails.find((item: any) => {
      return item.name === dentist;
    }).id;
    setDentistId(id);
  };

  const locationHandler = (location: string | string[]) => {
    const id = locationDetails.find((item: any) => {
      return item.identificationNumber === location;
    }).id;
    setLocationId(id);
  };

  const patientIdSuggestion = (patientId: string) => {
    const patient = patientDetails.find((item: any) => {
      return item.patientId === patientId;
    });
    setLomaScore(patient.lomaScore);
    setPatientName(patient.name);
    setPId(patient.id);
  };

  const patientNameSuggestion = (patientName: string) => {
    const patient = patientDetails.find((item: any) => {
      return item.name === patientName;
    });
    setLomaScore(patient.lomaScore);
    setPatientId(patient.patientId);
    setPId(patient.id);
  };
  const { getUserRolesAndPermissions } = useContext(SignInContext);
  useEffect(() => {
    function fetchDoctors() {
      appointmentService
        .fetchschedulerGridProviders(day, getCookie("selectedDentalPracticeId"))
        .then((result: any) => {
          const filterResult = result.data.listProviders.items.filter(
            (item: any) => {
              if (
                (item.availableTimeSlots[0].startTime <=
                  parseInt(startTimeInString, 10) &&
                  item.availableTimeSlots[0].endTime >=
                    parseInt(endTimeInString, 10)) ||
                (item.availableTimeSlots[1].startTime <=
                  parseInt(startTimeInString, 10) &&
                  item.availableTimeSlots[1].endTime >=
                    parseInt(endTimeInString, 10))
              ) {
                return item;
              } else {
                return null;
              }
            }
          );
          const dentistDetails: any = filterResult.filter((item: any) => {
            return item.providerType === 'dentist';
          });
          setDoctorDetails(dentistDetails);
          const dentistList = dentistDetails.map((item: any) => {
            return item.name;
          });
          setDoctorNameList(dentistList);

          const hygienistDetails: any = filterResult.filter((item: any) => {
            return item.providerType === 'hygienist';
          });
          setHygienistDetails(hygienistDetails);
          const providerList = hygienistDetails.map((item: any) => {
            return item.name;
          });
          setProviderNameList(providerList);
          // }
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    }
    if (startTimeInString && endTimeInString && day) {
      fetchDoctors();
    } else {
      setDoctorDetails([]);
      setHygienistDetails([]);
      setDoctorNameList([]);
      setDentistId('');
      setDentistName('');
      setProviderNameList([]);
      setHygienistName([]);
    }
  }, [
    day,
    endTimeInString,
    startTimeInString,
    authToken,
    appointmentService,
    getUserRolesAndPermissions,
  ]);

  const createdAppointmentHandler = () => {
    if (patientId === '') {
      setPatientError(true);
    } else {
      setPatientError(false);
    }
    setLoading(true);

    appointmentService
      .createAppointment(
        createdAppointmentValues.apptDate.toISOString(),
        createdAppointmentValues.apptEndDate.toISOString(),
        createdAppointmentValues.appointmentLocationId,
        createdAppointmentValues.appointmentPatientId,
        createdAppointmentValues.appointmentProviderId,
        createdAppointmentValues.isAvailShortList,
        getCookie('selectedDentalPracticeId')
      )
      .then((res: any) => {
        appointmentService
          .createAppointmentProvider(
            res.data.createAppointment.id,
            createdAppointmentValues.preferredHygenistId
          )
          .then((res: any) => {
            setSuccessToaster(true);
            props.oncreateAppointmentClose();
            props.reRenderAppointments();

            appointmentService.createUserAction1(
              user.attributes?.name,
              user.attributes?.email,
              ANALYTICS_EVENTS.APPOINTMENT.create,
              JSON.stringify(createdAppointmentValues)
            );
          })
          .catch((error) => console.log(error))
          .finally(() => setLoading(false));
      });
  };

  useEffect(() => {
    appointmentService
      .fetchLocationList(getCookie('selectedDentalPracticeId'))
      .then((result) => {
        setLocationDetails(result.data.listLocations.items);
        const newLocations: any = result.data.listLocations.items.map(
          (item: any) => {
            return item.identificationNumber;
          }
        );
        setLocationList(newLocations);
      });
  }, [authToken, appointmentService, getUserRolesAndPermissions]);

  useEffect(() => {
    appointmentService.fetchPatientList().then((result) => {
      setPatientDetails(result.data.listPatients.items);
      const patientId: any = result.data.listPatients.items.map((item: any) => {
        return item.patientId;
      });
      setPatientIdList(patientId);
      const patientName: any = result.data.listPatients.items.map(
        (item: any) => {
          return item.name;
        }
      );
      setPatientNameList(patientName);
    });
  }, [authToken, appointmentService]);

  if (!props.showCreateAppointment) return null;

  return (
    <>
      {successToaster && (
        <Toaster
          title={'Success'}
          setShowToaster={setSuccessToaster}
          description={'Appointment Created'}
          toasterType={ToasterType.SUCCESS}
          timeInterval={4000}
        />
      )}
      <div>
        {props.showCreateAppointment && (
          <Modal
            show={props.showCreateAppointment}
            header={{
              title: 'Create Appointment',
              actionItems: [],
            }}
            footer={{
              actionItems: [
                <div className={classes.createBtn}>
                  <Button
                    text="Create Appointment"
                    disabled={!isEnabled}
                    loading={loading}
                    onClick={createdAppointmentHandler}
                  />
                </div>,
              ],
            }}
            onModalClose={() => {
              props.oncreateAppointmentClose();
              setPatientId('');
              setPatientName('');
            }}
            onOverlayClick={() => {}}
            isMinimizable
          >
            <div className={classes.createAppointment}>
              <div className={classes.patientIdAndName}>
                <div className={classes.patientId}>
                  <InputSuggestion
                    color=""
                    data={patientIdList}
                    placeholder={'Patient ID'}
                    label={'Patient ID'}
                    isRequired={true}
                    onInputChange={patientIdSuggestion}
                    defaultValue={patientId}
                  />
                </div>
                <div className={classes.patientName}>
                  <InputSuggestion
                    color=""
                    data={patientNameList}
                    placeholder={'Patient Name'}
                    label={'Patient Name'}
                    isRequired={true}
                    onInputChange={patientNameSuggestion}
                    defaultValue={patientName}
                  />
                </div>
                <div className={classes.lomaScoreField}>
                  <Input
                    label={'Loma Score'}
                    placeholder={'Loma Score'}
                    defaultValue={lomaScore}
                    disabled={true}
                  />
                </div>
              </div>
              <div className={classes.dateTimeAndShortCall}>
                <div className={classes.dateAndTimePicker}>
                  <DateAndTimePicker
                    minDate={getDateTime()}
                    defaultDate={appointmentDate}
                    defaultStartTime={appointmentStartTime}
                    defaultEndTime={appointmentEndTime}
                    dateTimeValue={startAndEndTime}
                    isEnabledDate={false}
                    isEnabledTime={false}
                  />
                </div>
                <div className={classes.shortCall}>
                  <StyledP font={fontType}>Short Call</StyledP>
                  <div className={classes.toggleSwitch}>
                    <Toggle
                      optionLabels={['No', 'Yes']}
                      onChange={(checked: boolean) => setShortCall(checked)}
                    />
                  </div>
                </div>
              </div>
              {timeError && (
                <p className={classes.error}>
                  End Time should be greater than Start Time{' '}
                </p>
              )}
              <div className={classes.dentistName}>
                <DropDown
                  label="Select Dentist"
                  isAutoSearch={true}
                  isRequired={true}
                  placeholder="Select Dentist"
                  data={doctorNameList}
                  onInputChange={dentistNameHandler}
                  defaultValue={
                    props.providerType === 'dentist' ? providerName : ''
                  }
                  isDisable={props.providerType === 'dentist' ? true : false}
                />
              </div>
              <div className={classes.hygienistName}>
                <DropDown
                  label="Select Hygienist"
                  isAutoSearch={true}
                  isRequired={true}
                  placeholder="Select Hygienist"
                  isMulti={true}
                  data={providerNameList}
                  onInputChanges={hygienistNameHandler}
                  defaultValues={
                    props.providerType === 'hygienist' ? [providerName] : []
                  }
                />
              </div>
              <div className={classes.locationAndButton}>
                <div className={classes.locationField}>
                  <DropDown
                    label="Physical Location"
                    isAutoSearch={true}
                    placeholder="Physical Location"
                    data={locationList}
                    onInputChange={locationHandler}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default CreateAppointment;
