import React, { useContext, useEffect, useState } from 'react';
import classes from './Header.module.css';
import { ReactComponent as SettingIcon } from '../../images/setting_icon.svg';
import { ReactComponent as NotificationIcon } from '../../images/logo/notification_icon.svg';
import { ReactComponent as HamburgerIcon } from '../../images/hamburger.svg';
import HeaderMenu from './HeaderMenu';
import SidebarContext from '../../contexts/sideBar';
import { ReactComponent as Logo } from '../../images/logo/Truro_logo.svg';
import ActionItem from '../ActionItem';
import { ReactComponent as Avatar } from '../../images/avatar.svg';
import { useNavigate } from 'react-router-dom';
import { SignInContext } from '../../contexts/signin';
import { loginRoutes } from '../../layouts/LoginPage/loginRoutes';
import RightSideBar from '../RightSideBar/RightSideBar';
function Header() {
  const navigate = useNavigate();

  const [openRightSideBar, setOpenRightSideBar] = useState(false);

  const { opened, setOpened } = useContext(SidebarContext);

  const { theme, user, getUserRolesAndPermissions } = useContext(SignInContext);

  const navigateHandler = () => {
    for (let i = 0; i < loginRoutes.length; i++) {
      if (
        Object.keys(loginRoutes[i])[0] ===
        getUserRolesAndPermissions.UserRoles.UserPermissions
          .modularPermission[0]
      ) {
        navigate(Object.values(loginRoutes[i])[0]);
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', function (e: MouseEvent) {
      const element1 = document.getElementById('right_sidebar_container');
      const element2 = document.getElementById('avatarContainer');
      if (
        !element1?.contains(e.target! as Node) &&
        !element2?.contains(e.target! as Node)
      ) {
        setOpenRightSideBar(false);
      }
    });
    window.removeEventListener('click', function (e: MouseEvent) {
      const element1 = document.getElementById('right_sidebar_container');
      const element2 = document.getElementById('avatarContainer');
      if (
        !element1?.contains(e.target! as Node) &&
        !element2?.contains(e.target! as Node)
      ) {
        setOpenRightSideBar(false);
      }
    });
  }, []);

  return (
    <header
      className={`d-flex align-items-center justify-content-between ${
        theme === 'light'
          ? classes.headerContainer
          : classes.darkHeaderContainer
      }`}
    >
      <div
        className={`d-flex justify-content-start align-items-center ${classes.headerIcon}`}
      >
        <div
          className={`${classes.hamburgerIconOuter} ${
            theme === 'dark' ? classes.dark : null
          }`}
          id="hamburger"
          onClick={() => setOpened(!opened, 'hamburger-click')}
        >
          <HamburgerIcon />
        </div>
        <Logo
          id="truro_logo"
          className={classes.logo}
          onClick={navigateHandler}
        />
      </div>
      <nav
        className={`d-flex align-items-center justify-content-end ${classes.headerRightContainer}`}
      >
        <ActionItem
          icon={<SettingIcon />}
          tooltip="Settings"
          onClick={() => navigate('/setting')}
        />

        <ActionItem
          icon={<NotificationIcon />}
          tooltip="Notification"
          onClick={() => navigate('/notifications')}
        />

        <HeaderMenu
          title={user?.attributes?.name || ''}
          avatar={<Avatar />}
          onClick={() => setOpenRightSideBar(!openRightSideBar)}
        ></HeaderMenu>
        {openRightSideBar && (
          <RightSideBar setOpenRightSideBar={setOpenRightSideBar} />
        )}
      </nav>
    </header>
  );
}

export default Header;
