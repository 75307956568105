import React from 'react';
import Schedules from './Schedules';
import classes from './index.module.css';
import LeftTimeBar from './LeftTimeBar';
import { SchedulerGridConf } from '../../utils/schedulerGridConf';
import { SchedulerGridProps } from './schedulerTypes';
import classnames from 'classnames';

const SchedulerGrid = (props: SchedulerGridProps) => {
  if (props.data.length !== 0) {
    return (
      <>
        <div
          className={classnames(
            props.isFull ? classes.containerFull : classes.container,
            classes.smallScrollBar
          )}
        >
          <div className={classes.timebar}>
            <LeftTimeBar
              header={{
                firstHeader: props.hourHeader,
                secondHeader: props.minuteHeader,
              }}
              startTime={props.fullDayTime.startTime}
              endTime={props.fullDayTime.endTime}
              timeFormat={props.fullDayTime.timeFormat}
              rowTimeDuration={props.rowTimeDuration}
              numberOfSlot={SchedulerGridConf.slotsPerHour}
            />
          </div>

          <Schedules
            data={props.data}
            view={props.view}
            apptCardView={props.apptCardView}
            reRenderAppointments={props.reRenderAppointments}
            currentDate={props.currentDate}
            setCurrentDateOfSchedulerGrid={props.setCurrentDateOfSchedulerGrid}
            isCancelled={props.isCancelled}
          />
        </div>
      </>
    );
  } else
    return (
      <div className={classes.notAvailableDataStyle}>
        Data is not available for selected data/filter
      </div>
    );
};
export default SchedulerGrid;
