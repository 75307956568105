import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ANALYTICS_EVENTS } from '../../utils/constants';
import AuthService from '../../services/authService';
import Appointment from '../../services/Appointment';

import classes from './RightSideBar.module.css';
import Loader from '../Loader/Loader';
import { SignInContext } from '../../contexts/signin';
import { removeCookie } from '../../utils';
import { StyledDiv } from '../Styled/index';

interface RightSideBarProps {
  setOpenRightSideBar: any;
}

const RightSideBar = (props: RightSideBarProps) => {
  const navigate = useNavigate();
  const { user, onSignOut, sessionId, authToken, fontType } = useContext(SignInContext);
  const [isLoading, setIsLoading] = useState(false);

  const authService = useMemo(() => new AuthService(), []);
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  const handleLogout = async () => {
    setIsLoading(true);
    await appointmentService.signInEventLog(ANALYTICS_EVENTS.AUTH.SIGNOUT, {
      email: user.attributes?.email,
      username: user.attributes?.name,
      sessionId: sessionId,
    });
    const isSignedOut = await authService.signOut();
    if (isSignedOut) {
      onSignOut();
      removeCookie('selectedDentalPracticeId')
      removeCookie('OrganizationId')
      localStorage.clear();
    }
    setIsLoading(false);
    props.setOpenRightSideBar(false);
  };

  const handleNavigate = () => {
    navigate('/profile');
    props.setOpenRightSideBar(false);
  };

  return (
    <div className={classes.RightSideBarOuterContainer}>
      <div className={classes.RightSideBarInnerContainer} id={'right_sidebar_container'}>
        <StyledDiv font={fontType} className={classes.item} onClick={handleNavigate}>
          Profile
        </StyledDiv>
        <StyledDiv font={fontType} className={classes.item} onClick={handleLogout} id={'logout'}>
          <div>
            {isLoading ? (
              <div className={classes.loaderStyle}>
                <Loader type="round" borderWidth={2} loaderSize={20} />
              </div>
            ) : (
              `logout`
            )}
          </div>
        </StyledDiv>
      </div>
    </div>
  );
};

export default RightSideBar;
