import React, { useContext, useEffect, useState } from 'react';
import DropDown from '../DropDown';
import Input, { InputFieldType } from '../Input';
import DatePicker from '../DatePicker';
import './index.css';
import TimePicker from '../TimePicker';
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';
import { dateInUTCStringFormat } from '../../utils/appointmentCardValues';
import Toggle from '../ToggleSwitch/Toggle';
import { SignInContext } from '../../contexts/signin';
import {StyledDiv,StyledButton} from '../Styled/index';

export enum FilterType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  AUTO_SUGGEST = 'auto-suggest',
  DROPDOWN = 'dropdown',
}

export interface Filter {
  type: FilterType;
  displayName: string;
  placeholder: string;
  fieldName: string;
  value: string | Date | number;
  data?: string[] | null;
}

export interface FilterValue {
  fieldName: string;
  fieldValue: string | Date | number;
}

interface FilterOverlayProps {
  show: boolean;
  title: string;
  filters: Filter[];
  closeFilterOverlay: () => void;
  onApplyFilter: (filterValues: FilterValue[]) => void;
  onApplyReset: () => void;
  andCondition: boolean;
  setAndCondition: (data: boolean) => void;
}

type FilterValueType = Date | string | number;

const defaultFilterOverlayProps: FilterOverlayProps = {
  show: false,
  title: '',
  filters: [],
  closeFilterOverlay: () => {},
  onApplyFilter: (filterValues: FilterValue[]) => {},
  onApplyReset: () => {},
  andCondition: false,
  setAndCondition: (data: boolean) => {},
};

const FilterOverlay = ({
  closeFilterOverlay,
  show,
  filters,
  title,
  onApplyFilter,
  onApplyReset,
  andCondition,
  setAndCondition,
}: FilterOverlayProps) => {
  const [mapFilterData, setMapFilterData] = useState<
    Map<string, FilterValueType>
  >(new Map<string, FilterValueType>());
  const [isReset, setIsReset] = useState<boolean>(false);
  const { fontType } = useContext(SignInContext);

  useEffect(() => {
    if(isReset) {
      const filterMap = new Map<string, FilterValueType>();
      filters.forEach((filter) => filterMap.set(filter.fieldName, filter.value=''));
      setMapFilterData(filterMap);
    }
    else {
      const filterMap = new Map<string, FilterValueType>();
      filters.forEach((filter) => filterMap.set(filter.fieldName, filter.value));
      setMapFilterData(filterMap);
    }
  }, [filters, isReset]);

  const onApply = () => {
    const filterValues: FilterValue[] = [];
    mapFilterData.forEach(function (value, key) {
      filterValues.push({
        fieldName: key,
        fieldValue: value,
      });
    });
    onApplyFilter(filterValues);
    closeFilterOverlay();
    setIsReset(false);
  };

  const onReset = () => {
    onApplyReset();
    filters.map((i) => {
      return (i.value = '');
    });
    setIsReset(true);
    closeFilterOverlay();
    setAndCondition(false);
  };

  if (!show) return null;

  return (
    <div className="blur_background">
      <div className="outer_box">
        <div className="header flex_with_row">
          <StyledDiv font={fontType} className="title">{title}</StyledDiv>
          <div className="toggle_condition">
            <Toggle
              optionLabels={['OR', 'AND']}
              onChange={(checked: boolean) => setAndCondition(checked)}
              checked={andCondition}
            />
          </div>
          <div className="close_icon_style" onClick={closeFilterOverlay}>
            <CloseIcon className="close_icon" />
          </div>
        </div>
        <div className="middle flex_wrap">
          {filters.map((filter) => (
            <div className="width_40" key={filter.fieldName}>
              {filter.type === 'text' && (
                <Input
                  type={InputFieldType.TEXT}
                  label={filter.displayName}
                  placeholder={filter.placeholder}
                  onValueChange={(item) =>
                    mapFilterData.set(filter.fieldName, item)
                  }
                  defaultValue={filter.value}
                  id={'textField'}
                />
              )}
              {filter.type === 'number' && (
                <Input
                  type={InputFieldType.NUMBER}
                  label={filter.displayName}
                  placeholder={filter.placeholder}
                  onValueChange={(item) =>
                    mapFilterData.set(filter.fieldName, item)
                  }
                  defaultValue={filter.value}
                  id={'lomaScore'}
                />
              )}
              {filter.type === 'date' && (
                <DatePicker
                  minDate={new Date('01/01/1900')}
                  maxDate={new Date('01/01/2030')}
                  format={''}
                  label={filter.displayName}
                  value={new Date('')}
                  onValueChange={(item) => {
                    mapFilterData.set(
                      filter.fieldName,
                      dateInUTCStringFormat(item)
                    );
                  }}
                  isEnabled={true}
                />
              )}
              {filter.type === 'time' && (
                <TimePicker
                  step={10}
                  format={'hh:mm a'}
                  label={filter.displayName}
                  value={new Date('01/01/1900 12:00')}
                  onValueChange={(item) => {
                    mapFilterData.set(filter.fieldName, item.toString());
                  }}
                  isEnabled={true}
                />
              )}
              {filter.type === 'dropdown' && (
                <DropDown
                  placeholder={filter.placeholder}
                  label={filter.displayName}
                  data={filter.data ? filter.data : []}
                  onInputChange={(item) =>
                    mapFilterData.set(filter.fieldName, item)
                  }
                  defaultValue={filter.value as string}
                />
              )}
            </div>
          ))}
        </div>
        <div className="footer">
          <div className="m-2">
            <StyledButton
              font={fontType}
              type="button"
              className="button secondary mx-2"
              onClick={onReset}
              id='resetFilterButton'
            >
              Reset
            </StyledButton>
            <StyledButton font={fontType} type="button" className="button primary" onClick={onApply} id='applyFilterButton'>
              Apply
            </StyledButton>
          </div>
        </div>
      </div>
    </div>
  );
};
FilterOverlay.defaultProps = defaultFilterOverlayProps;
export default FilterOverlay;
