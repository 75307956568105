import mixpanel from 'mixpanel-browser';
import { ANALYTICS_EVENTS } from '../utils/constants';

interface Config {
  ip: boolean;
  debug: boolean;
  autotrack: boolean;
  save_referrer: boolean;
}

export default class AnalyticsMixpanel {
  token: string;
  config: Config;
  constructor(
    config: Config,
    defaultProps: Record<string, any> = {},
    token = process.env.REACT_APP_MIXPANEL_ACCESS_TOKEN!
  ) {
    this.token = token;
    this.config = config;
    if (!this.token) {
      throw new Error('Please Provide a valid token');
    }
    // Default Operations
    mixpanel.init(this.token, this.config);
    this.registerProps(defaultProps);
    this.trackLinkClicks();
  }

  identify(id: string) {
    mixpanel.identify(id);
  }

  alias(aliasId: string) {
    mixpanel.alias(aliasId);
  }

  trackEvent(eventName: string, props: Record<string, any>) {
    mixpanel.track(eventName, props);
  }

  setPeople(propName: string, newValue: any) {
    mixpanel.people.set(propName, newValue);
  }

  registerProps(props: Record<string, any>) {
    mixpanel.register(props);
  }

  registerPropsOnce(props: Record<string, any>) {
    mixpanel.register_once(props);
  }

  trackLinkClicks(
    eventName = ANALYTICS_EVENTS.CLICK.LINK_CLICK,
    props: Record<string, any> = {}
  ) {
    mixpanel.track_links('a', eventName, props);
  }

  resetSession() {
    mixpanel.reset();
  }
}
