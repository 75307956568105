import ReactDOM from 'react-dom';
import classes from './index.module.css';
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';
import { ReactComponent as MinimizeIcon } from '../../images/minimize_icon.svg';
import { ReactComponent as MaximizeIcon } from '../../images/maximize_icon.svg';
import { MouseEvent, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { CSSProperties } from 'styled-components';
import { SignInContext } from '../../contexts/signin';
import { StyledP, StyledTd } from '../Styled';

interface DragState {
  x: number;
  y: number;
  xDiff: number;
  yDiff: number;
  cursorX: number;
  cursorY: number;
  isDragging: boolean;
}

interface ModalHeader {
  title: string;
  actionItems: JSX.Element[];
}

interface ModalBody {
  fields?: JSX.Element[];
}

interface ModalFooter {
  actionItems: JSX.Element[];
}

interface ModalProps {
  isPadding?: boolean;
  show: boolean;
  header: ModalHeader;
  children?: ModalBody | string | JSX.Element | null;
  footer?: ModalFooter;
  modalWidth?: boolean;
  isDraggable?: boolean;
  isMinimizable?: boolean;
  onModalClose: () => void;
  onOverlayClick: () => void;
}

const defaultProps: ModalProps = {
  show: false,
  isPadding: true,
  header: {
    title: '',
    actionItems: [],
  },
  modalWidth: true,
  isDraggable: true,
  isMinimizable: false,
  children: <></>,
  onModalClose: () => {},
  onOverlayClick: () => {},
};

const Modal: React.FC<ModalProps> = ({
  show,
  footer,
  header,
  children,
  isPadding,
  modalWidth,
  isDraggable,
  isMinimizable,
  onModalClose,
  onOverlayClick,
}: ModalProps) => {
  const [dragState, setDragState] = useState<DragState>({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
    xDiff: 0,
    yDiff: 0,
    cursorX: 0,
    cursorY: 0,
    isDragging: false,
  });
  const { fontType } = useContext(SignInContext);

  const [isMinimize, setIsMinimize] = useState<boolean>(false);

  const styles = useMemo<CSSProperties>(() => {
    return {
      position: isDraggable ? 'absolute' : 'relative',
      left: dragState.x + dragState.xDiff,
      top: dragState.y + dragState.yDiff,
    };
  }, [dragState, isDraggable]);

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      // Escape Key
      if (e.key === '27') {
        onModalClose();
      }
    }
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onModalClose]);

  const onClose = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onModalClose();
  };

  const onMinimize = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isMinimize) {
      setIsMinimize(true);
    } else {
      setIsMinimize(false);
    }
  };

  const setNewDragState = (newState: Partial<DragState>) =>
    setDragState({
      ...dragState,
      ...newState,
    });

  const handleDragStart = (e: MouseEvent<HTMLDivElement>) => {
    setNewDragState({
      cursorX: e.pageX,
      cursorY: e.pageY,
      isDragging: true,
    });
  };

  const handleDrag = (e: MouseEvent<HTMLDivElement>) => {
    if (dragState.isDragging) {
      const xDiff: number = e.pageX - dragState.cursorX;
      const yDiff: number = e.pageY - dragState.cursorY;
      setNewDragState({
        xDiff,
        yDiff,
      });
    }
  };

  const handleDragStop = (e: MouseEvent<HTMLDivElement>) => {
    setNewDragState({
      x: dragState.x + dragState.xDiff,
      y: dragState.y + dragState.yDiff,
      cursorX: e.pageX,
      cursorY: e.pageY,
      isDragging: false,
      xDiff: 0,
      yDiff: 0,
    });
  };

  if (!show) return null;

  return ReactDOM.createPortal(
    <div>
      <div
        className={classNames(
          classes.container,
          isMinimize ? classes.minimize : ''
        )}
      >
        <div className={classes.overlay} onClick={onOverlayClick}>
          <div
            className={classNames(classes.modal, {
              [classes.modalWidth]: modalWidth,
            })}
            style={styles}
          >
            <header
              className={classNames(classes.header, {
                [classes.draggable]: isDraggable,
              })}
              onMouseDown={handleDragStart}
              onMouseUp={handleDragStop}
              onMouseMove={handleDrag}
              onMouseLeave={() => setNewDragState({ isDragging: false })}
            >
              <StyledP font={fontType} className={classes.headerTitle}>{header.title}</StyledP>
              <div className={classes.actions}>
                {header.actionItems.map((actionItem, index) => (
                  <div key={index} className={classes.action}>
                    {actionItem}
                  </div>
                ))}
                {isMinimizable && (
                  <div className={classes.minimizeIcon} onClick={onMinimize}>
                    <MinimizeIcon />
                  </div>
                )}
                <div className={classes.closeIcon} onClick={onClose}>
                  <CloseIcon className={classes.fillBlack} />
                </div>
              </div>
            </header>
            <main className={isPadding ? classes.body : ''}>{children}</main>
            {footer && (
              <footer className={classes.footer}>
                <div className={classes.actions}>
                  {footer.actionItems.map((actionItem, index) => (
                    <div key={index} className={classes.action}>
                      {actionItem}
                    </div>
                  ))}
                </div>
              </footer>
            )}
          </div>
        </div>
      </div>
      <table
        className={classNames(classes.popUp, {
          [classes.minimize]: !isMinimize,
        })}
      >
        <tbody>
          <tr>
            <StyledTd font={fontType} onClick={onMinimize} className={classNames(classes.padding)}>
              {header.title}
            </StyledTd>
            <td onClick={onMinimize} className={classes.padding}>
              <MaximizeIcon className={classes.fillWhite} />
            </td>
            <td onClick={onModalClose} className={classes.padding}>
              <CloseIcon className={classes.fillWhite} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>,
    document.getElementById('modal-root') as HTMLElement
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
