import { Filter, FilterType } from '../components/FilterOverlay/index';

export namespace FilterListConf {
  export type FilterListParams = {
    type: 'call' | 'short-call' | 'reactivation' | 'unscheduledTreatment';
  };

  export const callListFilterFields: Filter[] = [
    {
      type: FilterType.TEXT,
      displayName: 'Patient Id',
      placeholder: 'Search Patient Id',
      fieldName: 'patientId',
      value: '',
    },
    {
      type: FilterType.TEXT,
      displayName: 'Patient Name',
      placeholder: 'Search Patient Name',
      fieldName: 'patientName',
      value: '',
    },
    {
      type: FilterType.NUMBER,
      displayName: 'LoMA Score',
      placeholder: 'Search LoMA Score',
      fieldName: 'lomaScore',
      value: '',
    },
    {
      type: FilterType.DROPDOWN,
      displayName: 'Day Of Time',
      placeholder: 'Search Day Of Time',
      fieldName: 'preferredDayOfTime',
      value: '',
      data: ['Morning', 'Afternoon', 'Evening'],
    },
    {
      type: FilterType.DROPDOWN,
      displayName: 'Day Of Week',
      placeholder: 'Search Day Of Week',
      fieldName: 'preferredDayOfWeek',
      value: '',
      data: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
    },
  ];

  export const shortCallListFilterFields: Filter[] = [
    {
      type: FilterType.TEXT,
      displayName: 'Patient Id',
      placeholder: 'Search Patient Id',
      fieldName: 'patientId',
      value: '',
    },
    {
      type: FilterType.TEXT,
      displayName: 'Patient Name',
      placeholder: 'Search Patient Name',
      fieldName: 'patientName',
      value: '',
    },
    {
      type: FilterType.NUMBER,
      displayName: 'LoMA Score',
      placeholder: 'Search LoMA Score',
      fieldName: 'lomaScore',
      value: '',
    },
    {
      type: FilterType.TEXT,
      displayName: 'Patient Phone',
      placeholder: 'Search Patient Phone',
      fieldName: 'patientPhone',
      value: '',
    },
    {
      type: FilterType.DROPDOWN,
      displayName: 'Day Of Time',
      placeholder: 'Search Day Of Time',
      fieldName: 'preferredDayOfTime',
      value: '',
      data: ['Morning', 'Afternoon', 'Evening'],
    },
    {
      type: FilterType.DROPDOWN,
      displayName: 'Day Of Week',
      placeholder: 'Search Day Of Week',
      fieldName: 'preferredDayOfWeek',
      value: '',
      data: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
    },
  ];

  export const recreationListFilterFields: Filter[] = [
    {
      type: FilterType.TEXT,
      displayName: 'Prefered Provider',
      placeholder: 'Search prefered provider',
      fieldName: 'preferredProvider',
      value: '',
    },
    {
      type: FilterType.TEXT,
      displayName: 'Patient Name',
      placeholder: 'Search Patient Name',
      fieldName: 'patientName',
      value: '',
    },
    {
      type: FilterType.NUMBER,
      displayName: 'LoMA Score',
      placeholder: 'Search LoMA Score',
      fieldName: 'lomaScore',
      value: '',
    },
  ];

  export const unscheduledTreatmentFilterFields: Filter[] = [
    {
      type: FilterType.TEXT,
      displayName: 'Patient Id',
      placeholder: 'Search Patient Id',
      fieldName: 'patientId',
      value: '',
    },
    {
      type: FilterType.TEXT,
      displayName: 'Patient Name',
      placeholder: 'Search Patient Name',
      fieldName: 'patientName',
      value: '',
    },
    {
      type: FilterType.NUMBER,
      displayName: 'LoMA Score',
      placeholder: 'Search LoMA Score',
      fieldName: 'lomaScore',
      value: '',
    },
  ];

  export const getFilterListFields = (listType: FilterListParams): Filter[] => {
    if (listType.type === 'call') {
      return callListFilterFields;
    } else if (listType.type === 'short-call') {
      return shortCallListFilterFields;
    } else if (listType.type === 'reactivation') {
      return recreationListFilterFields;
    } else {
      return unscheduledTreatmentFilterFields;
    }
  };
}
