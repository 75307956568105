import React from 'react';
import classes from './RankTable.module.css';
interface heading {
  val: string;
  colSpan: number;
}

interface BestFitProps {
  header: heading[];
  subHeader: heading[];
  data: any;
}

function BestFitTable(props: BestFitProps) {
  return (
    <table>
      <tr>
        {props.header.map((headerVal) => {
          return (
            <th className={classes.th1} colSpan={headerVal.colSpan}>
              {headerVal.val}
            </th>
          );
        })}
      </tr>
      <tr>
        {props.subHeader.map((headerVal) => {
          return (
            <th className={classes.th2} colSpan={headerVal.colSpan}>
              {headerVal.val}
            </th>
          );
        })}
      </tr>
      {/* <tr>
        {
            props.data.map((rowVal)=>{
                return <td></td>
            })
        }
      </tr> */}

      {props.data.map((val: any) => {
        return (
          <tr>
            <td className={classes.td1}>{val.rank}</td>
            <td className={classes.td1}>{val.prospectInfo.patient.patientName}</td>
            <td className={classes.td1}>{val.prospectInfo.patient.patientId}</td>
            <td className={classes.td1}>{val.prospectInfo.patient.lomaScore}</td>
            <td className={classes.td1}>{val.prospectInfo.preferredProvider.dentistName}</td>
            <td className={classes.td1}>{val.prospectInfo.preferredProvider.hygienistName}</td>
            <td className={classes.td1}>{val.scheduledDate}</td>
            <td className={classes.td1}>{val.durationInHrs}</td>
            <td className={classes.td1}>{val.preferredTimeSlot}</td>
            <td className={classes.td1}>{val.preferredDay}</td>
          </tr>
        );
      })}
    </table>
  );
}

export default BestFitTable;
