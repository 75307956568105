import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import SignInContextProvider from '../contexts/signin';

type Props = {
  children: JSX.Element;
};

export default function AuthProvider({ children }: Props) {
  return (
    <Authenticator.Provider>
      <SignInContextProvider>{children}</SignInContextProvider>
    </Authenticator.Provider>
  );
}
