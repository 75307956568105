export const compareTwoDates = (
  date1: Date | string,
  date2: Date | string = new Date()
): boolean => {
  try {
    const isEqual: boolean =
      new Date(date1).toDateString() === new Date(date2).toDateString();
    return isEqual;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const addHours = (hours: number, date: Date = new Date()) => {
  date.setTime(date.getTime() + hours * 3600 * 1000);
  return date;
};

export const addDays = (days: number, date: Date = new Date()) => {
  date.setTime(date.getTime() + days * 24 * 3600 * 1000);
  return date;
};

export const setCookie = (item: string, value: string, exdays: number = 1) => {
  item.trim();
  value.trim();
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = item + '=' + value + ';' + expires + ';path=/';
};

export function getCookie(cname: string) {
  cname.trim();
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    if (c.indexOf(name) !== -1) {
      return c.substring(c.indexOf(name) + name.length);
    }
  }
  return '';
}

export function removeCookie(cname: string) {
  cname.trim();
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
