import React from 'react'
import Loader from '../Loader/Loader';
import classes from './DataNotFound.module.css';
function DataNotFound(props: any) {
  return (
    
    <div className={classes.dataNotFoundContainer} id='dataNotAvailable'>{props.loader ? <Loader /> : `No records available`}</div>
  )
}

export default DataNotFound