export const COLOR_CODES = [
  '#6366F1',
  '#38BDF8',
  '#34D399',
  '#FBBF24',
  '#FFA726',
  '#024898',
  '#B82708',
];

export const ANALYTICS_EVENTS = {
  SESSION: {
    SESSION_START: 'session_start',
    SESSION_END: 'session_end',
  },
  AUTH: {
    SIGNIN: 'signin',
    SIGNUP: 'signup',
    SIGNOUT: 'signout',
    RESET_PASSWORD: 'reset_password',
  },
  CLICK: {
    LINK_CLICK: 'Link_Click',
    All_APPOINTMENTS: 'All Appointments',
    TODAY_APPOINTMENTS: 'Today Appointments',
    TOMMORROW_APPOINTMENTS: 'Today Appointments',
    CALL_LIST: 'Call List',
    Short_Call_List: 'Short Call-List',
    REACTIVATION_LIST: 'Reactivation List',
    UNSCHEDULED_APPOINTMENTS: 'Unscheduled Appointments',
    SCHEDULE_APPOINTMENT: 'Schedule Appointments',
  },
  ALL_APPOINTMENTS_EVENTS: {
    Row_Expand: 'Expand Row',
    Collapse_Row: 'Collapse Row',
    Copy_PatientId: 'Copy PatientId',
    Click_Pagination: 'Click on Pagination',
    Change_Showing_Items: 'Change Showing Items',
    Sort_Table_Column: 'Sort Table Column',
  },
  TODAYS_APPOINTMENTS_EVENTS: {
    Row_Expand: 'Expand Row',
    Collapse_Row: 'Collapse Row',
    Copy_PatientId: 'Copy PatientId',
    Click_Pagination: 'Click on Pagination',
    Change_Showing_Items: 'Change Showing Items',
    Sort_Table_Column: 'Sort Table Column',
  },
  TOMORROWS_APPOINTMENTS_EVENTS: {
    Row_Expand: 'Expand Row',
    Collapse_Row: 'Collapse Row',
    Copy_PatientId: 'Copy PatientId',
    Click_Pagination: 'Click on Pagination',
    Change_Showing_Items: 'Change Showing Items',
    Sort_Table_Column: 'Sort Table Column',
  },
  CALL_LIST_APPOINTMENTS_EVENTS: {
    list_type: 'call_list',
    Row_Expand: 'Expand Row',
    Collapse_Row: 'Collapse Row',
    Copy_PatientId: 'Copy PatientId',
    Click_Pagination: 'Click on Pagination',
    Change_Showing_Items: 'Change Showing Items',
    Sort_Table_Column: 'Sort Table Column',
    Contacted_Today: 'Contacted Today',
    Change_Confirm_Status: 'Confirm Status',
    Applied_Filter: 'Apply Filter',
    Save_Contact_Notes: 'call_list_notes',
  },
  SHORT_CALL_LIST_APPOINTMENTS_EVENTS: {
    list_type: 'short_call_list',
    Row_Expand: 'Expand Row',
    Collapse_Row: 'Collapse Row',
    Copy_PatientId: 'Copy PatientId',
    Click_Pagination: 'Click on Pagination',
    Change_Showing_Items: 'Change Showing Items',
    Sort_Table_Column: 'Sort Table Column',
    Contacted_Today: 'Contacted Today',
    Applied_Filter: 'Apply Filter',
    Save_Contact_Notes: 'short_call_list_notes',
  },
  REACTIVATION_LIST_EVENTS: {
    list_type: 'reactivation_list',
    Row_Expand: 'Expand Row',
    Collapse_Row: 'Collapse Row',
    Copy_PatientId: 'Copy PatientId',
    Click_Pagination: 'Click on Pagination',
    Change_Showing_Items: 'Change Showing Items',
    Sort_Table_Column: 'Sort Table Column',
    Contacted_Today: 'Contacted Today',
    Applied_Filter: 'Apply Filter',
    Save_Contact_Notes: 'reactivation_list_notes',
  },
  UNSCHEDULED_TREATMENT_LIST_EVENTS: {
    list_type: 'unscheduled_treatment_list',
    Row_Expand: 'Expand Row',
    Collapse_Row: 'Collapse Row',
    Copy_PatientId: 'Copy PatientId',
    Click_Pagination: 'Click on Pagination',
    Change_Showing_Items: 'Change Showing Items',
    Sort_Table_Column: 'Sort Table Column',
    Contacted_Today: 'Contacted Today',
    Applied_Filter: 'Apply Filter',
    Save_Contact_Notes: 'unscheduled_list_notes',
  },
  HEARTBEAT: {
    Heartbeat: 'HeartBeat',
  },
  APPOINTMENT: {
    create: 'create_appointment',
    update: 'update_appointment',
    delete: 'delete_appointment',
  },
};

export const API_ROUTES = {
  SIGN_IN: `${process.env.REACT_APP_API_BASE_URI}/user/login`,
  SIGN_UP: `${process.env.REACT_APP_API_BASE_URI}/user/signup`,
  SAVE_CONTACT_NOTES: `${process.env.REACT_APP_API_BASE_URI}/user/create-contact-notes`,
  FETCH_APPOINTMENTS: `${process.env.REACT_APP_API_BASE_URI}/user/filter-appointments`,
  FETCH_RECREATION: `${process.env.REACT_APP_API_BASE_URI}/user/reactivation-list`,
  GET_PATIENT_BY_ID: `${process.env.REACT_APP_API_BASE_URI}/user/get-patient-details`,
  GET_DOCTOR_LIST: `${process.env.REACT_APP_API_BASE_URI}/user/get-provider-filtered-details?providerType=doctor`,
  GET_PROVIDER_LIST: `${process.env.REACT_APP_API_BASE_URI}/user/get-provider-filtered-details?providerType=hygienist`,
  CREATE_APPOINTMENT: `${process.env.REACT_APP_API_BASE_URI}/user/create-appointment`,
  CONFIRM_APPOINTMENT: `${process.env.REACT_APP_API_BASE_URI}/user/update-appt-confirmed-status`,
  FETCH_APPOINTMENTS_FOR_CALENDAR_VIEW: `${process.env.REACT_APP_API_BASE_URI}/user/get-appointment-details-group-by-doctor`,
  GET_DENTIST_AND_HYGENIST: `${process.env.REACT_APP_API_BASE_URI}/user/get-dentist-and-hygenist`,
  GET_LOCATION_FILTERED_DETAILS: `${process.env.REACT_APP_API_BASE_URI}/user/get-location-filtered-details?limit=10&skip=0`,
  GET_APPOINTMENT_DETAILS: `${process.env.REACT_APP_API_BASE_URI}/user/get-appointment-details`,
  GET_UNSCHEDULED: `${process.env.REACT_APP_API_BASE_URI}/user/get-unschedule-trt-list`,
  UPDATE_APPOINTMENT_DETAILS: `${process.env.REACT_APP_API_BASE_URI}/user/update-appointment`,
  DELETE_APPOINTMENT: `${process.env.REACT_APP_API_BASE_URI}/user/delete-appointment`,
  GET_PATIENT_DETAILS: `${process.env.REACT_APP_API_BASE_URI}/user/get-patient-filtered-details`,
};

export const APP_ROUTES = {
  appointments: '/appointments',
  todayAppointments: '/appointments/today',
  tomorrowAppointments: '/appointments/tomorrow',
  callList: '/call-list/call',
  shortCallList: '/call-list/short-call',
  reactivationList: '/call-list/reactivation',
  scheduleAppt: '/schedule-appointment',
  cancelledAppt: '/cancelled-appointment',
  unscheduledTreatment: '/call-list/unscheduledTreatment',
  analyticsChart: '/analytics-chart',
};

export const DATE_FORMAT = {
  MM_dd_yyyy: 'MM/dd/yyyy',
};

export const TIME_FORMAT = {
  hh_mm_a: 'hh:mm a',
};

export const MIN_MAX_DATE = {
  minDate: new Date('01/01/1900'),
  maxDate: new Date('01/01/2100'),
};

export const MINUTE_STEP = {
  step: 10,
};

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const COOKIE_NAMES = {
  TOKEN: 'token',
};

export const LOCAL_STORAGE_KEYS = {
  USER: 'userDetails',
};

export const MONTH_NAME = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const USER_EVENTS = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
  'mousewheel',
  'dbclick',
  'mouseup',
  'contextmenu',
  'mouseout',
  'mouseover',
  'touchstart',
  'touchend',
  'touchmove',
  'touchcancel',
  'keydown',
  'keyup',
  'keypress',
  'resize',
  'unload',
  'hashchange',
  'focus',
];

export const getLocaleCurrency = (price: any) => {
  const options = {
    style: 'currency',
    currency: 'inr',
  };
  return price.toLocaleString(undefined, options);
};

export const numberToStringTime = (time: number) => {
  const res: string =
    time.toString().length === 3
      ? '0' + time.toString()[0] + ':' + time.toString()[1] + time.toString()[2]
      : time.toString()[0] +
        time.toString()[1] +
        ':' +
        time.toString()[2] +
        time.toString()[3];
  return res;
};

export const SESSION = { ACTIVE: 'active', LOGOUT: 'logout' };

export const SAVE_CONTACT_NOTES = {
  OPEN: 'save_contact_notes_open',
  SAVED: 'save_contact_notes_saved_notes',
  SAVED_AGAIN: 'save_contact_notes_saved_notes_again',
};

export const LISTTYPE = {
  CALL_LIST: 'call_list',
  SHORT_CALL_LIST: 'short_call_list',
  REACTIVATION_LIST: 'reactivation_list',
  UNSCHEDULED_LIST: 'unscheduled_list',
};
export const FONT_TYPE = ['Default', 'Lato', 'Italic', 'Cursive', 'Times New Roman'];

export const FONT_SIZE = ['none', '12', '13', '14', '15', '16', '17'];
