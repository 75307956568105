import { Auth } from 'aws-amplify';
import AnalyticsService from './analyticsService';

interface SignInOptions {
  username: string;
  password: string;
  attributes: {
    email: string;
    [other: string]: string;
  };
}

class AuthService {
  analyticsService: AnalyticsService;
  constructor() {
    this.analyticsService = new AnalyticsService();
  }

  async signIn(username: string, password: string) {
    try {
      const user = await Auth.signIn(username, password);
      return user;
    } catch (err) {
      console.log('Error has occurred while signing in');
    }
  }

  async signUpVerify(userName: string, code: string) {
    try {
      await Auth.confirmSignUp(userName, code);
    } catch (err) {
      console.log(err);
    }
  }

  async getAuthenticatedUser() {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (err) {
      throw err;
    }
  }

  async signUp(signUpOptions: SignInOptions) {
    try {
      const res = await Auth.signUp(signUpOptions);
      return res;
    } catch (err) {
      console.log('Error has occurred while signing up');
      throw err;
    }
  }

  async signOut(global = true) {
    try {
      await Auth.signOut({ global });
      return true;
    } catch (err) {
      console.log('Error has occurred while signing out');
      throw err;
    }
  }

  async resetPassword(username: string) {
    try {
      const otpRequest = await Auth.forgotPassword(username);
      return otpRequest;
    } catch (err) {
      console.log('Email is not registered with us');
    }
  }

  async resetPasswordfill(username: string, otp: string, password: string) {
    try {
      const message = await Auth.forgotPasswordSubmit(username, otp, password);
      return message;
    } catch (err) {
      console.log(err, 'Otp Mismatch');
    }
  }

  async changePassword(
    email: string | undefined,
    oldPassword: string,
    newPassword: string
  ) {
    try {
      const message = await Auth.changePassword(
        email,
        oldPassword,
        newPassword
      );
      return message;
    } catch (err) {
      console.log(err);
      console.log(err, 'old Password Wrong');
    }
  }
}

export default AuthService;
