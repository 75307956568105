interface Scale {
  value: number;
  scale: string;
}

interface AppointmentConfiguration {
  appointmentCardHeight: Scale;
  appointmentCardWidth: Scale;
}

export const cardConfiguration: AppointmentConfiguration = {
  appointmentCardHeight: {
    value: 90,
    scale: 'px',
  },
  appointmentCardWidth: {
    value: 152,
    scale: 'px',
  },
};
