import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import Table from '../../components/Table';
import {
  ANALYTICS_EVENTS,
  LISTTYPE,
  SAVE_CONTACT_NOTES,
} from '../../utils/constants';
import { TableTypes } from '../../components/Table/tableTypes';
import { UnscheduledListConf } from './unscheduledListConf';
import { UnscheduledFilterListConf } from './unscheduledFilterListConf';
import Modal from '../../components/Modal';
import Button, { ButtonType } from '../../components/Button';
import { SignInContext } from '../../contexts/signin';
import classes from '../commonFileOfList/index.module.css';
import Toaster, { ToasterType } from '../../components/Toaster';
import { getCookie } from '../../utils';
import classNames from 'classnames';
import FilterOverlay, { FilterValue } from '../../components/FilterOverlay';
import Appointment from '../../services/Appointment';
import useAppointmentFields from '../commonFileOfList/useAppointmentFields';
import { StyledTextarea, StyledDiv } from '../../components/Styled/index';

const UnscheduledList = () => {
  const {
    getAppointmentsFields,
    apptVersion,
    showModal,
    contactNodeId,
    setShowModal,
    setApptVersion,
    setContactNodeId,
    contactAgain,
  } = useAppointmentFields();
  const { user, fontType } = useContext(SignInContext);
  const { authToken } = useContext(SignInContext);
  const [fetching, setFetching] = useState(false);
  const [contactNotes, setContactNotes] = useState<string>('');
  const [showFilterOverlay, setShowFilterOverlay] = useState<boolean>(false);
  const [appoinmentData, setAppoinmentData] = useState<TableTypes.TableBody[]>(
    []
  );
  const [appointmentService] = useState(new Appointment(authToken!));
  const [dataAfterFilter, setDataAfterFilter] = useState<
    TableTypes.TableBody[]
  >([]);
  const [showToaster, setShowToaster] = useState<boolean>(false);
  const [showToggleToaster, setShowToggleToaster] = useState<boolean>(false);
  const [saveNotesLoading, setSaveNotesLoading] = useState(false);
  const [andCondition, setAndCondition] = useState(false);
  const getHeaders = useCallback(() => {
    return () => UnscheduledListConf.getCallListFields();
  }, []);

  const getAggregation = useCallback(() => {
    return () => UnscheduledListConf.getAggregationField();
  }, []);

  const getFilterFields = useCallback(() => {
    return () => UnscheduledFilterListConf.getFilterListFields();
  }, []);

  const [filterFields, setFilterFields] = useState(getFilterFields()());

  const showRemoveFilterButton = useMemo(() => {
    return filterFields.filter((field) => field.value).length > 0;
  }, [filterFields]);

  useEffect(() => {
    setFilterFields(getFilterFields()());
  }, [getFilterFields]);

  const [headers, setHeaders] = useState<TableTypes.TableHeader[]>(
    getHeaders()
  );

  const [aggregation, setAggregation] = useState<TableTypes.Aggregation>(
    getAggregation()
  );

  const sortOrder: TableTypes.TableSort = useMemo(() => {
    return TableTypes.TableSort.DESC;
  }, []);

  useEffect(() => {
    setHeaders(getHeaders());
    setAggregation(getAggregation());
  }, [getHeaders, getAggregation]);

  useEffect(() => {
    setDataAfterFilter(appoinmentData);
  }, [appoinmentData]);

  useEffect(() => {
    if (showModal) {
      appointmentService.createUserAction(
        user.attributes?.name,
        user.attributes?.email,
        SAVE_CONTACT_NOTES.OPEN,
        LISTTYPE.UNSCHEDULED_LIST
      );
    }
  }, [appointmentService, user, showModal, setShowModal]);

  useEffect(() => {
    setFetching(true);
    const getAppointmentsData = () => {
      ///   graphql API
      appointmentService
        .fetchList(
          'unscheduledTreatment',
          getCookie('selectedDentalPracticeId')
        )
        .then(({ data }: { data: any }) => {
          let dataSet: any = data.listUnscheduleds.items;

          setAppoinmentData(
            dataSet.map(({ id, ...apptData }: any) => ({
              ...apptData,
              id: id,
              ...(apptData.patientDetails || {}),
              ...(apptData.preferredInfo || {}),
              ...getAppointmentsFields(id, {
                ...apptData,
                ...(apptData.Patient || {}),
                ...(apptData.Provider || {}),
                ...(apptData.Procedure || {}),
              }),
            }))
          );
        })
        .catch((err) => {
          console.log('Error has occurred while fetching appointments::', err);
        })
        .finally(() => {
          setFetching(false);
        });
    };
    getAppointmentsData();
  }, [authToken, getAppointmentsFields, appointmentService]);

  const onModalClose = () => {
    setShowModal(false);
    setContactNodeId('');
    setApptVersion(0);
    setContactNotes('');
  };

  const saveNotes = () => {
    ////////    updated contact notes...    ///////////////////
    if (contactNotes) {
      setSaveNotesLoading(true);
      const saveContactNotes = appointmentService.createUnscheduledContactNotes;
      appointmentService
        .createContactNotes(user.attributes?.name, contactNotes)
        .then((res: any) => {
          saveContactNotes(contactNodeId, res.data.createContactNotes.id).then(
            (res: any) => {
              const updatedContactNode =
                res.data.createUnscheduledContactNotes.unscheduled;
              updatedContactNode.ContactNotes.items.sort(function (
                a: any,
                b: any
              ) {
                const date1: any = new Date(a.contactNotes.createdAt);
                const date2: any = new Date(b.contactNotes.createdAt);
                return date2 - date1;
              });
              contactAgain
                ? appointmentService.createUserAction(
                    user.attributes?.name,
                    user.attributes?.email,
                    SAVE_CONTACT_NOTES.SAVED_AGAIN,
                    ANALYTICS_EVENTS.UNSCHEDULED_TREATMENT_LIST_EVENTS.list_type
                  )
                : appointmentService.createUserAction(
                    user.attributes?.name,
                    user.attributes?.email,
                    SAVE_CONTACT_NOTES.SAVED,
                    ANALYTICS_EVENTS.UNSCHEDULED_TREATMENT_LIST_EVENTS.list_type
                  );

              appointmentService
                .updateLastContactedValue(
                  contactNodeId,
                  apptVersion,
                  new Date().toISOString(),
                  'unscheduledTreatment'
                )
                .then((res: any) => {
                  setApptVersion(res.data.updateUnscheduled._version);
                });

              setAppoinmentData(
                appoinmentData.map((apptData) => {
                  if (apptData.id === updatedContactNode.id) {
                    return {
                      ...apptData,
                      id: apptData.id,
                      ...getAppointmentsFields(apptData.id, {
                        ...apptData,
                        ...updatedContactNode,
                        lastContacted: new Date(),
                        _version: apptVersion + 1,
                      }),
                    };
                  }
                  return apptData;
                })
              );
              setShowToaster(true);
            }
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSaveNotesLoading(false);
          onModalClose();
        });
    }
  };

  const handleFilterApply = (filters: FilterValue[]) => {
    // handling prev state of filter fields
    filterFields.map((v) =>
      filters.map((i) => {
        if (v.fieldName === i.fieldName) {
          v.value = i.fieldValue;
        }
        return null;
      })
    );
    //  filter logic
    setFilterFields(
      filterFields.map((field) => {
        const requiredField = filters.find(
          (filter) => filter.fieldName === field.fieldName
        );
        if (requiredField?.fieldValue) {
          return {
            ...field,
            value: requiredField?.fieldValue,
          };
        } else {
          return field;
        }
      })
    );
    const fieldData: { [fieldName: string]: any } = filters.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.fieldName]: curr.fieldValue,
      }),
      {}
    );
    const filteredData = appoinmentData.filter((row) => {
      const orCondition = !andCondition;

      const isPatientIdEqual: boolean =
        fieldData?.patientId &&
        row.Patient.patientId
          ?.toLowerCase()
          .includes(fieldData?.patientId?.toLowerCase());

      const isPatientNameEqual: boolean =
        fieldData?.patientName &&
        row.Patient.name
          ?.toLowerCase()
          .includes(fieldData?.patientName?.toLowerCase());

      const isLomaScoreEqual: boolean =
        fieldData?.lomaScore &&
        row.Patient.lomaScore.toString() === fieldData?.lomaScore;
      if (orCondition) {
        return isPatientIdEqual || isPatientNameEqual || isLomaScoreEqual;
      } else {
        return (
          (fieldData?.patientId
            ? row.Patient.patientId
                ?.toLowerCase()
                .includes(fieldData?.patientId?.toLowerCase())
            : true) &&
          (fieldData?.patientName
            ? row.Patient.name
                ?.toLowerCase()
                .includes(fieldData?.patientName?.toLowerCase())
            : true) &&
          (fieldData?.lomaScore
            ? row.Patient.lomaScore.toString().includes(fieldData?.lomaScore)
            : true)
        );
      }
    });

    setDataAfterFilter(filteredData);
  };

  const handleImplicitReset = () => {
    setDataAfterFilter(appoinmentData);
  };

  const handleExplicitReset = () => {
    setFilterFields(getFilterFields()());
    setDataAfterFilter(appoinmentData);
  };

  const renderTitle = () => {
    return 'Unscheduled List';
  };

  const renderDescription = () => {
    return 'Patients who have been prescribed a treatment by the doctor, but have not yet agreed to or scheduled their appointment.';
  };

  if (fetching) {
    return (
      <div className={classes.loaderStyleForCallList}>
        <Loader type="round" />
      </div>
    );
  }

  return (
    <div className={classes.callListContainer}>
      <div className={classes.callListHeaderBar}>
        <div className={classes.listInfo}>
          <StyledDiv font= {fontType} className={classes.title}>{renderTitle()}</StyledDiv>
          <StyledDiv font= {fontType} className={classes.description}>{renderDescription()}</StyledDiv>
        </div>
        {!!appoinmentData.length && (
          <div className={classes.listFilterButton}>
            {showRemoveFilterButton && (
              <Button
                className="mx-2"
                onClick={handleExplicitReset}
                buttonType={ButtonType.OUTLINE}
              >
                Remove Filter
              </Button>
            )}
            <Button
              className={classes.filterButton}
              onClick={() => setShowFilterOverlay(true)}
              id={'filterButton'}
            >
              Filter
            </Button>
          </div>
        )}
      </div>
      <FilterOverlay
        title="Filter Results"
        filters={filterFields}
        onApplyFilter={handleFilterApply}
        onApplyReset={handleImplicitReset}
        show={showFilterOverlay}
        andCondition={andCondition}
        setAndCondition={setAndCondition}
        closeFilterOverlay={() => setShowFilterOverlay(false)}
      />
      <Table
        headers={headers}
        isHeaderSticky={true}
        sortedBy="Patient.lomaScore"
        sortOrder={sortOrder}
        body={dataAfterFilter}
        aggregations={aggregation}
      />
      {showToaster && (
        <Toaster
          title="Success"
          setShowToaster={setShowToaster}
          description="Contact notes saved successfully."
          toasterType={ToasterType.SUCCESS}
          timeInterval={4000}
        />
      )}
      {showToggleToaster && (
        <Toaster
          title="Success"
          setShowToaster={setShowToggleToaster}
          description="Updated Successfully"
          toasterType={ToasterType.SUCCESS}
          timeInterval={4000}
        />
      )}
      {showModal && (
        <Modal
          show={showModal}
          header={{
            title: 'Add Contact Notes',
            actionItems: [],
          }}
          footer={{
            actionItems: [
              <Button loading={saveNotesLoading} onClick={() => saveNotes()}>
                Save Notes
              </Button>,
            ],
          }}
          onModalClose={onModalClose}
          onOverlayClick={() => {}}
        >
          <div>
            <StyledDiv font={fontType}>{new Date().toUTCString()}</StyledDiv>
            <StyledTextarea
              font={fontType}
              value={contactNotes}
              className={classNames('w-100', classes.saveNotes)}
              onChange={(e) => setContactNotes(e.target.value)}
            >
              Contact Notes
            </StyledTextarea>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UnscheduledList;
