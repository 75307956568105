import React, { useContext } from 'react';
import classes from './Header.module.css';
import { SignInContext } from '../../contexts/signin';
import { StyledDiv } from '../Styled/index';

interface HeaderMenuProps {
  title?: string;
  subTitle?: string;
  onClick?: () => void;
  children?: JSX.Element | JSX.Element[] | boolean;
  avatar: JSX.Element;
  onBlur?: () => void;
}

function HeaderMenu({
  title,
  subTitle,
  children,
  avatar,
  onClick,
  onBlur,
}: HeaderMenuProps) {
  const { fontType } = useContext(SignInContext);

  return (
    <>
      <button
        id="avatarContainer"
        onClick={onClick}
        onBlur={onBlur}
        className={`${classes.avatarContainer} d-flex flex-row`}
      >
        <div className={classes.avatar}>{avatar}</div>
        <div className={`d-flex flex-column`}>
          {title && <StyledDiv font={fontType} className={classes.headername}>{title}</StyledDiv>}
          {subTitle && <StyledDiv font={fontType} className={classes.headersubname}>{subTitle}</StyledDiv>}
        </div>
        {children}
      </button>
    </>
  );
}

export default HeaderMenu;
