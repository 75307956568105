import styled from 'styled-components';

export const StyledInputField = styled.input`
    outline: none;
    border: none;
    background: transparent;
    margin: 0px 0px 0px 5px;
    padding: 3px 0px 9px 0px;
`

export const StyledOptionsListContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledSelectedItemRemoveButton = styled.i`
  width: max-content;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  :hover {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    cursor: pointer;
  }
`;

export const StyledSelectedItemText = styled.span`
  border-radius: 8px;
  padding: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

export const StyledSelectedItem = styled.span`
  width: max-content;
  background: rgba(0, 140, 201, 0.15);
  border-radius: 4px;
  height: 30px;
  display: -webkit-inline-box;
  flex-direction: row;
  padding: 0px 5px;
  margin-top: 4px;
  margin-left: 10px;
`;

export const StyledDropDownOuter = styled.div`
  border: 1px solid #858c94;
  border-radius: 8px;
  z-index: 0;
  min-height: 40px;
  margin-top: 10px;
  position: relative;
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    background: #eeeeee;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    background: gray;
    border-radius: 5000px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledUnorderedList = styled.ul`
  background: #ffffff;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  list-style: none;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: auto;
  max-height: 250px;
  min-width: 46.5%;
  position: absolute;
  width: 100%;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledListItem = styled.li`
  text-align: center;
  margin-left: -32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  padding: 6px 5px 6px 5px;

  :hover {
    background-color: rgba(211, 224, 249, 0.4);
    text-align: center;
    margin: 0px 0px 0px -32px;
  }
  :active {
    background-color: rgba(174, 200, 248, 0.4);
  }
`;
