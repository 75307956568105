import { Analytics } from 'aws-amplify';

class AnalyticsService {
  provider: string;
  recordImmediate: boolean;
  constructor(provider = 'AWSPinpoint', recordImmediate = false) {
    this.provider = provider;
    this.recordImmediate = recordImmediate;
  }

  recordEvent(
    eventName: string,
    attributes: Record<string, string>,
    metrics?: Record<string, number>
  ) {
    Analytics.record({
      name: eventName,
      attributes,
      metrics,
      immediate: this.recordImmediate,
    });
  }

  disableRecording() {
    Analytics.disable();
  }

  enableRecording() {
    Analytics.enable();
  }

  trackSession(attributes: Record<string, string>) {
    Analytics.autoTrack('session', {
      enable: true,
      attributes: {
        msg: 'Session Tracking Start',
        ...attributes,
      },
      provider: this.provider,
    });
  }

  disableTrackSession() {
    Analytics.autoTrack('session', {
      enable: false,
    });
  }

  trackPageView(attributes: Record<string, string>) {
    Analytics.autoTrack('pageView', {
      enable: true,
      attributes: {
        msg: 'Page view started',
        ...attributes,
      },
      type: 'SPA',
      provider: this.provider,
      getUrl: () => {
        return window.location.origin + window.location.pathname;
      },
    });
  }

  disableTrackPageView() {
    Analytics.autoTrack('pageView', {
      enable: false,
    });
  }

  trackEvents(attributes: Record<string, string>) {
    Analytics.autoTrack('event', {
      enable: true,
      events: ['click'],
      selectorPrefix: 'data-amplify-analytics-',
      provider: this.provider,
      attributes: {
        clientIP: '',
        timestamp: '',
        ...attributes,
      },
    });
  }
}

export default AnalyticsService;
