/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      apptDate
      apptEndDate
      apptDetails
      apptNotes
      apptProcedure
      doctorId
      patientId
      preferredHygenistId
      locationId
      noShowProb
      contacted
      contactNotes {
        noteDetails
      }
      lastContacted
      isConfirmed
      isDeleted
      availForShortList
      procedureId
      recallDate
      parentName
      patientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createAppointment1 = /* GraphQL */ `
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      apptDate
      apptEndDate
      appointmentLocationId
      appointmentPatientId
      appointmentProviderId
      availForShortList
      id
    }
  }
`;

export const createAppointmentProvider = /* GraphQL */ `
  mutation CreateAppointmentProvider($input: CreateAppointmentProviderInput!) {
    createAppointmentProvider(input: $input) {
      id
    }
  }
`;

export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      apptDate
      apptEndDate
      apptDetails
      apptNotes
      apptProcedure
      doctorId
      patientId
      preferredHygenistId
      locationId
      noShowProb
      contacted
      contactNotes {
        noteDetails
      }
      lastContacted
      isConfirmed
      isDeleted
      availForShortList
      procedureId
      recallDate
      parentName
      patientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      apptDate
      apptEndDate
      apptDetails
      apptNotes
      apptProcedure
      doctorId
      patientId
      preferredHygenistId
      locationId
      noShowProb
      contacted
      contactNotes {
        noteDetails
      }
      lastContacted
      isConfirmed
      isDeleted
      availForShortList
      procedureId
      recallDate
      parentName
      patientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      name
      lomaScore
      email
      isActive
      metaData {
        createdAt
        createdBy
        updatedAt
        updatedBy
        trno
      }
      profile {
        gender
        imageUrl
        dob
        permissionGroups
      }
      Appointments {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      name
      lomaScore
      email
      isActive
      metaData {
        createdAt
        createdBy
        updatedAt
        updatedBy
        trno
      }
      profile {
        gender
        imageUrl
        dob
        permissionGroups
      }
      Appointments {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      name
      lomaScore
      email
      isActive
      metaData {
        createdAt
        createdBy
        updatedAt
        updatedBy
        trno
      }
      profile {
        gender
        imageUrl
        dob
        permissionGroups
      }
      Appointments {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createContactNotes =
  /* GraphQL */
  `
    mutation createContactNotes($input: CreateContactNotesInput!) {
      createContactNotes(input: $input) {
        id
      }
    }
  `;

export const createAppointmentContactNotes =
  /* GraphQL */
  `
    mutation createAppointmentContactNotes(
      $input: CreateAppointmentContactNotesInput!
    ) {
      createAppointmentContactNotes(input: $input) {
        id
        appointment {
          id
          apptId
          ContactNotes {
            items {
              contactNotes {
                createdAt
                email
                id
                noteDetails
                updatedAt
                username
              }
            }
          }
        }
      }
    }
  `;

export const createReactivationContactNotes =
  /* GrpahQL */
  `
  
  mutation createReactivationContactNotes(
    $input: CreateReactivationContactNotesInput!
    ) {
    createReactivationContactNotes(input: $input) {
      id
      reactivation {
        id
        ContactNotes {
          items {
            contactNotes {
              createdAt
              email
              id
              noteDetails
              updatedAt
              username
            }
          }
        }
      }
    }
  }
  
  `;

export const createUnscheduledContactNotes =
  /* GrpahQL */
  `
  
  mutation createUnscheduledContactNotes(
    $input: CreateUnscheduledContactNotesInput!
    ) {
    createUnscheduledContactNotes(input: $input) {
      id
      unscheduled {
        id
        ContactNotes {
          items {
            contactNotes {
              createdAt
              email
              id
              noteDetails
              updatedAt
              username
            }
          }
        }
      }
    }
  }
  
  `;

export const deleteAppointment1 = /* GraphQL */ `
  mutation deleteAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      id
    }
  }
`;

export const createUserAction =
  /* GraphQL */
  `
    mutation createUserActions($input: CreateUserActionsInput!) {
      createUserActions(input: $input) {
        id
        listType
      }
    }
  `;

export const updateAppointmentTime = /* GraphQL */ `
  mutation UpdateMyAppointment(
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(input: $input) {
      id
    }
  }
`;
export const signInEventLog =
  /* GraphQl */
  `
mutation signInEventLog($input: CreateUserActionsInput!) {
  createUserActions(input: $input) {
    id
  }
}
`;

export const updateCallShortCallLastContacted = /* GraphQL */ `
  mutation UpdateCallShortCallLastContacted(
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(input: $input) {
      id
      _version
    }
  }
`;

export const updateReactivationLastContacted = /* GraphQL */ `
  mutation UpdateReactivationLastContacted(
    $input: UpdateReactivationInput!
  ) {
    updateReactivation(input: $input) {
      id
      _version
    }
  }
`;

export const updateUnscheduledLastContacted = /* GraphQL */ `
  mutation UpdateUnscheduledLastContacted(
    $input: UpdateUnscheduledInput!
  ) {
    updateUnscheduled(input: $input) {
      id
      _version
    }
  }
`;

export const updateIsConfirmed = /* GraphQL */ `
  mutation UpdateIsConfirmed(
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(input: $input) {
      id
      _version
    }
  }
`;