interface BackgroundAndShape {
  borderRadius: string;
  borderLeft: string;
  borderRight: string;
  borderBottom: string;
}

interface LabelShape {
  [shapeName: string]: BackgroundAndShape;
}

export const legendLabel: LabelShape = {
  square: {
    borderRadius: '0%',
    borderLeft: '0',
    borderRight: '0',
    borderBottom: '0',
  },
  circle: {
    borderRadius: '50%',
    borderLeft: '0',
    borderRight: '0',
    borderBottom: '0',
  },
  rectangle: {
    borderRadius: '0%',
    borderLeft: '0',
    borderRight: '0',
    borderBottom: '0',
  },
  triangle: {
    borderRadius: '0%',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '18px solid blue',
  },
};
