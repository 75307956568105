import { API, graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';
import {
  createAppointment,
  createAppointment1,
  createAppointmentContactNotes,
  createContactNotes,
  createReactivationContactNotes,
  createUnscheduledContactNotes,
  deleteAppointment1,
  createAppointmentProvider,
  createUserAction,
  signInEventLog,
  updateAppointmentTime,
  updateCallShortCallLastContacted,
  updateReactivationLastContacted,
  updateUnscheduledLastContacted,
  updateIsConfirmed,
} from '../graphql/mutations';
import {
  getAppointment,
  syncAppointments,
  callList,
  appointmentList,
  listPatients,
  shortCallList,
  recreationList,
  unscheduledTreatment,
  schedulerGridProviderData,
  patientList,
  locationList,
  appointmentDetails,
  userActionList,
  appointmentDetailsForDeletion,
  schedulerGridData,
  getUserRolesAndPermissionsByID,
  fetchDentalPracticeList,
} from '../graphql/queries';

class Appointment {
  authToken: string;
  constructor(authToken: string) {
    this.authToken = authToken;
  }
  async fetchAppointments(apptDate: Date | string, dentalPracticeId: string) {
    try {
      let appointments: any;
      if (dentalPracticeId) {
        // filter with dentalPracticeid
        appointments = await API.graphql<any>(
          apptDate === 'All'
            ? graphqlOperation(
                appointmentList,
                {
                  filter: {
                    isDeleted: { ne: true },
                    appointmentDentalPracticeId: { eq: dentalPracticeId },
                  },
                },
                this.authToken
              )
            : graphqlOperation(
                appointmentList,
                {
                  filter: {
                    apptDate: {
                      contains: dayjs(apptDate).format('YYYY-MM-DD'),
                    },
                    isDeleted: { ne: true },
                    appointmentDentalPracticeId: { eq: dentalPracticeId },
                  },
                },
                this.authToken
              )
        );
      } else {
        appointments = await API.graphql<any>(
          apptDate === 'All'
            ? graphqlOperation(
                appointmentList,
                {
                  filter: {
                    isDeleted: { ne: true },
                  },
                },
                this.authToken
              )
            : graphqlOperation(
                appointmentList,
                {
                  filter: {
                    apptDate: {
                      contains: dayjs(apptDate).format('YYYY-MM-DD'),
                    },
                    isDeleted: { ne: true },
                  },
                },
                this.authToken
              )
        );
      }
      return appointments;
    } catch (err) {
      throw err;
    }
  }

  async fetchAppointmentById(apptId: string) {
    try {
      const appointment = await API.graphql(
        graphqlOperation(getAppointment, {
          id: apptId,
        })
      );
      return appointment;
    } catch (err) {
      throw err;
    }
  }

  async syncAppointments() {
    try {
      return await API.graphql(graphqlOperation(syncAppointments));
    } catch (err) {
      throw err;
    }
  }

  async createAppointmentForPatient(apptData: any) {
    try {
      return await API.graphql(graphqlOperation(createAppointment, apptData));
    } catch (err) {
      throw err;
    }
  }

  async fetchList(
    listType: string | undefined,
    selectedDentalPracticeId: string
  ) {
    let result: any;
    try {
      if (listType === 'call') {
        if(selectedDentalPracticeId){
          result = await API.graphql(
            graphqlOperation(
              callList,
              {
                filter: {
                  isDeleted: { ne: true },
                  availForShortList: { ne: true },
                  appointmentDentalPracticeId: { eq: selectedDentalPracticeId },
                },
              },
              this.authToken
            )
          );
        }
        else{
          result = await API.graphql(
            graphqlOperation(
              callList,
              {
                filter: {
                  isDeleted: { ne: true },
                  availForShortList: { ne: true },
                },
              },
              this.authToken
            )
          );
        }
        

      } else if (listType === 'short-call') {
        if(selectedDentalPracticeId){
          result = await API.graphql(
            graphqlOperation(
              shortCallList,
              {
                filter: {
                  isDeleted: { ne: true },
                  availForShortList: { eq: true },
                  appointmentDentalPracticeId: { eq: selectedDentalPracticeId },
                },
              },
              this.authToken
            )
          );
        }
        else{
          result = await API.graphql(
            graphqlOperation(
              shortCallList,
              {
                filter: {
                  isDeleted: { ne: true },
                  availForShortList: { eq: true },
                },
              },
              this.authToken
            )
          );
        }
        

      } else if (listType === 'reactivation') {
        if (selectedDentalPracticeId) {
          result = await API.graphql(
            graphqlOperation(
              recreationList,
              {
                filter: {
                  reactivationDentalPracticeId: {
                    eq: selectedDentalPracticeId,
                  },
                },
              },
              this.authToken
            )
          );
        } else {
          result = await API.graphql(graphqlOperation(recreationList));
        }
      } else if (listType === 'unscheduledTreatment') {
        if (selectedDentalPracticeId) {
          result = await API.graphql(
            graphqlOperation(
              unscheduledTreatment,
              {
                filter: {
                  unscheduledDentalPracticeId: { eq: selectedDentalPracticeId },
                },
              },
              this.authToken
            )
          );
        } else {
          result = await API.graphql(graphqlOperation(unscheduledTreatment));
        }
      }
      return result;
    } catch (err) {
      throw err;
    }
  }

  async fetchPatients() {
    try {
      const patientList: any = await API.graphql<any>(
        graphqlOperation(listPatients)
      );
      return patientList;
    } catch (err) {
      throw err;
    }
  }
  async fetchschedulerGridProviders(day: string, dentalPracticeId: string) {
    try {
      let result: any; 
      if(dentalPracticeId){
        result = await API.graphql(
          graphqlOperation(schedulerGridProviderData, {
            filter: {
              availableDays: { contains: day },
              providerDentalPracticeId: {eq: dentalPracticeId}
            },
          })
        );
      }
      else{
        result = await API.graphql(
          graphqlOperation(schedulerGridProviderData, {
            filter: {
              availableDays: { contains: day },
            },
          })
        );
      }
      
      return result;
    } catch (err) {
      throw err;
    }
  }

  // // calling two query combination
  async fetchGridData(filterDate: string, day: string, isCancelled: boolean) {
    let result: any;
    try {
      if (!isCancelled) {
        result = await API.graphql(
          graphqlOperation(schedulerGridData, {
            filter1: {
              apptDate: { contains: filterDate },
              isDeleted: { ne: !isCancelled },
            },

            filter2: {
              availableDays: { contains: day },
            },
          })
        );
      } else {
        result = await API.graphql(
          graphqlOperation(schedulerGridData, {
            filter1: {
              apptDate: { contains: filterDate },
            },

            filter2: {
              availableDays: { contains: day },
            },
          })
        );
      }
      return result;
    } catch (err) {
      throw err;
    }
  }

  async createContactNotes(username: string | undefined, noteDetails: string) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createContactNotes, {
          input: { noteDetails: noteDetails, username: username },
        })
      );
      return response;
    } catch (err) {
      throw err;
    }
  }
  async fetchPatientList() {
    try {
      const list: any = await API.graphql(graphqlOperation(patientList));
      return list;
    } catch (err) {
      throw err;
    }
  }

  async fetchLocationList(dentalPracticeId: any) {
    try {
      const location: any = await API.graphql(
        graphqlOperation(locationList, {
          filter: {
            // locationDentalPracticeId: { eq: dentalPracticeId },
          },
        })
      );
      return location;
    } catch (err) {
      throw err;
    }
  }

  async fetchAppointmentDetails(apptId: string) {
    try {
      const detailsOfAppointment: any = await API.graphql(
        graphqlOperation(appointmentDetails, {
          id: apptId,
        })
      );
      return detailsOfAppointment;
    } catch (err) {
      throw err;
    }
  }

  async fetchAppointmentDetailsForDeletion(apptId: string) {
    try {
      const detailsOfAppointment: any = await API.graphql(
        graphqlOperation(appointmentDetailsForDeletion, {
          id: apptId,
        })
      );
      return detailsOfAppointment;
    } catch (err) {
      throw err;
    }
  }

  async deleteAppointment(id: string, _version: number, isDeleted: boolean) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(deleteAppointment1, {
          input: {
            id,
            _version,
            isDeleted,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createAppointmentContactNotes(
    appointmentID: string,
    contactNotesID: string
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createAppointmentContactNotes, {
          input: {
            appointmentID: appointmentID,
            contactNotesID: contactNotesID,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createReactivationContactNotes(
    reactivationID: string,
    contactNotesID: string
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createReactivationContactNotes, {
          input: {
            reactivationID: reactivationID,
            contactNotesID: contactNotesID,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createUnscheduledContactNotes(
    appointmentID: string,
    contactNotesID: string
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createUnscheduledContactNotes, {
          input: {
            unscheduledID: appointmentID,
            contactNotesID: contactNotesID,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createUserAction(
    username: string | undefined,
    email: string | undefined,
    event: string,
    listType?: string
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createUserAction, {
          input: {
            username: username,
            event: event,
            email: email,
            listType: listType,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createUserAction1(
    username: string | undefined,
    email: string | undefined,
    event: string,
    log?: string
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createUserAction, {
          input: {
            username: username,
            event: event,
            email: email,
            log: log,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createAppointment(
    apptDate: string,
    apptEndDate: string,
    appointmentLocationId: string,
    appointmentPatientId: string,
    appointmentProviderId: string,
    availForShortList: boolean,
    dentalPracticeId: string
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createAppointment1, {
          input: {
            apptDate: apptDate,
            apptEndDate: apptEndDate,
            appointmentLocationId: appointmentLocationId,
            appointmentPatientId: appointmentPatientId,
            appointmentProviderId: appointmentProviderId,
            availForShortList: availForShortList,
            appointmentDentalPracticeId: dentalPracticeId,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createAppointmentProvider(appointmentID: string, providerID: string) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(createAppointmentProvider, {
          input: {
            appointmentID,
            providerID,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveUserAction(userName: any, eventName: string) {
    try {
      const userAction: any = await API.graphql(
        graphqlOperation({
          // userName: userName,
          // eventName: eventName
        })
      );
      return userAction;
    } catch (err) {
      throw err;
    }
  }

  async fetchUserAction(eventName: string, filteredDate?: string | Date) {
    try {
      const list: any = await API.graphql(
        graphqlOperation(
          userActionList,
          {
            filter: {
              event: { eq: eventName },
              createdAt: { ge: filteredDate },
            },
            limit: 10000,
          },
          this.authToken
        )
      );
      return list;
    } catch (err) {
      throw err;
    }
  }

  async signInEventLog(event?: string, payload?: any) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(signInEventLog, {
          input: {
            email: payload.email,
            event: event,
            username: payload.username,
            log: JSON.stringify({
              sessionId: payload.sessionId,
            }),
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async signInEventLog1(event?: string, payload?: any) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(signInEventLog, {
          input: {
            email: payload.email,
            event: event,
            username: payload.username,
            log: JSON.stringify({
              sessionId: payload.sessionId,
            }),
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateAppointment(
    id: string,
    _version: number,
    apptDate: string,
    apptEndDate: string
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(updateAppointmentTime, {
          input: {
            id,
            _version,
            apptDate,
            apptEndDate,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateLastContactedValue(
    id: string,
    _version: number,
    lastContacted: string,
    listType: string | undefined
  ) {
    try {
      const response: any = await API.graphql<any>(
        listType === 'reactivation'
          ? graphqlOperation(updateReactivationLastContacted, {
              input: {
                id,
                _version,
                lastContacted,
              },
            })
          : listType === 'unscheduledTreatment'
          ? graphqlOperation(updateUnscheduledLastContacted, {
              input: {
                id,
                _version,
                lastContacted,
              },
            })
          : graphqlOperation(updateCallShortCallLastContacted, {
              input: {
                id,
                _version,
                lastContacted,
              },
            })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateIsConfirmedValue(
    id: string,
    _version: number,
    isConfirmed: boolean
  ) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(updateIsConfirmed, {
          input: {
            id,
            _version,
            isConfirmed,
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getUserRolesAndPermissionsByID(userID?: string) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(getUserRolesAndPermissionsByID, {
          filter: {
            userId: { eq: userID },
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchDentalPractices(organizationID?: string) {
    try {
      const response: any = await API.graphql<any>(
        graphqlOperation(fetchDentalPracticeList, {
          filter: {
            userRolesAndPermissionsOrganizationId: { eq: organizationID },
          },
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default Appointment;
