import React from 'react';
import BestFitTable from './BestFitTable';

const sampleData = [
  {
    rank: 1,
    prospectInfo: {
      patient: {
        patientName: 'John Doe',
        patientId: 'TRANS_123',
        lomaScore: 10,
      },
      preferredProvider: {
        dentistName: 'Dr. Smith',
        hygienistName: 'Dwayne John',
      },
    },
    scheduledDate: '2022-10-11',
    durationInHrs: 2,
    preferredTimeSlot: 'Morning',
    preferredDay: 'Monday',
  },
  {
    rank: 2,
    prospectInfo: {
      patient: {
        patientName: 'Chris Evans',
        patientId: 'TRANS_124',
        lomaScore: 9,
      },
      preferredProvider: {
        dentistName: 'Dr. Jones',
        hygienistName: 'Jane Doe',
      },
    },
    scheduledDate: '2022-10-12',
    durationInHrs: 1,
    preferredTimeSlot: 'Evening',
    preferredDay: 'Tuesday',
  },
  {
    rank: 3,
    prospectInfo: {
      patient: {
        patientName: 'Hardy Kaiy',
        patientId: 'TRANS_125',
        lomaScore: 8,
      },
      preferredProvider: {
        dentistName: 'Dr. Sumit',
        hygienistName: 'Leonardo Di',
      },
    },
    scheduledDate: '2022-10-10',
    durationInHrs: 0.5,
    preferredTimeSlot: 'Evening',
    preferredDay: 'Tuesday',
  },
  {
    rank: 4,
    prospectInfo: {
      patient: {
        patientName: 'Chris Hemsworth',
        patientId: 'TRANS_126',
        lomaScore: 7,
      },
      preferredProvider: {
        dentistName: 'Dr. Winslet',
        hygienistName: 'Harry',
      },
    },
    scheduledDate: '2022-10-14',
    durationInHrs: 1,
    preferredTimeSlot: 'Afternoon',
    preferredDay: 'Thursday',
  },
  {
    rank: 5,
    prospectInfo: {
      patient: {
        patientName: 'Katte Kaiy',
        patientId: 'TRANS_127',
        lomaScore: 6,
      },
      preferredProvider: {
        dentistName: 'Dr. Valle',
        hygienistName: 'Vanne vais',
      },
    },
    scheduledDate: '2022-10-13',
    durationInHrs: 2,
    preferredTimeSlot: 'Morning',
    preferredDay: 'Wednesday',
  },
];
const header = [
  {
    val: 'Rank',
    colSpan: 1,
  },
  {
    val: 'ProspectInfo',
    colSpan: 3,
  },
  {
    val: 'Preffered Provider',
    colSpan: 2,
  },
  {
    val: 'Date',
    colSpan: 1,
  },
  {
    val: 'Duration(HH)',
    colSpan: 1,
  },
  {
    val: 'Preffered Time',
    colSpan: 1,
  },
  {
    val: 'Preffered day',
    colSpan: 1,
  },
];

const subHeader = [
  {
    val: '',
    colSpan: 1,
  },
  {
    val: 'Patient name',
    colSpan: 1,
  },
  {
    val: 'Patient ID',
    colSpan: 1,
  },
  {
    val: 'lomascore',
    colSpan: 1,
  },
  {
    val: 'Dentist name',
    colSpan: 1,
  },
  {
    val: 'Hygienist name',
    colSpan: 1,
  },
  {
    val: '',
    colSpan: 1,
  },
  {
    val: '',
    colSpan: 1,
  },
  {
    val: '',
    colSpan: 1,
  },
  {
    val: '',
    colSpan: 1,
  },
];

function RankTable() {
  return (
    <>
      <BestFitTable header={header} subHeader={subHeader} data={sampleData} />
    </>
  );
}

export default RankTable;
