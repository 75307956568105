import { Filter, FilterType } from '../../components/FilterOverlay/index';

export namespace ReactivationFilterListConf {

  export const recreationListFilterFields: Filter[] = [
    {
      type: FilterType.TEXT,
      displayName: 'Prefered Provider',
      placeholder: 'Search prefered provider',
      fieldName: 'preferredProvider',
      value: '',
    },
    {
      type: FilterType.TEXT,
      displayName: 'Patient Name',
      placeholder: 'Search Patient Name',
      fieldName: 'patientName',
      value: '',
    },
    {
      type: FilterType.NUMBER,
      displayName: 'LoMA Score',
      placeholder: 'Search LoMA Score',
      fieldName: 'lomaScore',
      value: '',
    },
  ];

  export const getFilterListFields = (): Filter[] => {
      return recreationListFilterFields;
  };
}
