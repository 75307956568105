import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import _orderBy from 'lodash/orderBy';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Arrow } from '../../images/arrow.svg';
import { ReactComponent as UpDownArrow } from '../../images/up_down_arrow.svg';
import { ReactComponent as SortArrow } from '../../images/sort_arrow.svg';
import './table.css';
import Checkbox from '../form/Checkbox';
import TableHeaderBar from './TableHeaderBar';
import { TableTypes } from './tableTypes';
import TableRow from './TableRow';
import DataNotFound from '../DataNotFound/DataNotFound';
import { SignInContext } from '../../contexts/signin';
import { StyledSpan } from '../Styled/index';

const defaultProps: TableTypes.TableProps = {
  selectable: false,
  body: [],
  pageSizes: [5, 10, 20],
  showPagination: true,
  headers: [],
  isHeaderSticky: false,
  defaultPageSize: 10,
  showAvatarFirst: false,
  customAvatar: null,
  tableActions: [],
  sortedBy: '',
  sortOrder: TableTypes.TableSort.NO_SORT,
};

const Table = ({
  body,
  headers,
  pageSizes,
  showPagination,
  defaultPageSize,
  showAvatarFirst,
  selectable,
  sortedBy,
  sortOrder,
  aggregations,
  isHeaderSticky
}: TableTypes.TableProps) => {
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const { fontType } = useContext(SignInContext);


  const [totalPages, setTotalPages] = useState(
    Math.ceil(body.length / pageSize)
  );

  const [pageNumber, setPageNumber] = useState<number>(body.length ? 0 : -1);

  const pageOffset: number = pageNumber * pageSize;

  const pageOffsetEnd: number = pageOffset + pageSize;

  const memoisedTableSettings: TableTypes.TableSettings = useMemo(() => {
    const memoisedHeaders: TableTypes.ColumnsSetting = headers.reduce(
      (newHeaders, column) => {
        return {
          ...newHeaders,
          [column.value]: {
            ...column,
            searchValue: '',
            searchEnabled: false,
            sortable: column.sortable === false ? false : true,
            currentSort:
              column.value === sortedBy
                ? sortOrder
                : TableTypes.TableSort.NO_SORT,
          },
        };
      },
      {}
    );

    const column: TableTypes.ColumnSetting = memoisedHeaders[sortedBy];

    const sortedBody: TableTypes.TableBody[] =
      sortOrder !== TableTypes.TableSort.NO_SORT && column
        ? _orderBy(body, [column.value], [sortOrder])
        : [...body];

    const memoisedBody: TableTypes.RowsSetting[] = sortedBody.map(
      (newBody) => ({
        ...newBody,
        selected: false,
        currentCollapseState: TableTypes.CollapseState.COLLAPSED,
      })
    );
    return {
      rows: memoisedBody,
      columns: memoisedHeaders,
    };
  }, [headers, body, sortOrder, sortedBy]);

  const [tableSettings, setTableSettings] = useState<TableTypes.TableSettings>(
    memoisedTableSettings
  );

  const nonExpandableColumns = useMemo(() => {
    return Object.values(tableSettings.columns).filter(
      (column) => !column.expandable
    );
  }, [tableSettings.columns]);

  const nRowsSelected = useMemo(() => {
    return Object.values(tableSettings.rows).filter((row) => row.selected)
      .length;
  }, [tableSettings.rows]);

  useEffect(() => {
    setTableSettings(memoisedTableSettings);
  }, [memoisedTableSettings]);

  useEffect(() => {
    const newTotalPages = Math.ceil(tableSettings.rows.length / pageSize);
    setTotalPages(newTotalPages);
    setPageNumber(0);
  }, [pageSize, tableSettings.rows.length]);

  useEffect(() => {
    setTotalPages(Math.ceil(tableSettings.rows.length / pageSize));
  }, [pageSize, tableSettings.rows.length]);

  const handleRowClick = (rowId: string) => {
    const currentCollapseState = tableSettings.rows.find(
      (row) => row.id === rowId
    )?.currentCollapseState;
    setTableSettings({
      ...tableSettings,
      rows: tableSettings.rows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            currentCollapseState:
              currentCollapseState === TableTypes.CollapseState.COLLAPSED
                ? TableTypes.CollapseState.EXPANDED
                : TableTypes.CollapseState.COLLAPSED,
          };
        } else {
          return {
            ...row,
            currentCollapseState: TableTypes.CollapseState.COLLAPSED,
          };
        }
      }),
    });
  };

  const handleRowsSelect = (selected: boolean) => {
    setTableSettings({
      ...tableSettings,
      rows: tableSettings.rows.map((row) => ({
        ...row,
        selected,
      })),
    });
  };

  const handleChangePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected);
  };

  const handleColumnClick = (column: TableTypes.ColumnSetting) => {
    const { columns }: TableTypes.TableSettings = tableSettings;

    let initialRows: TableTypes.RowsSetting[] = [...tableSettings.rows];

    let newSort: TableTypes.TableSort = TableTypes.TableSort.NO_SORT;

    const hasSortValue = !!initialRows.find(
      (row) => row[column.value]?.sortValue
    );

    const sortField = initialRows.find((row) => row[column.value]?.sortField)?.[
      column.value
    ]?.sortField;

    const getRowFieldValue = (obj: TableTypes.RowsSetting) =>
      obj[column.value]?.sortValue;

    if (column.currentSort === TableTypes.TableSort.ASC) {
      // DESC
      if (hasSortValue) {
        initialRows = [...initialRows].sort(
          (a, b) => getRowFieldValue(b) - getRowFieldValue(a)
        );
      } else {
        initialRows = _orderBy(
          initialRows,
          [sortField || column.value],
          [TableTypes.TableSort.DESC]
        );
      }
      newSort = TableTypes.TableSort.DESC;
    } else {
      // ASC
      if (hasSortValue) {
        initialRows = [...initialRows].sort(
          (a, b) => getRowFieldValue(a) - getRowFieldValue(b)
        );
      } else {
        initialRows = _orderBy(
          initialRows,
          [sortField || column.value],
          [TableTypes.TableSort.ASC]
        );
      }
      newSort = TableTypes.TableSort.ASC;
    }
    setTableSettings({
      columns: {
        ...Object.values(columns)
          .map((column) => ({
            ...column,
            currentSort: TableTypes.TableSort.NO_SORT,
          }))
          .reduce(
            (prev, curr) => ({
              ...prev,
              [curr.value]: curr,
            }),
            {}
          ),
        [column.value]: {
          ...columns[column.value],
          currentSort: newSort,
        },
      },
      rows: initialRows,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const renderBody = () =>
    tableSettings.rows
      .slice(pageOffset, pageOffset + pageSize)
      .map((row, idx) => {
        const isRowExpanded: boolean =
          row.currentCollapseState === TableTypes.CollapseState.EXPANDED;
        return (
          <TableRow
            row={row}
            key={row.id}
            rowIndex={idx}
            aggregations={aggregations}
            isRowExpanded={isRowExpanded}
            handleRowClick={handleRowClick}
            columns={Object.values(tableSettings.columns)}
          />
        );
      });

  return (
    <div>
      {!!body.length ? (
        <div>
          <div className={'tableStyle'}>
            {!!nRowsSelected && (
              <TableHeaderBar nRowsSelected={nRowsSelected} />
            )}
            <table className="table table-borderless" id="truro-table">
              <thead className="header_color">
                <tr
                  className={`table-head header_color ${isHeaderSticky ? 'stickyHeader' : ''}`}>
                  {showAvatarFirst && <th />}
                  <th colSpan={1} />
                  {selectable && (
                    <th>
                      <Checkbox onCheck={handleRowsSelect} />
                    </th>
                  )}
                  {nonExpandableColumns.map((column, index) => (
                    <th
                      key={column.value + index}
                      colSpan={column.colSpan || 1}
                      className={column.textCenter ? 'text-center' : ''}
                    >
                      <div
                        className={`d-flex flex-column ${
                          column.textCenter
                            ? 'align-items-center'
                            : 'align-items-start'
                        }`}
                      >
                        <div className="d-flex align-items-center">
                          <div className="d-flex flex-column">
                            <StyledSpan font= {fontType}
                              className="table-header-text"
                              onClick={() => handleColumnClick(column)}
                            >
                              {column.displayName}
                            </StyledSpan>
                          </div>
                          {column.sortable && (
                            <>
                              {column.currentSort ===
                                TableTypes.TableSort.NO_SORT && <UpDownArrow />}
                              {column.currentSort ===
                                TableTypes.TableSort.ASC && <SortArrow />}
                              {column.currentSort ===
                                TableTypes.TableSort.DESC && (
                                <SortArrow className="sort-arrow--down" />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="test-row">{renderBody()}</tbody>
            </table>
          </div>
          <div className="pagination-test">
            {showPagination && (
              <div className="d-flex align-items-center justify-content-between table-footer marginLeft marginRight">
                <span>
                  Showing <strong>{pageOffset + 1}</strong> to{' '}
                  <strong>
                    {pageOffsetEnd > tableSettings.rows.length
                      ? tableSettings.rows.length
                      : pageOffsetEnd}
                  </strong>{' '}
                  of <strong>{tableSettings.rows.length}</strong> results
                </span>

                <div className="d-flex align-items-center">
                  <label htmlFor="pageSize">Select:</label>
                  <select
                    value={pageSize}
                    className="mx-2"
                    id="pageSize"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      handlePageSizeChange(+e.target.value)
                    }
                  >
                    {pageSizes.map((pageSize) => (
                      <option key={pageSize}>{pageSize}</option>
                    ))}
                  </select>
                </div>
                <ReactPaginate
                  previousLabel={<Arrow className="left-arrow" />}
                  breakLabel="..."
                  nextLabel={<Arrow className="right-arrow" />}
                  pageCount={totalPages}
                  forcePage={pageNumber}
                  onPageChange={handleChangePage}
                  containerClassName="paginationBttns"
                  previousLinkClassName="previousBttn"
                  nextLinkClassName="nextBttn"
                  disabledClassName="paginationDisabled"
                  activeClassName="paginationActive"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
};

Table.defaultProps = defaultProps;

export default Table;
