import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/authService';
import Input, { InputFieldType } from '../../components/Input';
import classes from './forgotPasswordPage.module.css';
import Button, { ButtonType, Type } from '../../components/Button';
import Toaster, { ToasterType } from '../../components/Toaster';

interface ForgotpasswordInput {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [code] = useState('');
  const [password] = useState('');
  const [confirmPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState<boolean>(true);
  const [otpPageShow, setOtpPageShow] = useState<boolean>(false);
  const [errorToaster, setErrorToaster] = useState(false);
  const [toasterDescription, setToasterDescription] = useState('');

  const navigate = useNavigate();
  const authService = new AuthService();

  const forgotpasswordInput: ForgotpasswordInput = {
    email: email,
    code: code,
    password: password,
    confirmPassword: confirmPassword,
  };

  const submitHandler = (event: any) => {
    event.preventDefault();

    authService
      .resetPasswordfill(
        forgotpasswordInput.email,
        forgotpasswordInput.code,
        forgotpasswordInput.password
      )
      .then((message) => {
        if (message) {
          navigate('/login');
        } else {
          setToasterDescription('Invalid Otp');
          setErrorToaster(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const emailHandler = (userEmail: string) => {
    setEmail(userEmail);
    forgotpasswordInput.email = userEmail;
  };

  const otpHandler = (userCode: string) => {
    forgotpasswordInput.code = userCode;
  };

  const passwordHandler = (userPassword: string) => {
    forgotpasswordInput.password = userPassword;
  };

  const confirmPasswordHandler = (userPassword: string) => {
    forgotpasswordInput.confirmPassword = userPassword;
  };

  function nextPageHandler() {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(forgotpasswordInput.email)) {
      authService
        .resetPassword(forgotpasswordInput.email)
        .then((otpRequest) => {
          if (otpRequest) {
            setForgotPassword(false);
            setOtpPageShow(true);
          } else {
            setToasterDescription('Email is not registered with us');
            setErrorToaster(true);
          }
        })
        .catch((error) => console.log(error));
    } else {
      setToasterDescription('Invalid Email');
      setErrorToaster(true);
    }
  }

  function resendPassword() {
    authService.resetPassword(forgotpasswordInput.email).then((otpRequest) => {
      if (otpRequest) {
        setForgotPassword(false);
        setOtpPageShow(true);
      } else {
        setToasterDescription('Email is not registered with us');
        setErrorToaster(true);
      }
    });
  }

  return (
    <div
      className={
        classes.forgotPasswordPage
      } /*style={{backgroundColor: "blue"}}*/
    >
      <form className={classes.forgotPasswordForm} onSubmit={submitHandler}>
        {forgotPassword && (
          <>
            <p className={classes.forgotPasswordHead}>Forgot Password</p>
            <div style={{ marginTop: '5px' }}>
              <Input
                label="Email"
                placeholder="Enter email"
                isRequired
                onValueChange={emailHandler}
              />
            </div>

            <div className={classes.flexButton}>
              <Button
                text="Generate Otp"
                type={Type.BUTTON}
                buttonType={ButtonType.FILLED}
                onClick={nextPageHandler}
              />
              <Button
                text="Back To Login"
                type={Type.BUTTON}
                buttonType={ButtonType.OUTLINE}
                onClick={() => navigate('/login')}
              />
            </div>
          </>
        )}

        {otpPageShow && (
          <>
            <p className={classes.forgotPasswordHead}>
              Enter OTP sent to registered Email Address and Enter New Password
            </p>
            <div style={{ marginTop: '5px' }}>
              <Input
                label="Enter Otp"
                placeholder="Enter Otp"
                isRequired
                onValueChange={otpHandler}
              />
              <br />
              <Input
                label="New Password"
                placeholder="Enter New Password"
                isRequired
                onValueChange={passwordHandler}
                type={InputFieldType.PASSWORD}
              />
              <br />
              <Input
                label="Re-confirm Password"
                placeholder="Re-Enter New Password"
                isRequired
                onValueChange={confirmPasswordHandler}
                type={InputFieldType.PASSWORD}
              />
            </div>

            <div className={classes.flexButton}>
              <Button
                text="Continue"
                type={Type.SUBMIT}
                buttonType={ButtonType.FILLED}
              />
              <Button
                text="Resend Otp"
                type={Type.BUTTON}
                buttonType={ButtonType.OUTLINE}
                onClick={resendPassword}
              />
            </div>
          </>
        )}

        {errorToaster && (
          <Toaster
            title={'Error'}
            setShowToaster={setErrorToaster}
            description={toasterDescription}
            toasterType={ToasterType.ERROR}
            timeInterval={4000}
          />
        )}
      </form>
    </div>
  );
}

export default ForgotPassword;
