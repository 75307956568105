import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classes from './Signup.module.css';
import Input, { InputFieldType } from '../../components/Input';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import Button, { ButtonType, Type } from '../../components/Button';
import AuthService from '../../services/authService';
import { SignInContext, SignInContextType } from '../../contexts/signin';
import Toaster, { ToasterType } from '../../components/Toaster';

interface SignupInput {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

const authService = new AuthService();

function SignUp() {
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formErrors, setFormErrors]: any = useState({});
  const [isSubmit, setIsSubmit] = useState(0);
  const { onSignUp } = useContext<SignInContextType>(SignInContext);
  const [loading, setLoading] = useState(false);
  const [successToaster, setSuccessToaster] = useState(false);

  const navigate = useNavigate();
  const isEnabled =
    username.length > 0 &&
    email.length > 0 &&
    password.length > 0 &&
    confirmPassword.length > 0;

  const signupInput: SignupInput = {
    name: username,
    email: email,
    password: password,
    passwordConfirmation: confirmPassword,
  };

  const userNameHandler = (userName: string) => {
    setUserName(userName);
    signupInput.name = userName;
    if (isSubmit) {
      setFormErrors(formValidation(signupInput));
    }
  };

  const emailHandler = (userEmail: string) => {
    setEmail(userEmail);
    signupInput.email = userEmail;
    if (isSubmit) {
      setFormErrors(formValidation(signupInput));
    }
  };

  const passwordHandler = (userPassword: string) => {
    setPassword(userPassword);
    signupInput.password = userPassword;
    if (isSubmit) {
      setFormErrors(formValidation(signupInput));
    }
  };

  const confirmPasswordHandler = (userConfirmPassword: string) => {
    setConfirmPassword(userConfirmPassword);
    signupInput.passwordConfirmation = userConfirmPassword;
    if (isSubmit) {
      setFormErrors(formValidation(signupInput));
    }
  };

  const formValidation = (formValues: SignupInput) => {
    const errors: any = {};
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (!regex.test(formValues.email)) {
      errors.email = 'Must be a valid email!';
    }
    if (formValues.name.length < 4) {
      errors.name = 'Must be atleast 4 characters !';
    }
    if (formValues.password.length < 8) {
      errors.password = 'Must be atleast 8 characters !';
    }
    if (formValues.passwordConfirmation !== formValues.password) {
      errors.confirmPassword = 'Password should match with confirm password !';
    }
    return errors;
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    setFormErrors(formValidation(signupInput));
    setIsSubmit(1);
    if (isEmpty(formValidation(signupInput)) === true) {
      authService
        .signUp({
          username: signupInput.email,
          password: signupInput.password,
          attributes: {
            email: signupInput.email,
            name: username,
          },
        })
        .then(({ user }) => {
          if (user) {
            onSignUp(user);
            navigate('/login');
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className={classNames(classes.signupPage)}>
      {successToaster && (
        <Toaster
          title={'Error'}
          setShowToaster={setSuccessToaster}
          description={'Username/Email already exists!'}
          toasterType={ToasterType.ERROR}
          timeInterval={4000}
        />
      )}
      <form className={classes.signupForm} onSubmit={submitHandler}>
        <p className={classes.signupHeading}>Sign Up</p>

        <p className={classes.signupDesc}>
          Create account with your Email ID and get access to Truro.
        </p>

        <div className={classes.inputField} id="signUpUserName">
          <Input
            isRequired={true}
            type={InputFieldType.TEXT}
            label="Username"
            placeholder="Choose username"
            onValueChange={userNameHandler}
          />
        </div>

        {!!formErrors.name && (
          <p className={classes.error}>{formErrors.name}</p>
        )}

        <div className={classes.inputField} id="signUpEmail">
          <Input
            isRequired={true}
            type={InputFieldType.TEXT}
            label="Email"
            placeholder="xyz@gmail.com"
            onValueChange={emailHandler}
          />
        </div>

        {!!formErrors.email && (
          <p className={classes.error}>{formErrors.email}</p>
        )}

        <div className={classes.inputPasswordField} id="signUpPassword">
          <Input
            isRequired={true}
            type={InputFieldType.PASSWORD}
            label="Password"
            placeholder="Create a password"
            onValueChange={passwordHandler}
          />
        </div>

        {!!formErrors.password && (
          <p className={classes.error}>{formErrors.password}</p>
        )}

        <div className={classes.inputPasswordField} id="signUpConfirmPassword">
          <Input
            isRequired={true}
            type={InputFieldType.PASSWORD}
            label="Confirm Password"
            placeholder="Enter created password"
            onValueChange={confirmPasswordHandler}
          />
        </div>

        {!!formErrors.confirmPassword && (
          <p className={classes.error}>{formErrors.confirmPassword}</p>
        )}

        <div className={classes.createAccountButton}>
          <Button
            type={Type.SUBMIT}
            disabled={!isEnabled}
            text="Create Account"
            loading={loading}
            buttonType={ButtonType.FILLED}
          />
        </div>

        <p className={classes.signupFooter}>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </form>
    </div>
  );
}

export default SignUp;
