import { createContext } from 'react';

export type OpenedBy = 'hover' | 'hamburger-click' | '';

interface SideBarConfig {
  opened: boolean;
  openedBy: OpenedBy;
  setOpened: (open: boolean, openedBy: OpenedBy) => void;
}

export const sideBarConfig: SideBarConfig = {
  opened: window.innerWidth>500 ? true : false,  // true for desktop, false for mobile
  openedBy: 'hamburger-click',
  setOpened: () => {},
};

const SidebarContext = createContext(sideBarConfig);

export default SidebarContext;