import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { SignInContext } from '../../contexts/signin';
import { StyledP,StyledInput } from '../Styled/index';

export enum InputFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  LIST = 'list',
  PASSWORD = 'password',
  EMAIL = 'email',
}

interface InputProps {
  type: InputFieldType;
  label: string;
  placeholder: string;
  isRequired: boolean;
  color: string;
  defaultValue: any;
  disabled: boolean;
  onValueChange: (item: any) => void;
  onEnterPress: (item: string) => void;
  id?: string;
}

const defaultInputProps = {
  type: InputFieldType.TEXT,
  label: '',
  placeholder: '',
  isRequired: false,
  color: '',
  defaultValue: '',
  disabled: false,
  onValueChange: () => {},
  onEnterPress: () => {},
};

function Input(props: InputProps) {
  const { fontType } = useContext(SignInContext);
  const [number, setNumber] = useState<string>('');
  const [text, setText] = useState<any>('');
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [show /*, setShow */] = useState(false);

  useEffect(() => {
    setText(props.defaultValue);
    setNumber(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <div className="margin_top_1">
      <StyledP
        font= {fontType}
        className={`label ${props.label ? 'background-white' : ''} ${
          props.color === 'red' ? 'red' : ''
        }`}
      >
        {props.label}
        <span className="red">{props.isRequired ? ' *' : ''}</span>
      </StyledP>

      {props.type === InputFieldType.TEXT && (
        <StyledInput
          font= {fontType}
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setText(e.target.value);
          }}
          disabled={props.disabled}
          value={text}
          placeholder={props.placeholder}
          id={props.id}
        />
      )}

      {props.type === InputFieldType.NUMBER && (
        <StyledInput
          font= {fontType}
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setNumber(e.target.value);
          }}
          disabled={props.disabled}
          value={number}
          placeholder={props.placeholder}
          id={props.id}
        />
      )}

      {props.type === InputFieldType.PASSWORD && (
        <>
          <div className="password">
            <StyledInput
              font= {fontType}
              className={`text_input ${
                props.color === 'red' ? 'border-red' : 'border-gray'
              }`}
              type={show ? 'text' : props.type}
              onChange={(e) => {
                props.onValueChange(e.target.value);
                setPassword(e.target.value);
              }}
              value={password}
              placeholder={props.placeholder}
              id={props.id}
            />
            {/* <span className="eye" onClick={()=>setShow(!show)}>WIP</span> */}
          </div>
        </>
      )}

      {props.type === InputFieldType.EMAIL && (
        <StyledInput
          font= {fontType}
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setEmail(e.target.value);
          }}
          value={email}
          placeholder={props.placeholder}
        />
      )}

      {props.type === InputFieldType.DATE && (
        <StyledInput
          font= {fontType}
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setDate(e.target.value);
          }}
          value={date}
          placeholder={props.placeholder}
        />
      )}
    </div>
  );
}
Input.defaultProps = defaultInputProps;
export default Input;
