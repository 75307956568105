export interface FilterDataTypes {
    displayName: string,
    value: number
}

const FilterData: FilterDataTypes[] = [
    {
        displayName: 'Today',
        value: 0
    },
    {
        displayName: 'Yesterday',
        value: 1
    },
    {
        displayName: 'Last 1 Week',
        value: 7
    },
    {
        displayName: 'Last 2 Week',
        value: 14
    },
    {
        displayName: 'Last 1 Month',
        value: 30
    },
    {
        displayName: 'Last 3 Month',
        value: 90
    },
    {
        displayName: 'All Data',
        value: -1
    }
]

export default FilterData;