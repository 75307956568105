// Adding Comment for build

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import extendPlugins from './utils/plugins';
import awsExports from './aws-exports';
import { Amplify } from 'aws-amplify';

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Extend any plugins // Ex: dayjs plugins
extendPlugins();

console.log('App Version:', process.env.REACT_APP_VERSION);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
