import React, { useContext } from 'react';
import classes from './Schedules.module.css';
import { SchedulerGridConf, giveHeight } from '../../utils/schedulerGridConf';
import Cell from './Cell';
import { HygienistSchedulesProps } from './schedulerTypes';
import { SignInContext } from '../../contexts/signin';
import { StyledH5 } from '../Styled/index';

const headerHeight = SchedulerGridConf.headerHeight.value;
const rowheight = SchedulerGridConf.rowHeight.value;
const numberOfRows =
  (SchedulerGridConf.totalWorkingHours / SchedulerGridConf.hoursDuration) *
  SchedulerGridConf.slotsPerHour;
const columWidth = SchedulerGridConf.columWidth.value;

const HygienistSchedules = (props: HygienistSchedulesProps) => {
  const { fontType } = useContext(SignInContext);
  function giveEmptyBox() {
    return [...Array(numberOfRows + 2)].map((_, index) => (
      <div
        key={index}
        className={classes.border_show}
        style={{ height: `${rowheight}px` }}
      ></div>
    ));
  }

  return (
    <div className={classes.outercontainer}>
      <div
        style={{
          height: `${
            headerHeight +
            giveHeight(
              SchedulerGridConf.time.startTime,
              SchedulerGridConf.time.endTime
            )
          }px`,
        }}
      ></div>
      {props.data.map((singlehygienist, index) => {
        let alignBox = false;
        return (
          <div
            key={singlehygienist._id}
            style={{
              width: `${columWidth}px`,
              height: `${rowheight * numberOfRows + headerHeight}px`,
            }}
          >
            <div
              className={classes.header}
              style={{ height: `${headerHeight}px` }}
            >
              <StyledH5 font={fontType} className={classes.header_name}>{singlehygienist.name}</StyledH5>
              {props.view === 'Primary' && (
                <StyledH5 font={fontType} className={classes.header_subname}>
                  {singlehygienist.providerType === 'dentist'
                    ? 'Dentist'
                    : 'Hygienist'}
                </StyledH5>
              )}
            </div>
            {giveEmptyBox()}

            <div>
              {singlehygienist.availableTimeSlots &&
                singlehygienist.availableTimeSlots?.map((item, index) => {
                  if (item.slotType === 'booked') {
                    alignBox = !alignBox;
                  }
                  return (
                    <div key={index + 2}>
                      <Cell
                        type={item.slotType}
                        timing={{
                          startTime: item.startTime,
                          endTime: item.endTime,
                        }}
                        index={index}
                        view={props.view}
                        apptCardView={props.apptCardView}
                        align={alignBox}
                        providerName={singlehygienist.name}
                        providerType={
                          singlehygienist.providerType === 'hygienist'
                            ? 'hygienist'
                            : 'dentist'
                        }
                        reRenderAppointments={props.reRenderAppointments}
                        setCurrentDateOfSchedulerGrid={
                          props.setCurrentDateOfSchedulerGrid
                        }
                        currentDate={props.currentDate}
                        provider_id={singlehygienist._id}
                        isCancelled={props.isCancelled}
                      />
                    </div>
                  );
                })}

              {singlehygienist.appointmentDetails &&
                singlehygienist.appointmentDetails?.map((item, index) =>
                  item.appointments.map((appt, i) => (
                    
                    <div key={index + i}>
                      {
                        <Cell
                          type={'booked'}
                          timing={{
                            startTime: appt.apptDate,
                            endTime: appt.apptEndDate,
                          }}
                          index={index + i}
                          view={props.view}
                          apptCardView={
                            appt.isDeleted === true ? 'Danger' : 'Primary'
                          }
                          align={(alignBox = !alignBox)}
                          name={appt.Patient.name}
                          subName={item.name}
                          providerName={singlehygienist.name}
                          providerType={
                            singlehygienist.providerType === 'hygienist'
                              ? 'hygienist'
                              : 'dentist'
                          }
                          patientId={appt.Patient.id}
                          patientPhone={
                            appt.Patient.profile.contactInfo.phoneArr[0].phone
                          }
                          apptId={appt.id}
                          apptDate={appt.apptDate}
                          apptEndDate={appt.apptEndDate}
                          reRenderAppointments={props.reRenderAppointments}
                          setCurrentDateOfSchedulerGrid={
                            props.setCurrentDateOfSchedulerGrid
                          }
                          currentDate={props.currentDate}
                          provider_id={singlehygienist._id}
                          isCancelled={props.isCancelled}
                        />
                      }
                    </div>
                  ))
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HygienistSchedules;
