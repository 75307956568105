import React, { useContext } from 'react';
import styled from 'styled-components';
import { SidebarItemType } from '../models/SidebarItemTypes';
import { Link, useLocation } from 'react-router-dom';
import classes from '../Sidebar.module.css';
import classNames from 'classnames';
import SidebarContext from '../../../contexts/sideBar';
import { SignInContext } from '../../../contexts/signin';
import { StyledSpan } from '../../Styled/index'

interface SidebarItemProps {
  item: SidebarItemType;
}
// for side bar text when opened
const SidebarLink = styled.div<{ toggleSideBar: boolean }>`
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 12.5px;
  color: #52575c;
  transition: width 0.3s;
  display: ${({ toggleSideBar }) => (toggleSideBar ? 'flex' : 'none')};
`;
// for side bar container(icon + text)
const SidebarLinkContainer = styled(Link)`
  min-height: 48px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 5px;
  overflow: hidden;
`;
function SidebarItem({ item }: SidebarItemProps) {
  const { opened } = useContext(SidebarContext);
  const location = useLocation();
  const { fontType } = useContext(SignInContext);

  return (
    <>
      <SidebarLinkContainer
        key={item.id}
        id={item.id}
        to={item.path[0]}
        className={classNames(
          'd-flex',
          classes.sidebarItem,
          { [classes.activeRoute]: item.path.indexOf(location.pathname) !== -1 }
        )}
      >
        <div className={classes.icon}>{item.icon}</div>
        <SidebarLink toggleSideBar={opened}>
          <StyledSpan font={fontType} className={classNames(classes.SidebarLabel, classes.overflowInformation)}>{item.title}</StyledSpan>
        </SidebarLink>
      </SidebarLinkContainer>
    </>
  );
}

export default SidebarItem;
