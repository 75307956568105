import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as DropDownArrow } from '../../images/dropDownArrow.svg';
import './index.css';
import { SignInContext } from '../../contexts/signin';
import { StyledLi, StyledP, StyledInput, StyledSpan } from '../Styled/index'

type DropDownTypes = {
  data: string[];
  isMulti: boolean;
  isAutoSearch: boolean;
  isRequired: boolean;
  placeholder: string;
  label: string;
  defaultValues: string[];
  defaultValue: string;
  isDisable: boolean;
  onInputChange: (item: string) => void;
  onInputChanges: (item: string[]) => void;
};

const defaultProps = {
  data: [],
  isMulti: false,
  isAutoSearch: false,
  isRequired: false,
  placeholder: 'search...',
  label: '',
  defaultValues: [],
  defaultValue: '',
  isDisable: false,
  onInputChange: () => {},
  onInputChanges: () => {},
};

function DropDown(props: DropDownTypes) {
  const {
    data,
    label,
    isMulti,
    isRequired,
    placeholder,
    isAutoSearch,
    defaultValue,
    defaultValues,
    isDisable,
    onInputChange,
    onInputChanges,
  } = props;
  const { fontType } = useContext(SignInContext);
  const selectedValues = new Set();
  const [items, setItems] = useState<string[]>([]);
  useEffect(() => {
    setItems(data);
  }, [data]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [multiValues, setMultiValues] = useState<string[]>(
    isMulti ? defaultValues : []
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [singleValue, setSingleValue] = useState<string>(
    !isMulti ? defaultValue : ''
  );
  let suggestionsLength = useRef<number>(0);

  useEffect(() => {}, [defaultValue, defaultValues]);
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    const value = e.target.value;
    let suggestion: string[] = [];
    if (value.length > 0 && isAutoSearch) {
      const regex = new RegExp(`^${value}`, 'i');
      suggestion = items.sort().filter((v) => regex.test(v));
    }
    setSuggestions(suggestion);
  };

  const handleSuggestionChange = (item: string) => {
    if (isMulti) {
      multiValues.forEach((element) => {
        selectedValues.add(element);
      });
      selectedValues.add(item);

      setItems(
        items.filter(
          (itemListValue, index) => index !== itemListValue.indexOf(item)
        )
      );

      let array: string[] = Array.from(selectedValues) as string[];
      setMultiValues(array);
      onInputChanges(array);
    } else {
      setSingleValue(item);
      onInputChange(item);
    }
  };
  const removeData = (item: string) => {
    if (isMulti) {
      multiValues.forEach((element) => {
        selectedValues.add(element);
      });
      selectedValues.delete(item);
      setItems([...items, item]);
      let array: string[] = Array.from(selectedValues) as string[];
      setMultiValues(array);
      onInputChanges(array);
    } else {
      setSingleValue('');
      onInputChange('');
    }
  };
  const togglePopup = () => {
    if (suggestions.length) {
      setSuggestions([]);
      suggestionsLength.current = 0;
    } else {
      setSuggestions(items);
      suggestionsLength.current = items.length;
    }
  };
  useEffect(() => {
    window.addEventListener('click', function (e) {
      const element = document.getElementsByClassName('suggested_item')[1];
      const event = e.target as Element;
      if (
        element &&
        suggestionsLength.current > 0 &&
        !element.contains(event)
      ) {
        if (suggestionsLength.current === 1.1) {
          setSuggestions([]);
        }
        suggestionsLength.current = 1.1;
      }
    });

    return () =>
      window.removeEventListener('click', function (e) {
        const element = document.getElementsByClassName('suggested_item')[1];
        const event = e.target as Element;
        if (
          element &&
          suggestionsLength.current > 0 &&
          !element.contains(event)
        ) {
          if (suggestionsLength.current === 1.1) {
            setSuggestions([]);
          }
          suggestionsLength.current = 1.1;
        }
      });
  }, []);

  return (
    <>
      <div>
        <div
          className="dropdown_box"
          style={
            isDisable
              ? {
                  background: 'rgb(236, 236, 236)',
                  pointerEvents: 'none',
                  borderRadius: '8px',
                }
              : { background: '' }
          }
        >
          <StyledP font={fontType} className="legend">
            {label}
            <span className="red">{isRequired ? ' *' : ''}</span>
          </StyledP>
          <div className="border_style">
            {multiValues.length
              ? multiValues.map((item) => (
                  <span key={item} className="m_1 border_radius bg_gray">
                    <StyledSpan font={fontType} className="p-1 text">{item}</StyledSpan>{' '}
                    <i
                      className="p-1 x border_radius"
                      onClick={(e) => removeData(item)}
                    >
                      {' '}
                      <DropDownArrow />
                    </i>
                  </span>
                ))
              : null}
            {singleValue.length ? (
              <div className="m_1 border_radius bg_gray">
                <StyledSpan font={fontType} className="p-1 text">{singleValue}</StyledSpan>{' '}
                <div
                  className="p-1 x border_radius"
                  onClick={(e) => removeData(singleValue)}
                >
                  {' '}
                  <DropDownArrow data-testid={'cross_icon'} />
                </div>
              </div>
            ) : null}
            <span className=" p_1 width_100" onClick={togglePopup}>
              {isAutoSearch && !singleValue && (
                <StyledInput 
                  font={fontType}
                  className="inputfield_inside_dropdown"
                  type="text"
                  onChange={(e) => onTextChange(e)}
                  placeholder={singleValue ? '' : placeholder}
                  disabled={isDisable}
                  value={inputValue}
                />
              )}
              <span className="p_t_2" data-testid={'dropdown_arrow'}></span>
            </span>
          </div>
        </div>
        <div className="suggestion_width">
          <div tabIndex={0}>
            <ul className="list_style">
              {suggestions.length
                ? suggestions.map((item) => (
                    <StyledLi 
                      font={fontType}
                      key={item}
                      className="suggested_item"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSuggestionChange(item);
                        setSuggestions([]);
                        setInputValue('');
                      }}
                    >
                      {item}
                    </StyledLi>
                  ))
                : null}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
DropDown.defaultProps = defaultProps;
export default DropDown;
