interface Legend {
  text: string;
  color: string;
  shape: string;
}

export const legendDataforBooked: Legend[] = [
  {
    text: 'Booked',
    color: '#4f8bc3',
    shape: 'circle',
  },
  {
    text: 'Available',
    color: '#b6f7d3',
    shape: 'circle',
  },
  {
    text: 'Unavailable',
    color: '#ddf9e9',
    shape: 'circle',
  },
];

export const legendDataforCancelled: Legend[] = [
  {
    text: 'Booked',
    color: '#4f8bc3',
    shape: 'circle',
  },
  {
    text: 'Available',
    color: '#b6f7d3',
    shape: 'circle',
  },
  {
    text: 'Cancelled',
    color: '#ff4d4d',
    shape: 'circle',
  },
  {
    text: 'Unavailable',
    color: '#ddf9e9',
    shape: 'circle',
  },
];

// export default legendTestData;
