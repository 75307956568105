import styled from 'styled-components';

export const StyledSpan = styled.span<{ font: string }>`
    font-family: ${props => (props.font ? props.font : '')}; 
`;

export const StyledDiv = styled.div<{font: string}>`
    font-family: ${props => (props.font ? props.font : '')};
`;

export const StyledP = styled.p<{font: string}>`
    font-family: ${props => (props.font ? props.font : '')};
`;

export const StyledButton = styled.button<{font: string}>`
    font-family: ${props => (props.font ? props.font : '')}; 
`;

export const StyledTd = styled.td<{font: string}>`
    font-family: ${props => (props.font ? props.font : '')}; 
`;

export const StyledH5 = styled.h5<{font: string}>`
    font-family: ${props => (props.font ? props.font : '')}; 
`;

export const StyledInput = styled.input<{font: string}>`
    font-family: ${props => (props.font ? props.font : '')}; 
`;

export const StyledTextarea = styled.textarea<{ font: string }>`
    font-family: ${props => (props.font ? props.font : '')}; 
`;

export const StyledLi = styled.li<{ font: string }>`
    font-family: ${props => (props.font ? props.font : '')}; 
`;
