import { Fragment, useCallback, useContext, useMemo } from 'react';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import './table.css';
import { ReactComponent as Arrow } from '../../images/arrow.svg';
import { TableTypes } from './tableTypes';
import TableBox, { TableBoxProps } from '../TableBox';
import { SignInContext } from '../../contexts/signin';
import { StyledTd } from '../Styled/index';

interface TableRowProps {
  rowIndex: number;
  isRowExpanded: boolean;
  row: TableTypes.RowsSetting;
  columns: TableTypes.ColumnSetting[];
  aggregations?: TableTypes.Aggregation;
  handleRowClick: (rowId: string) => void;
}

const TableRow: React.FC<TableRowProps> = ({
  row,
  columns,
  rowIndex,
  aggregations,
  isRowExpanded,
  handleRowClick,
}: TableRowProps) => {
  const { fontType } = useContext(SignInContext);

  const { colSpans, differentCols, nonExpandableCols, expandableRowHeaders } =
    useMemo(() => {
      const nonExpandableCols =
        columns.filter((header) => !header.expandable).length + 1;

      const expandableHeaders: TableTypes.ColumnSetting[] = columns.filter(
        (header) =>
          header.expandable && !header.differentRow && row[header.value]
      );

      const expandableRowHeaders: TableTypes.ColumnSetting[][] = [];
      const colSpans: number[] = [];

      for (
        let i = 0;
        i < expandableHeaders.length;
        i = i + nonExpandableCols - 1
      ) {
        const slice = expandableHeaders.slice(i, i + nonExpandableCols - 1);
        expandableRowHeaders.push(slice);
        colSpans.push(
          slice.length === nonExpandableCols - 1
            ? 1
            : (nonExpandableCols - 1) / slice.length
        );
      }

      const differentCols = columns.filter(
        (column) => column.expandable && column.differentRow
      );
      return {
        colSpans,
        differentCols,
        nonExpandableCols,
        expandableRowHeaders,
      };
    }, [row, columns]);

  const renderCell = useCallback(
    (header: TableTypes.ColumnSetting) => {
      if (header.formatter) return header.formatter(row[header.value]);
      else if (
        row[header.value]?.render &&
        typeof row[header.value]?.render === 'function'
      ) {
        return row[header.value]?.render();
      } else {
        return _get(row, header.value, 'NA');
      }
    },
    [row]
  );

  const renderAggregationView = () => {
    const boxes: TableBoxProps[] = [];
    Object.keys(aggregations || {}).forEach((aggregationKey) => {
      const fields = columns
        .filter((column) => column.aggregationKey === aggregationKey)
        .map((col) => ({
          value: renderCell(col),
          colSpan: col.colSpan,
          displayName: col.displayName,
          rowNumber: col.rowNumber,
        }));
      boxes.push({
        fields,
        title: aggregations?.[aggregationKey].title || '',
        colSpan: aggregations?.[aggregationKey].colSpan || 8,
        align: aggregations?.[aggregationKey].align || 'horizontal',
        rowNumber: aggregations?.[aggregationKey].rowNumber || 3,
      });
    });
    const rows = _groupBy(boxes, 'rowNumber');
    return Object.keys(rows).map((colSpan) => (
      <tr key={colSpan} className="expandedBackgound">
        {rows[colSpan].map((row) => (
          <td key={row.title} colSpan={row.colSpan}>
            <TableBox {...row} />
          </td>
        ))}
      </tr>
    ));
  };

  const renderExpandedView = () => (
    <>
      {expandableRowHeaders.map((expandableHeaders, idx2) => (
        <Fragment key={idx2}>
          <tr
            className={`row-expanded ${
              differentCols.length ? 'with-extra-row' : ''
            } ${rowIndex % 2 === 0 ? ' even' : ''}`}
          >
            <td colSpan={1} />
            {expandableHeaders
              .filter((header) => row[header.value])
              .map((header) => (
                <td colSpan={colSpans[idx2]} key={header.value}>
                  <div className="d-flex flex-column">
                    <span className="header-expanded">
                      {header.displayName}
                    </span>
                    <span className="table-data-expanded">
                      {renderCell(header)}
                    </span>
                  </div>
                </td>
              ))}
          </tr>
        </Fragment>
      ))}
      {!!differentCols.length &&
        differentCols.map((differentCol, index) => (
          <tr
            key={differentCol.displayName + index}
            className={`row-expanded extra-row ${
              rowIndex % 2 === 0 ? ' even' : ''
            }`}
          >
            <td colSpan={1} />
            <td colSpan={nonExpandableCols}>
              <span className="header-expanded">
                {differentCol.displayName}
              </span>
              <span className="table-data-expanded">
                {renderCell(differentCol)}
              </span>
            </td>
          </tr>
        ))}
    </>
  );

  return (
    <>
      <tr
        className={`table-row ${isRowExpanded ? ' row-expadable' : ''}`}
        onClick={() => handleRowClick(row.id)}
      >
        <>
          <td colSpan={1}>
            <div className="arrowImage">
              <Arrow />
            </div>
          </td>
          {columns
            .filter((header) => !header.expandable)
            .map((header) => (
              <StyledTd
                font= {fontType}
                colSpan={header.colSpan || 1}
                key={header.value}
                className={header.textCenter ? 'text-center' : ''}
              >
                {renderCell(header)}
              </StyledTd>
            ))}
        </>
      </tr>
      {isRowExpanded &&
        (aggregations ? renderAggregationView() : renderExpandedView())}
    </>
  );
};

export default TableRow;
