/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      apptDate
      apptEndDate
      apptDetails
      apptNotes
      apptProcedure
      doctorId
      preferredHygenistId
      locationId
      noShowProb
      contacted
      contactNotes {
        noteDetails
      }
      lastContacted
      isConfirmed
      isDeleted
      availForShortList
      procedureId
      recallDate
      parentName
      patientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query MyQuery {
    listAppointments(limit: 100) {
      items {
        id
        apptDate
        appointmentPatientId
        appointmentProcedureId
        appointmentProviderId
        apptId
        Patient {
          name
          email
          patientId
          lomaScore
          profile {
            dob
            contactInfo {
              phone
              preferredContactMethod
              phoneArr {
                phone
                phoneType
              }
            }
            paymentInformation {
              balance
              modeOfPayment
              insuranceInfo {
                partyName
              }
            }
            preferredInfo {
              preferredDayOfTime
              preferredDayOfWeek
              preferredProviders
            }
          }
        }
        Procedure {
          procedureCode
          procedureName
        }
        Provider {
          name
          providerId
          providerType
          id
        }
      }
    }
  }
`;
export const syncAppointments = /* GraphQL */ `
  query SyncAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        apptDate
        apptEndDate
        apptDetails
        apptNotes
        apptProcedure
        doctorId
        preferredHygenistId
        locationId
        noShowProb
        contacted
        lastContacted
        isConfirmed
        isDeleted
        availForShortList
        procedureId
        recallDate
        parentName
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      name
      lomaScore
      email
      isActive
      metaData {
        createdAt
        createdBy
        updatedAt
        updatedBy
        trno
      }
      profile {
        gender
        imageUrl
        dob
        permissionGroups
      }
      Appointments {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query MyQuery {
    listPatients(limit: 100) {
      items {
        email
        id
        lomaScore
        name
        patientId
        profile {
          gender
          dob
          contactInfo {
            phoneArr {
              phone
              phoneType
            }
          }
        }
      }
    }
  }
`;
export const syncPatients = /* GraphQL */ `
  query SyncPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        lomaScore
        email
        isActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const appointmentList = /* GraphQL */ `
  query MyQuery($filter: ModelAppointmentFilterInput, $limit: Int) {
    listAppointments(filter: $filter, limit: $limit) {
      items {
        id
        apptDate
        apptEndDate
        appointmentPatientId
        appointmentProcedureId
        appointmentProviderId
        apptId
        parentName
        Patient {
          name
          email
          patientId
          lomaScore
          profile {
            dob
            contactInfo {
              phone
              preferredContactMethod
              phoneArr {
                phone
                phoneType
              }
            }
            paymentInformation {
              balance
              modeOfPayment
              insuranceInfo {
                partyName
              }
            }
            preferredInfo {
              preferredDayOfTime
              preferredDayOfWeek
              preferredProviders
            }
          }
        }
        Procedure {
          procedureCode
          procedureName
        }
        Provider {
          name
          providerId
          providerType
          id
        }
        apptNotes
        Hygienist {
          items {
            id
          }
        }
      }
    }
  }
`;

export const callList = /* GraphQL */ `
  query callList($filter: ModelAppointmentFilterInput, $limit: Int) {
    listAppointments(filter: $filter, limit: $limit) {
      items {
        id
        _version
        apptDate
        apptEndDate
        appointmentPatientId
        appointmentProcedureId
        appointmentProviderId
        apptId
        parentName
        lastContacted
        Patient {
          name
          email
          patientId
          lomaScore
          profile {
            dob
            contactInfo {
              phone
              preferredContactMethod
              phoneArr {
                phone
                phoneType
              }
            }
            paymentInformation {
              balance
              modeOfPayment
              insuranceInfo {
                partyName
              }
            }
            preferredInfo {
              preferredDayOfTime
              preferredDayOfWeek
              preferredProviders
            }
          }
        }
        Procedure {
          procedureCode
          procedureName
        }
        Provider {
          name
          providerId
          providerType
          id
        }
        Procedure {
          procedureCode
          procedureName
        }
        Provider {
          name
          providerId
          providerType
          id
        }
        ContactNotes {
          items {
            contactNotes {
              noteDetails
              email
              id
              username
              createdAt
              appointments {
                items {
                  contactNotes {
                    username
                    updatedAt
                    noteDetails
                    id
                    email
                    createdAt
                  }
                }
              }
            }
            createdAt
            updatedAt
            id
          }
        }
        isConfirmed
        contacted
        apptNotes
      }
    }
  }
`;

export const shortCallList = /* GraphQL */ `
  query shortCallList($filter: ModelAppointmentFilterInput, $limit: Int) {
    listAppointments(filter: $filter, limit: $limit) {
      items {
        id
        _version
        apptDate
        apptEndDate
        appointmentPatientId
        appointmentProcedureId
        appointmentProviderId
        apptId
        parentName
        lastContacted
        Patient {
          name
          email
          patientId
          lomaScore
          profile {
            dob
            contactInfo {
              phone
              preferredContactMethod
              phoneArr {
                phone
                phoneType
              }
            }
            paymentInformation {
              balance
              modeOfPayment
              insuranceInfo {
                partyName
              }
            }
            preferredInfo {
              preferredDayOfTime
              preferredDayOfWeek
              preferredProviders
            }
          }
        }
        Procedure {
          procedureCode
          procedureName
        }
        Provider {
          name
          providerId
          providerType
          id
        }
        Procedure {
          procedureCode
          procedureName
        }
        Provider {
          name
          providerId
          providerType
          id
        }
        ContactNotes {
          items {
            contactNotes {
              createdAt
              email
              id
              noteDetails
              updatedAt
              username
            }
          }
        }
      }
    }
  }
`;

export const recreationList = /* GraphQL */ `
  query recreationList($filter: ModelReactivationFilterInput) {
    listReactivations(filter: $filter) {
      items {
        Patient {
          patientId
          name
          lomaScore
          email
          profile {
            contactInfo {
              phone
              preferredContactMethod
              phoneArr {
                phone
                phoneType
              }
              countryCode
            }
            dob
            paymentInformation {
              balance
              modeOfPayment
              insuranceInfo {
                partyName
              }
            }
          }
        }
        Hygienist {
          name
        }
        Provider {
          name
        }
        contacted
        lastContacted
        parent
        lastHygieneDos
        Procedure {
          procedureName
          procedureCode
        }
        apptNotes
        ContactNotes {
          items {
            contactNotes {
              createdAt
              email
              id
              noteDetails
              username
              updatedAt
            }
            contactNotesID
            createdAt
          }
        }
        id
        _version
        lastSeen
      }
    }
  }
`;

export const unscheduledTreatment = /* GraphQL */ `
  query unscheduledTreatment($filter: ModelUnscheduledFilterInput) {
    listUnscheduleds(filter: $filter) {
      items {
        Patient {
          lomaScore
          name
          patientId
          profile {
            dob
            paymentInformation {
              modeOfPayment
              balance
              insuranceInfo {
                partyName
              }
            }
            contactInfo {
              phone
              phoneArr {
                phone
                phoneType
              }
              preferredContactMethod
            }
          }
          email
        }
        dateOfPrescription
        prescribedTrt
        contacted
        id
        _version
        Provider {
          name
        }
        apptNotes
        ContactNotes {
          items {
            contactNotesID
            createdAt
            contactNotes {
              email
              createdAt
              id
              noteDetails
              updatedAt
              username
            }
          }
        }
        lastContacted
      }
    }
  }
`;

export const schedulerGridProviderData = /* GraphQL */ `
  query schedulerGridProviderData($filter: ModelProviderFilterInput) {
    listProviders(filter: $filter) {
      items {
        id
        availableTimeSlots {
          endTime
          startTime
        }
        providerType
        name
        providerId
        availableDays
      }
    }
  }
`;

export const schedulerGridAppointmentData = /* GraphQL */ `
  query schedulerGridAppointmentData($filter: ModelAppointmentFilterInput) {
    listAppointments(filter: $filter) {
      items {
        apptDate
        apptId
        Patient {
          id
          name
          patientId
          lomaScore
          profile {
            contactInfo {
              phoneArr {
                phone
                phoneType
              }
            }
          }
        }
        Provider {
          id
        }
        Location {
          identificationNumber
        }
        Hygienist {
          items {
            provider {
              providerType
              providerId
            }
          }
        }
        isDeleted
        apptEndDate
        apptNotes
        appointmentPatientId
        id
      }
    }
  }
`;
// testing
export const schedulerGridData = /* GraphQL */ `
  query schedulerGridData(
    $filter1: ModelAppointmentFilterInput
    $filter2: ModelProviderFilterInput
  ) {
    listProviders(filter: $filter2) {
      items {
        id
        availableTimeSlots {
          endTime
          startTime
        }
        providerType
        name
        providerId
        availableDays
      }
    }
    listAppointments(filter: $filter1) {
      items {
        apptDate
        apptId
        Patient {
          id
          name
          patientId
          lomaScore
          profile {
            contactInfo {
              phoneArr {
                phone
                phoneType
              }
            }
          }
        }
        Provider {
          id
        }
        Hygienist {
          items {
            id
          }
        }
        Location {
          identificationNumber
        }
        isDeleted
        apptEndDate
        apptNotes
        appointmentPatientId
        id
      }
    }
  }
`;

export const patientList = /* GraphQL */ `
  query patientList {
    listPatients {
      items {
        id
        lomaScore
        patientId
        name
      }
    }
  }
`;

export const locationList = /* GraphQL */ `
  query locationList($filter: ModelLocationFilterInput) {
    listLocations(filter: $filter) {
      items {
        id
        identificationNumber
        locationId
        name
      }
    }
  }
`;

export const listUserActions =
  /* GraphQL */
  `
    query MyQuery {
      listUserActions {
        items {
          createdAt
          event
          usename
        }
      }
    }
  `;

export const appointmentDetails = /* GraphQL */ `
  query getAppointmentDetails($id: ID!) {
    getAppointment(id: $id) {
      id
      _version
      Patient {
        lomaScore
        name
        patientId
      }
      appointmentLocationId
      availForShortList
      apptDate
      apptEndDate
      Provider {
        name
        providerId
        providerType
        id
      }
      Location {
        identificationNumber
      }
      Hygienist {
        items {
          providerID
          provider {
            name
            providerType
            providerId
          }
        }
      }
    }
  }
`;
export const userActionList = /* GraphQL */ `
  query userAction($filter: ModelUserActionsFilterInput, $limit: Int) {
    listUserActions(filter: $filter, limit: $limit) {
      items {
        username
        email
        event
        listType
        createdAt
      }
    }
  }
`;

export const appointmentDetailsForDeletion = /* GraphQL */ `
  query getAppointmentDetailsForDeletion($id: ID!) {
    getAppointment(id: $id) {
      id
      _version
    }
  }
`;
export const getUserRolesAndPermissionsByID = /* GraphQL */ `
  query listUserRolesAndPermissionsByID(
    $filter: ModelUserRolesAndPermissionsFilterInput
  ) {
    listUserRolesAndPermissions(filter: $filter) {
      items {
        userId
        userRolesAndPermissionsOrganizationId
        UserRoles {
          UserPermissions {
            modularPermission
            name
          }
        }
        DentalPractice {
          id
        }
      }
    }
  }
`;

export const fetchDentalPracticeList =
  `
query listUserRolesAndPermissionsByID($filter: ModelUserRolesAndPermissionsFilterInput) {
  listUserRolesAndPermissions(filter:$filter) {
    items {
      DentalPractice {
        name
        id
      }
    }
  }
}
`
