import dayjs from 'dayjs';
import { TableTypes } from '../../components/Table/tableTypes';
import { InputFieldType } from '../../components/Input';

export namespace ReactivationListConf {
  export type CallistParams = {
    type: 'reactivation';
  };

  export const reactivationTitles = {
    BASIC_DETAILS: 'Basic Details',
    LAST_APPOINTMENT_DETAILS: 'Last Appointment Details',
    CONTACT_DETAILS: 'Contact Details',
    CONTACT_NOTES: 'Contact Notes',
    APPT_NOTES: 'Last Appointment Notes',
  };

  export const reactivationAggregation: TableTypes.Aggregation = {
    [reactivationTitles.BASIC_DETAILS]: {
      title: reactivationTitles.BASIC_DETAILS,
      align: 'vertical',
      colSpan: 3,
      rowNumber: 1,
    },
    [reactivationTitles.CONTACT_DETAILS]: {
      title: reactivationTitles.CONTACT_DETAILS,
      align: 'vertical',
      colSpan: 2,
      rowNumber: 1,
    },
    [reactivationTitles.LAST_APPOINTMENT_DETAILS]: {
      title: reactivationTitles.LAST_APPOINTMENT_DETAILS,
      align: 'vertical',
      colSpan: 3,
      rowNumber: 1,
    },
    [reactivationTitles.CONTACT_NOTES]: {
      title: reactivationTitles.CONTACT_NOTES,
      align: 'vertical',
      colSpan: 4,
      rowNumber: 2,
    },
    [reactivationTitles.APPT_NOTES]: {
      title: reactivationTitles.APPT_NOTES,
      align: 'vertical',
      colSpan: 4,
      rowNumber: 2,
    },
  };

  export const reactivationListFields: TableTypes.TableHeader[] = [
    {
      value: 'patientIdNode',
      displayName: 'Patient Id',
    },
    {
      value: 'Patient.name',
      displayName: 'Patient Name',
      searchable: true,
      filterType: InputFieldType.TEXT,
    },
    {
      value: 'Provider.name',
      displayName: 'Provider',
    },
    {
      value: 'Hygienist.name',
      displayName: 'Hygienist',
    },
    { value: 'Patient.lomaScore', displayName: 'LoMA Score', textCenter: true },
    {
      value: 'parent',
      displayName: 'Responsible Party',
      expandable: true,
      aggregationKey: reactivationTitles.BASIC_DETAILS,
    },
    {
      value: 'dob',
      displayName: 'DOB',
      expandable: true,
      aggregationKey: reactivationTitles.BASIC_DETAILS,
      // formatter: (date: string) => dayjs(date).format('MM/DD/YYYY'),
    },
    {
      value: 'age',
      displayName: 'Age',
      expandable: true,
      aggregationKey: reactivationTitles.BASIC_DETAILS,
    },
    {
      value: 'lastHygieneDos',
      displayName: 'Hygiene DOS',
      expandable: true,
      formatter: (date: string) => dayjs(date).format('MM/DD/YYYY'),
      aggregationKey: reactivationTitles.LAST_APPOINTMENT_DETAILS,
    },
    {
      value: 'Provider.name',
      displayName: 'Hygiene Provider',
      expandable: true,
      aggregationKey: reactivationTitles.LAST_APPOINTMENT_DETAILS,
    },
    {
      value: 'contacted',
      displayName: 'Contacted Today',
      sortable: false,
      textCenter: true,
    },
    {
      value: 'multiplePhoneNode',
      displayName: 'Contact Number',
      expandable: true,
      aggregationKey: reactivationTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.email',
      displayName: 'Email',
      expandable: true,
      aggregationKey: reactivationTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.profile.contactInfo.preferredContactMethod',
      displayName: 'Method',
      expandable: true,
      aggregationKey: reactivationTitles.CONTACT_DETAILS,
    },
    {
      value: 'Procedure.procedureName',
      displayName: 'Hygiene Procedure',
      expandable: true,
      aggregationKey: reactivationTitles.LAST_APPOINTMENT_DETAILS,
    },
    {
      value: 'contactNotesNode',
      displayName: 'Contact Notes',
      expandable: true,
      differentRow: true,
      aggregationKey: reactivationTitles.CONTACT_NOTES,
    },
    {
      value: 'lastSeen',
      displayName: 'Last Visited On',
      expandable: false,
      // formatter: (date: string) => dayjs(date).format('MM/DD/YYYY'),
    },
  ];

  export const getCallListFields = (): TableTypes.TableHeader[] => {
    return reactivationListFields;
  };

  export const getAggregationField = (): any => {
    return reactivationAggregation;
  };
}
