import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignInContext } from '../contexts/signin';
import { SESSION, USER_EVENTS } from '../utils/constants';

const useAutoLogout = (timerInSec: number) => {
  const navigateTo = useNavigate();
  const currentTime = useRef(new Date());
  const { broadcastChannel } = useContext(SignInContext);

  useEffect(() => {
    const resetTimeout = () => {
      if (
        (new Date().getTime() - currentTime.current.getTime()) / 1000 >
        timerInSec
      ) {
        localStorage.clear();
        broadcastChannel.postMessage(SESSION.LOGOUT);
        navigateTo('/login');
      } else {
        currentTime.current = new Date();
        broadcastChannel.postMessage(SESSION.ACTIVE);
      }
    };

    broadcastChannel.onmessage = (msg: any) => {
      if (msg.data === SESSION.ACTIVE) {
        currentTime.current = new Date();
      } else if (msg.data === SESSION.LOGOUT) {
        navigateTo('/login');
      }
    };

    for (let i in USER_EVENTS) {
      window.addEventListener(USER_EVENTS[i], resetTimeout);
    }

    return () => {
      for (let i in USER_EVENTS) {
        window.removeEventListener(USER_EVENTS[i], resetTimeout);
      }
    };
  });
};

export default useAutoLogout;
