import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import classes from './index.module.css';
import { SignInContext } from '../../contexts/signin';
import {StyledButton} from '../Styled/index';

export enum ButtonType {
  FILLED = 'filled',
  OUTLINE = 'outline',
  SUCCESS = 'success',
  DANGER = 'danger',
}

export enum Type {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
}

interface ButtonProps {
  onClick?: () => void;
  text?: string;
  className?: string;
  buttonType?: ButtonType;
  type?: Type;
  disabled?: boolean;
  loading?: boolean;
  children?: JSX.Element | string;
  id?: string;
}

const defaultButtonProps: ButtonProps = {
  onClick: () => {},
  className: '',
  text: 'button',
  buttonType: ButtonType.FILLED,
  type: Type.SUBMIT,
  loading: false,
  disabled: false,
  id: ''
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    type,
    onClick,
    className,
    disabled,
    text,
    buttonType,
    children,
    loading,
    id
  } = props;
  const { fontType } = useContext(SignInContext);

  const btnClass = useMemo(() => {
    return classNames(classes.button, classes[buttonType as string]);
  }, [buttonType]);

  return (
    <StyledButton
      font={fontType}
      type={type}
      className={classNames(btnClass, className)}
      id={id}
      disabled={disabled || loading}
      onClick={onClick}
    >
      <div className={classNames({ [classes.hide]: loading })}>
        {children || text}
      </div>
      {loading && (
        <div className={classes.loader}>
          <div className={classes.loading} />
        </div>
      )}
    </StyledButton>
  );
};

Button.defaultProps = defaultButtonProps;

export default Button;
