import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Table from '../components/Table';
import Loader from '../components/Loader/Loader';
import { TableTypes } from '../components/Table/tableTypes';
import { SignInContext } from '../contexts/signin';
import Clipboard from '../components/Clipboard';
import { getLocaleCurrency } from '../utils/appointmentCardValues';
import Appointment from '../services/Appointment';
import classes from './Appointments.module.css';
import { getCookie } from '../utils';
export const appointmentListTitles = {
  BASIC_DETAILS: 'Basic Details',
  PREFERECES: 'Preferences',
  PAYMENT_DETAILS: 'Payment Details',
  CONTACT_DETAILS: 'Contact Details',
  APPT_DETAILS: 'Appointment Details',
  APPT_NOTES: 'Appointment Notes',
};

export const reportAggregation: TableTypes.Aggregation = {
  [appointmentListTitles.APPT_DETAILS]: {
    title: appointmentListTitles.APPT_DETAILS,
    align: 'vertical',
    colSpan: 4,
    rowNumber: 2,
  },
  [appointmentListTitles.BASIC_DETAILS]: {
    title: appointmentListTitles.BASIC_DETAILS,
    align: 'vertical',
    colSpan: 2,
    rowNumber: 1,
  },
  [appointmentListTitles.CONTACT_DETAILS]: {
    title: appointmentListTitles.CONTACT_DETAILS,
    align: 'vertical',
    colSpan: 2,
    rowNumber: 1,
  },
  [appointmentListTitles.PREFERECES]: {
    title: appointmentListTitles.PREFERECES,
    align: 'vertical',
    colSpan: 2,
    rowNumber: 1,
  },
  [appointmentListTitles.PAYMENT_DETAILS]: {
    title: appointmentListTitles.PAYMENT_DETAILS,
    align: 'vertical',
    colSpan: 2,
    rowNumber: 1,
  },
  [appointmentListTitles.APPT_NOTES]: {
    title: appointmentListTitles.APPT_NOTES,
    align: 'vertical',
    colSpan: 4,
    rowNumber: 2,
  },
};

const Appointments = () => {
  const params = useParams();
  const { authToken } = useContext(SignInContext);
  const [fetching, setFetching] = useState(false);
  const [appoinmentData, setAppoinmentData] = useState<TableTypes.TableBody[]>(
    []
  );
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  const getApptFields = useCallback(
    (id: string, appt: TableTypes.TableBody) => ({
      patientIdNode: {
        sortField: 'Patient.patientId',
        render: () => (
          <div>
            <Clipboard
              textValueToCopy={appt.Patient.patientId}
              timeInterval={2000}
            />
          </div>
        ),
      },
      ...(appt?.Patient?.profile?.dob
        ? {
            age: (
              <span>
                {dayjs(new Date()).year() -
                  dayjs(appt?.Patient?.profile?.dob).year()}
              </span>
            ),
          }
        : {}),

      parentName: <span>{appt.parentName ? appt.parentName : 'NA'}</span>,

      apptNotes: <span>{appt.apptNotes ? appt.apptNotes : 'NA'}</span>,

      age: (
        <span>
          {dayjs(new Date()).diff(dayjs(appt.Patient.profile.dob), 'year')}
        </span>
      ),

      dob: <span>{dayjs(appt.Patient.profile.dob).format('MM-DD-YYYY')}</span>,

      balance: (
        <span>
          {getLocaleCurrency(appt.Patient.profile.paymentInformation.balance)}
        </span>
      ),

      minutes: {
        sortField: 'apptEndDate',
        render: () => (
          <div>
            <span>
              {dayjs(appt.apptEndDate).diff(dayjs(appt.apptDate)) / 60000}
            </span>
          </div>
        ),
      },

      ...(appt.Patient
        ? {
            multiplePhoneNode: (
              <div>
                {appt.Patient.profile.contactInfo.phoneArr.map(
                  (item: any, i: number) => {
                    return (
                      <div key={i}>
                        <span>{item.phoneType} : </span>
                        <span>{item.phone} </span>
                      </div>
                    );
                  }
                )}
              </div>
            ),
          }
        : {}),
    }),
    []
  );

  useEffect(() => {
    const getAppointmentsData = () => {
      const todayDate = new Date();

      const tomorrowDate = new Date();

      tomorrowDate.setDate(tomorrowDate.getDate() + 1);

      appointmentService
        .fetchAppointments(
          params.apptSlot === 'today'
            ? todayDate
            : params.apptSlot === 'tomorrow'
            ? tomorrowDate
            : 'All',
            getCookie('selectedDentalPracticeId')
        )
        .then(({ data }: { data: any }) => {
          setAppoinmentData(
            data?.listAppointments?.items?.map(({ id, ...apptData }: any) => ({
              ...apptData,
              id: id,
              ...(apptData.patientDetails || {}),
              ...(apptData.preferredInfo || {}),
              ...getApptFields(id, {
                ...apptData,
                ...(apptData.Patient || {}),
                ...(apptData.Provider || {}),
                ...(apptData.Procedure || {}),
              }),
            }))
          );
        })
        .catch((err) => console.log('error', err))
        .finally(() => {
          setFetching(false);
        });
    };

    setFetching(true);
    getAppointmentsData();
  }, [appointmentService, params.apptSlot, authToken, getApptFields]);

  const headers: TableTypes.TableHeader[] = [
    {
      value: 'patientIdNode',
      displayName: 'Patient Id',
    },
    {
      value: 'Patient.name',
      displayName: 'Patient Name',
    },
    { value: 'Provider.name', displayName: 'Primary Doctor' },
    {
      value: 'apptDate',
      displayName: 'Appointment Date',
      textCenter: true,
      formatter: (date) => dayjs(date).format('L LT'),
    },
    { value: 'minutes', displayName: 'Duration', textCenter: true },
    { value: 'Patient.lomaScore', displayName: 'LoMA Score', textCenter: true },
    {
      value: 'apptScheduledWith',
      displayName: 'Appt Scheduled With',
      expandable: true,
    },
    { value: 'apptDetails', displayName: 'Appt Details', expandable: true },
    {
      value: 'preferredInfo.preferredProviderName',
      displayName: 'Provider',
      expandable: true,
      aggregationKey: appointmentListTitles.PREFERECES,
    },
    {
      value: 'preferredHygienistName',
      displayName: 'Hygienist',
      expandable: true,
      aggregationKey: appointmentListTitles.PREFERECES,
    },
    {
      value: 'parentName',
      displayName: 'Responsible Party',
      expandable: true,
      aggregationKey: appointmentListTitles.BASIC_DETAILS,
    },
    {
      value: 'Patient.profile.preferredInfo.preferredDayOfWeek',
      displayName: 'Day',
      expandable: true,
      aggregationKey: appointmentListTitles.PREFERECES,
    },
    {
      value: 'Patient.profile.preferredInfo.preferredDayOfTime',
      displayName: 'Time',
      expandable: true,
      aggregationKey: appointmentListTitles.PREFERECES,
    },
    {
      value: 'apptNotes',
      displayName: 'Notes',
      expandable: true,
      differentRow: true,
      aggregationKey: appointmentListTitles.APPT_NOTES,
    },
    {
      value: 'Procedure.procedureName',
      displayName: 'Procedure Name',
      expandable: true,
      aggregationKey: appointmentListTitles.APPT_DETAILS,
    },
    {
      value: 'Procedure.procedureCode',
      displayName: 'Procedure Code',
      expandable: true,
      aggregationKey: appointmentListTitles.APPT_DETAILS,
    },
    {
      value: 'dob',
      displayName: 'DOB',
      expandable: true,
      aggregationKey: appointmentListTitles.BASIC_DETAILS,
    },
    {
      value: 'age',
      displayName: 'Age',
      expandable: true,
      aggregationKey: appointmentListTitles.BASIC_DETAILS,
    },
    {
      value: 'multiplePhoneNode',
      displayName: 'Contact Number',
      expandable: true,
      aggregationKey: appointmentListTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.email',
      displayName: 'Email',
      expandable: true,
      aggregationKey: appointmentListTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.profile.contactInfo.preferredContactMethod',
      displayName: 'Method',
      expandable: true,
      aggregationKey: appointmentListTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.profile.paymentInformation.insuranceInfo.partyName',
      displayName: 'Insurance Party',
      expandable: true,
      aggregationKey: appointmentListTitles.PAYMENT_DETAILS,
    },
    {
      value: 'balance',
      displayName: 'Balance',
      expandable: true,
      aggregationKey: appointmentListTitles.PAYMENT_DETAILS,
    },
    {
      value: 'Patient.profile.paymentInformation.modeOfPayment',
      displayName: 'Mode',
      expandable: true,
      aggregationKey: appointmentListTitles.PAYMENT_DETAILS,
    },
  ];

  if (fetching) {
    return (
      <div className={classes.loaderStyle}>
        <Loader type="round" borderWidth={2} loaderSize={50} />
      </div>
    );
  }

  return (
    <div className={classes.tableContainerForAppointments}>
      <Table
        headers={headers}
        aggregations={reportAggregation}
        body={appoinmentData}
        isHeaderSticky={true}
      />
    </div>
  );
};

export default Appointments;
