import { InputFieldType } from '../Input';

export namespace TableTypes {
  export enum TableSort {
    NO_SORT = 'no_sort',
    ASC = 'asc',
    DESC = 'desc',
  }

  export enum CollapseState {
    COLLAPSED = 0,
    EXPANDED = 1,
  }

  export interface TableAction {
    text: string;
    component: JSX.Element | null;
    actionListener?: (data: any) => void;
  }

  export interface Aggregation {
    [key: string]: {
      title: string;
      colSpan: number;
      rowNumber?: number;
      align: 'vertical' | 'horizontal';
    };
  }

  export interface TableHeader {
    displayName: string;
    value: string;
    sortable?: boolean;
    searchable?: boolean;
    expandable?: boolean;
    differentRow?: boolean;
    filterType?: InputFieldType;
    textCenter?: boolean;
    isCopy?: boolean;
    aggregationKey?: string;
    colSpan?: number;
    rowNumber?: number;
    formatter?: (value: string) => number | string;
  }

  export interface TableBody {
    id: any;
    [propName: string]:
      | {
          sortValue: any;
          sortField: string;
          render: () => JSX.Element | JSX.Element[] | null | string | boolean;
        }
      | any;
  }

  export interface TableProps {
    body: TableBody[];
    pageSizes: number[];
    showPagination: boolean;
    headers: TableHeader[];
    isHeaderSticky: boolean;
    defaultPageSize: number;
    showAvatarFirst: boolean;
    customAvatar?: null | JSX.Element;
    tableActions: TableAction[];
    selectable?: boolean;
    sortedBy: string;
    sortOrder: TableSort;
    aggregations?: Aggregation;
  }

  export interface ColumnSetting extends TableHeader {
    searchValue: string;
    searchEnabled: boolean;
    currentSort: TableSort;
  }

  export interface RowsSetting extends TableBody {
    selected: boolean;
    currentCollapseState: CollapseState;
  }

  export interface ColumnsSetting {
    [value: string]: ColumnSetting;
  }

  export interface TableSettings {
    columns: ColumnsSetting;
    rows: RowsSetting[];
  }
}
