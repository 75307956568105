import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import Sidebar from '../components/SideBar/index';
import SidebarContext, { OpenedBy, sideBarConfig } from '../contexts/sideBar';
import { SignInContext, SignInContextType } from '../contexts/signin';
import { SidebarData } from '../components/SideBar/SidebarItem/SidebarData';
import useAutoLogout from '../hooks/useAutoLogout';
import Appointment from '../services/Appointment';
import classes from './index.module.css';
import DataNotFound from '../components/DataNotFound/DataNotFound';

const Main = styled.main<{ open: boolean; openedBy: OpenedBy }>`
  overflow: auto;
  background: #f4f8ff;
  transition: 400ms;
  height: calc(100% - 15px);
  margin-top: 15px;
  margin-right: 5px;
  @media (min-width: 500px){
    margin-left: ${({ open, openedBy }) =>
      open && openedBy === 'hamburger-click' ? '230px' : '80px'};
  }
  @media (max-width: 500px) {
    margin-left: 15px !important;
  }
  @media (max-width: 500px){
   margin-left: 5px !important;
  } 
`;

const Layouts = () => {
  const { isSignedIn, user, authToken, userLoading, theme } =
    useContext<SignInContextType>(SignInContext);

  const [sideBarState, setSideBarState] = useState(sideBarConfig);
  const logouttime: any = 1800;
  useAutoLogout(logouttime);
  const navigateTo = useNavigate();
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  const handleChange = (opened: boolean, openedBy: OpenedBy) => {
    if (
      sideBarState.opened &&
      sideBarState.openedBy === 'hamburger-click' &&
      openedBy === 'hover'
    )
      return;
    setSideBarState({
      ...sideBarState,
      opened,
      openedBy,
    });
  };
  const [permittedData, setPermittedData] = useState([]);
  useEffect(() => {
    user &&
      appointmentService
        .getUserRolesAndPermissionsByID(user.username)
        .then((data) => {
          let res: any = SidebarData.filter((el: any) => {
            return data.data.listUserRolesAndPermissions.items[0].UserRoles.UserPermissions.modularPermission.find(
              (element: any) => {
                return element === el.id;
              }
            );
          });
          setPermittedData(res);
        });
  }, [user, appointmentService]);

  useEffect(() => {
    if (!isSignedIn && !userLoading) {
      navigateTo('/login');
    }
  }, [isSignedIn, userLoading, navigateTo]);

  if (userLoading) {
    return <DataNotFound loader />;
  }

  return (
    <>
      {isSignedIn && (
        <>
          <SidebarContext.Provider
            value={{ ...sideBarState, setOpened: handleChange }}
          >
            <Header />
            <div
              className={`${classes.sideBarWithMainContainer} ${
                theme === 'dark' ? classes.darkBg : null
              }`}
            >
              <Sidebar
                sidebarData={permittedData}
                openedBy={sideBarState.openedBy}
              />
              <Main
                open={sideBarState.opened}
                openedBy={sideBarState.openedBy}
                className={classes.mainInLayout}
              >
                <Outlet />
              </Main>
            </div>
          </SidebarContext.Provider>
        </>
      )}
    </>
  );
};

export default Layouts;
