import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './Login.module.css';
import Input, { InputFieldType } from '../../components/Input';
import Checkbox from '../../components/form/Checkbox';
import { isEmpty } from 'lodash';
import { SignInContext, SignInContextType } from '../../contexts/signin';
import Button, { ButtonType, Type } from '../../components/Button';
import Toaster, { ToasterType } from '../../components/Toaster';
import AuthService from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import Appointment from '../../services/Appointment';
import { loginRoutes } from './loginRoutes';
interface LoginInput {
  username: string;
  password: string;
}

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors]: any = useState({});
  const [isSubmit, setIsSubmit] = useState(0);
  const [loading, setLoading] = useState(false);
  const [successToaster, setSuccessToaster] = useState(false);

  const isEnabled = email.length > 0 && password.length > 0;

  const { onSignIn, authToken } = useContext<SignInContextType>(SignInContext);

  const authService = useMemo(() => new AuthService(), []);
  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  const LoginInput: LoginInput = {
    username: email,
    password: password,
  };

  const emailHandler = (userEmail: string) => {
    setEmail(userEmail);
    LoginInput.username = userEmail;
    if (isSubmit) {
      setFormErrors(formValidation(LoginInput));
    }
  };

  const passwordHandler = (userPassword: string) => {
    setPassword(userPassword);
    LoginInput.password = userPassword;
    if (isSubmit) {
      setFormErrors(formValidation(LoginInput));
    }
  };

  const formValidation = (formValues: LoginInput) => {
    const errors: any = {};
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (!regex.test(formValues.username)) {
      errors.username = 'Must be a valid email!';
    }
    if (formValues.password.length < 8) {
      errors.password = 'Must be atleast 8 characters !';
    }
    return errors;
  };

  const navigate = useNavigate();

  const submitHandler = async (event: any) => {
    event.preventDefault();
    setIsSubmit(1);
    setFormErrors(formValidation(LoginInput));
    if (isEmpty(formValidation(LoginInput)) === true) {
      setLoading(true);
      await authService
        .signIn(LoginInput.username, LoginInput.password)
        .then(async (user) => {
          if (user) {
            appointmentService
              .getUserRolesAndPermissionsByID(user?.username)
              .then((res: any) => {
                onSignIn(
                  user,
                  res?.data?.listUserRolesAndPermissions?.items[0]
                );
                for (let i = 0; i < loginRoutes.length; i++) {
                  if (
                    Object.keys(loginRoutes[i])[0] ===
                    res?.data?.listUserRolesAndPermissions?.items[0].UserRoles
                      .UserPermissions.modularPermission[0]
                  ) {
                    navigate(Object.values(loginRoutes[i])[0]);
                    break;
                  }
                }
              })
              .catch((e: any) => {
                console.error(e);
              })
              .finally(() => setLoading(false));
          } else {
            setSuccessToaster(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        })
        .finally(() => {});
    }
  };

  return (
    <>
      <div className={classes.loginPage}>
        {successToaster && (
          <div id="Login_bad_Credentials">
            <Toaster
              title={'Error'}
              setShowToaster={setSuccessToaster}
              description={'Incorrect username/password'}
              toasterType={ToasterType.ERROR}
              timeInterval={4000}
            />
          </div>
        )}
        <form className={classes.loginForm} onSubmit={submitHandler}>
          <p className={classes.loginHead}>Login to your account</p>

          <p className={classes.loginDesc}>
            Login with your data you entered during sign up.
          </p>

          <div className={classes.inputField} id="emailField">
            <Input
              isRequired={true}
              type={InputFieldType.TEXT}
              label="Email"
              placeholder="xyz@gmail.com"
              onValueChange={emailHandler}
            />
          </div>

          {!!formErrors.username && (
            <p id="username_error" className={classes.error}>
              {formErrors.username}
            </p>
          )}

          <div className={classes.inputPasswordField} id="passwordField">
            <Input
              isRequired={true}
              type={InputFieldType.PASSWORD}
              label="Password"
              placeholder={'Type Password'}
              onValueChange={passwordHandler}
            />
          </div>

          {!!formErrors.password && (
            <p id="password_error" className={classes.error}>
              {formErrors.password}
            </p>
          )}

          <div className={classes.middleFieldLoginBox}>
            <div className={classes.middleFieldLogin}>
              <Checkbox />
              <p className={classes.loginCheckBoxText}>Keep me logged in</p>
            </div>
            <div className={classes.right}>
              <Link to="/forgotpassword">Forgot password?</Link>{' '}
            </div>
          </div>

          <div className={classes.loginButton}>
            <Button
              type={Type.SUBMIT}
              disabled={!isEnabled}
              text="Login"
              loading={loading}
              buttonType={ButtonType.FILLED}
            />
          </div>
          <div className={classes.loginFooter}>
            <p>
              Don't have an account? <Link to="/signup">Sign Up</Link>{' '}
            </p>
          </div>
        </form>
      </div>
    </>
  );
}
export default Login;
