interface BackgroundAndTextColor {
  backgroundColor: string;
  textColor: string;
}

interface AppointmentCardBackgroundAndText {
  Primary: BackgroundAndTextColor;
  Secondary: BackgroundAndTextColor;
  Danger: BackgroundAndTextColor;
}

export const cardStyle: AppointmentCardBackgroundAndText = {
  Primary: {
    backgroundColor: '#4F8BC3',
    textColor: '#FFFFFF',
  },
  Secondary: {
    backgroundColor: '#F5B049',
    textColor: '#000000',
  },
  Danger: {
    backgroundColor: '#ff4d4d',
    textColor: 'white',
  },
};

export const calculateHeightOfAppointmentCard = (
  startTime: Date,
  endTime: Date,
  appointmentCardHeight: number
): number => {
  const minute = 60;
  const startTimeHour = startTime.getHours();
  const endTimeHour = endTime.getHours();
  const startTimeMinutes = startTime.getMinutes();
  const endTimeMinutes = endTime.getMinutes();
  const difference =
    endTimeMinutes - startTimeMinutes + (endTimeHour - startTimeHour) * minute;
  const height = difference * (appointmentCardHeight / minute);
  return height;
};

export const padStartOfNumber = (
  inputNumber: number,
  requiredLength: number,
  defaultValue: string
): string => {
  return inputNumber.toString().padStart(requiredLength, defaultValue);
};

export const dateInStringFormat = (inputDate: Date): string => {
  let dateInString =
    padStartOfNumber(inputDate.getMonth() + 1, 2, '0') +
    '/' +
    padStartOfNumber(inputDate.getDate(), 2, '0') +
    '/' +
    inputDate.getFullYear();
  return dateInString;
};

export const timeInStringFormat = (inputTime: Date): string => {
  let timeInString =
    padStartOfNumber(inputTime.getHours(), 2, '0') +
    ':' +
    padStartOfNumber(inputTime.getMinutes(), 2, '0');
  return timeInString;
};

export const getDateTime = () => {
  const today = new Date();
  const date =
    today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
  const time = today.getHours() + ':' + today.getMinutes();
  return new Date(date + ' ' + time);
};

export const dateInUTCStringFormat = (inputDate: Date): string => {
  let dateInString =
    padStartOfNumber(inputDate.getUTCMonth() + 1, 2, '0') +
    '/' +
    padStartOfNumber(inputDate.getUTCDate(), 2, '0') +
    '/' +
    inputDate.getUTCFullYear();
  return dateInString;
};

export const timeInUTCStringFormat = (inputTime: Date): string => {
  let timeInString =
    padStartOfNumber(inputTime.getUTCHours(), 2, '0') +
    ':' +
    padStartOfNumber(inputTime.getUTCMinutes(), 2, '0');
  return timeInString;
};

export const getLocaleDateString = (
  date: string | Date,
  format?: string
): string => {
  return new Date(date).toLocaleDateString();
};

export const getLocaleCurrency = (price: any) => {
  const options = {
    style: 'currency',
    currency: 'USD',
  };
  return price.toLocaleString(undefined, options);
};
