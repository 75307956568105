import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { SignInContext } from '../../contexts/signin';
import Appointment from '../../services/Appointment';
import { getCookie, setCookie } from '../../utils';
import NewDropDown from '../NewDropDown/NewDropDown';
import Toggle from '../ToggleSwitch/Toggle';
import classes from './Settings.module.css';
import { FONT_TYPE } from '../../utils/constants';
import { StyledSpan, StyledDiv } from '../Styled/index';
import DropDown from '../DropDown';

const ACTIONS = {
  CALL_API: 'call-api',
  SUCCESS: 'success',
};

const initialState = {
  dentalPracticeIdOfOrganization: [],
  dentalPracticeNames: [],
  fetchingData: true,
};

const userDetailsReducer = (prevState: any, action: any) => {
  switch (action.type) {
    case ACTIONS.CALL_API: {
      return {
        ...initialState,
        fetchingData: true,
      };
    }
    case ACTIONS.SUCCESS: {
      const names = action.data.listUserRolesAndPermissions.items.map(
        (item: any) => {
          return item.DentalPractice.name;
        }
      );
      return {
        dentalPracticeIdOfOrganization: [
          { DentalPractice: { name: 'Organization data', id: '' } },
          ...action.data.listUserRolesAndPermissions.items,
        ],
        dentalPracticeNames: ['Organization data', ...names],
        fetchingData: false,
      };
    }
  }
};

const Settings = () => {
  const [dentalPracticeData, dispatch] = useReducer(
    userDetailsReducer,
    initialState
  );
  const { theme, onThemeChange, onFontChange, authToken, fontType } = useContext(SignInContext);

  const appointmentService = useMemo(
    () => new Appointment(authToken!),
    [authToken]
  );

  useEffect(() => {
    appointmentService
      .fetchDentalPractices(getCookie('OrganizationId'))
      .then((res: any) => {
        dispatch({ type: ACTIONS.SUCCESS, data: res.data });
        setCookie('selectedDentalPracticeId', '');
      })
      .catch((err) => {
        console.log(err);
      });
  }, [appointmentService]);

  const handleValueChange = (val: string) => {
    for (let i in dentalPracticeData?.dentalPracticeIdOfOrganization) {
      if (
        dentalPracticeData?.dentalPracticeIdOfOrganization[+i].DentalPractice
          .name === val
      ) {
        setCookie(
          'selectedDentalPracticeId',
          dentalPracticeData?.dentalPracticeIdOfOrganization[+i].DentalPractice
            .id
        );
      }
    }
  };

  const handleFontChange = (val: string) => {
    onFontChange(val);
  };

  const getDefaultValue = () => {
    const val = getCookie('selectedDentalPracticeId');
    if (val) {
      for (let i in dentalPracticeData?.dentalPracticeIdOfOrganization) {
        if (
          dentalPracticeData?.dentalPracticeIdOfOrganization[+i].DentalPractice
            .id === val
        ) {
          return dentalPracticeData?.dentalPracticeIdOfOrganization[+i].DentalPractice
          .name;
        }
      }
    }
    return 'Organization data';
  };

  return (
    <>
      <div
        className={`${theme === 'light' ? classes.light : classes.dark} ${
          classes.outerContainer
        }`}
      >
        <StyledSpan font={fontType}>Dark theme : </StyledSpan>
        <Toggle
          checked={theme === 'light' ? false : true}
          optionLabels={['NO', 'YES']}
          onChange={(checked: boolean) =>
            onThemeChange(`${checked ? 'dark' : 'light'}`)
          }
        />
        <div className={classes.dropDownOuter}>
          <StyledDiv font={fontType} className={classes.dropDownText}>Select Dental Practice</StyledDiv>
          <div>
            <NewDropDown
              data={dentalPracticeData?.dentalPracticeNames}
              defaultData={getDefaultValue()}
              onInputChange={(val : any) => {
                handleValueChange(val);
              }}
              placeholder={'Select'}
            />
          </div>
        </div>
        <div className={classes.dropDownOuter}>
          <StyledDiv font={fontType} className={classes.dropDownText}>Select Font</StyledDiv>
          <div>
            <DropDown
              data={FONT_TYPE}
              defaultValue={''}
              onInputChange={(val) => {
                handleFontChange(val);
              }}
              placeholder={'Select'}
              isAutoSearch
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
