import { useEffect } from 'react';
import '../../../utils/chartConfiguration';
import { Chart, ChartItem, ChartTypeRegistry } from 'chart.js';
import _isEmpty from 'lodash/isEmpty';
import styles from './ChartLegend.module.css';
export interface Dataset {
  label: string;
  backgroundColor?: string;
  borderColor?: string;
  data: any[];
  fill?: boolean;
}

interface LineChartProps {
  type: keyof ChartTypeRegistry;
  datasets: Dataset[];
  labels: string[];
  uniqueChartId: string;
  options?: object;
  plugins?: Array<any>;
}

const font = {
  family: 'Open Sans, sans-serif',
  size: 14,
  style: 'normal',
  weight: '400',
  lineHeight: '24px',
};

const defaultProps = {
  type: '',
  datasets: [],
  labels: [],
  options: {
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      legend: false,
      tooltip: {
        usePointStyle: true,
        bodyFont: font,
        boxPadding: 6,
        position: 'nearest',
        intersect: true,
        callbacks: {
          labelPointStyle: function () {
            return {
              pointStyle: 'circle',
            };
          },
        },
      },
    },
    scales: {
      yAxis: {
        ticks: {
          font: font,
          color: '#52575C',
        },
      },
      xAxis: {
        ticks: {
          font: font,
          color: '#52575C',
        },
      },
    },
  },
  plugins: [],
  uniqueChartId: 'lineChartId',
};

const ChartComponent = (props: LineChartProps): JSX.Element => {
  const { type, labels, options, plugins, datasets, uniqueChartId } = props;

  useEffect(() => {
    const createChartInstance = () => {
      const ctx = document.getElementById(uniqueChartId) as ChartItem;
      return new Chart(ctx, {
        type,
        data: {
          labels,
          datasets,
        },
        options,
        plugins,
      });
    };

    let chartInstance = {} as Chart;
    const destroy = () => {
      if (!_isEmpty(chartInstance)) {
        chartInstance.destroy();
      }
    };
    try {
      chartInstance = createChartInstance();
    } catch (err) {
      destroy();
      chartInstance = createChartInstance();
    }
    return destroy;
  }, [type, labels, datasets, uniqueChartId, options, plugins]);

  return (
    <div className={styles['chart']}>
      <canvas id={uniqueChartId} />
    </div>
  );
};

ChartComponent.defaultProps = defaultProps;

export default ChartComponent;
