import React, { useContext, useEffect, useState } from 'react';
import classes from './LeftTimeBar.module.css';
import { SchedulerGridConf } from '../../utils/schedulerGridConf';
import { SignInContext } from '../../contexts/signin';
import { StyledDiv } from '../Styled/index';

interface HeaderName {
  firstHeader: string;
  secondHeader: string;
}

interface RowTimeDuration {
  hourDuration: number;
  minuteDuration: number;
}

interface LeftTimeBarProps {
  header: HeaderName;
  startTime: string;
  endTime: string;
  timeFormat: number;
  rowTimeDuration: RowTimeDuration;
  numberOfSlot: number;
}

const rowHeight = SchedulerGridConf.rowHeight.value;
const headerHeight = SchedulerGridConf.headerHeight.value;
export const LeftTimeBar = ({
  header,
  startTime,
  endTime,
  timeFormat,
  rowTimeDuration,
  numberOfSlot,
}: LeftTimeBarProps) => {
  //only 24 hrs format for now
  const [time, setTime] = useState<string[]>([]);
  const { fontType } = useContext(SignInContext);
  useEffect(() => {
    const arr: string[] = [];
    for (let i = +startTime[1]; i <= +endTime.slice(0, 2); i++) {
      if (i.toString().length !== 2) {
        arr[i] = `0${i}:00`;
      } else {
        arr[i] = `${i}:00`;
      }
    }

    setTime(arr);
  }, [startTime, endTime]);

  function giveminutebox() {
    return [...Array(numberOfSlot)].map((_, index) => (
      <StyledDiv
        font={fontType}
        key={index}
        className={classes.minutestyle}
        style={{ flex: '50%', height: `${rowHeight}px` }}
      >
        {rowTimeDuration.minuteDuration + 'm'}
      </StyledDiv>
    ));
  }

  return (
    <>
      <div className={classes.outerbox}>
        <StyledDiv 
          font={fontType}
          className={`${classes.innerbox} ${classes.header}`}
          style={{ height: `${headerHeight}px` }}
        >
          <div className={classes.time}>{header.firstHeader}</div>
          <div className={classes.slot}>{header.secondHeader}</div>
        </StyledDiv>

        {time.map((t, i) => {
          return (
            <div key={i} className={classes.innerbox}>
              <StyledDiv
                font={fontType}
                className={classes.hourshow}
                style={{ height: `${numberOfSlot * rowHeight}px` }}
              >
                {t}
              </StyledDiv>
              {<div className={classes.minuteshow}>{giveminutebox()}</div>}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LeftTimeBar;
