import { Filter, FilterType } from '../../components/FilterOverlay/index';

export namespace UnscheduledFilterListConf {

    export const unscheduledTreatmentFilterFields: Filter[] = [
        {
          type: FilterType.TEXT,
          displayName: 'Patient Id',
          placeholder: 'Search Patient Id',
          fieldName: 'patientId',
          value: '',
        },
        {
          type: FilterType.TEXT,
          displayName: 'Patient Name',
          placeholder: 'Search Patient Name',
          fieldName: 'patientName',
          value: '',
        },
        {
          type: FilterType.NUMBER,
          displayName: 'LoMA Score',
          placeholder: 'Search LoMA Score',
          fieldName: 'lomaScore',
          value: '',
        },
      ];

  export const getFilterListFields = (): Filter[] => {
      return unscheduledTreatmentFilterFields;
  };
}
