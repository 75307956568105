import React, { useContext, useState } from 'react';
import './index.css';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SignInContext } from '../../contexts/signin';
import {StyledDiv} from '../Styled/index';

interface DatePickerPropsType {
  minDate: Date;
  maxDate: Date;
  format: string;
  label: string;
  value: Date;
  onValueChange: (item: Date) => void;
  isEnabled: boolean;
}

function DatePicker(props: DatePickerPropsType) {
  const { minDate, maxDate, format, label, value, onValueChange, isEnabled } =
    props;
  const { fontType } = useContext(SignInContext);

  const [date, setDate] = useState<Date>(value);

  return (
    <>
      <div
        className="dateBorder"
        style={
          isEnabled ? { background: '' } : { background: 'rgb(236, 236, 236)' }
        }
      >
        <div className="dateLabel">
          <StyledDiv font={fontType}>
            {label}
            <span>*</span>
          </StyledDiv>
        </div>
        <div
          className="date-Picker"
          style={
            isEnabled
              ? { background: '' }
              : { background: 'rgb(236, 236, 236)' }
          }
        >
          <DatePickerComponent
            placeholder="Select Date"
            min={minDate}
            max={maxDate}
            format={format}
            value={date}
            showClearButton={false}
            onChange={(e: any) => {
              onValueChange(e.target.value);
              setDate(e.target.value);
            }}
            enabled={isEnabled}
          />
        </div>
      </div>
    </>
  );
}

export default DatePicker;
