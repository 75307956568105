import dayjs from 'dayjs';
import { TableTypes } from '../../components/Table/tableTypes';
import { InputFieldType } from '../../components/Input';

export namespace CallShortCallListConf {
  export type CallistParams = {
    type: 'call' | 'short-call';
  };

  export const callListTitles = {
    BASIC_DETAILS: 'Basic Details',
    PREFERECES: 'Preferences',
    PAYMENT_DETAILS: 'Payment Details',
    CONTACT_DETAILS: 'Contact Details',
    APPT_DETAILS: 'Appointment Details',
    CONTACT_NOTES: 'Contact Notes',
    APPT_NOTES: 'Appointment Notes',
  };

  export const reportAggregation: TableTypes.Aggregation = {
    [callListTitles.APPT_DETAILS]: {
      title: callListTitles.APPT_DETAILS,
      align: 'horizontal',
      colSpan: 8,
      rowNumber: 1,
    },
    [callListTitles.BASIC_DETAILS]: {
      title: callListTitles.BASIC_DETAILS,
      align: 'vertical',
      colSpan: 2,
      rowNumber: 2,
    },
    [callListTitles.CONTACT_DETAILS]: {
      title: callListTitles.CONTACT_DETAILS,
      align: 'vertical',
      colSpan: 2,
      rowNumber: 2,
    },
    [callListTitles.PREFERECES]: {
      title: callListTitles.PREFERECES,
      align: 'vertical',
      colSpan: 2,
      rowNumber: 2,
    },
    [callListTitles.PAYMENT_DETAILS]: {
      title: callListTitles.PAYMENT_DETAILS,
      align: 'vertical',
      colSpan: 2,
      rowNumber: 2,
    },
    [callListTitles.CONTACT_NOTES]: {
      title: callListTitles.CONTACT_NOTES,
      align: 'vertical',
      colSpan: 4,
      rowNumber: 3,
    },
    [callListTitles.APPT_NOTES]: {
      title: callListTitles.APPT_NOTES,
      align: 'vertical',
      colSpan: 4,
      rowNumber: 3,
    },
  };

  export const callListFields: TableTypes.TableHeader[] = [
    {
      value: 'patientIdNode',
      displayName: 'Patient Id',
      filterType: InputFieldType.TEXT,
    },
    {
      value: 'Patient.name',
      displayName: 'Patient Name',
      filterType: InputFieldType.TEXT,
    },
    {
      value: 'apptDate',
      displayName: 'Appointment Date',
      textCenter: true,
      formatter: (date: string) => dayjs(date).format('MM/DD/YYYY hh:mm a'),
    },
    {
      value: 'minutes',
      displayName: 'Duration',
      textCenter: true,
    },
    { value: 'Patient.lomaScore', displayName: 'LoMA Score', textCenter: true },
    {
      value: 'recallDate',
      displayName: 'Recall Date',
      textCenter: true,
      formatter: (date: string) => dayjs(date).format('MM/DD/YYYY'),
    },
    {
      value: 'Provider.name',
      displayName: 'Primary Doctor',
      expandable: true,
      aggregationKey: callListTitles.APPT_DETAILS,
    },
    {
      value: 'Procedure.procedureName',
      displayName: 'Procedure Name',
      expandable: true,
      aggregationKey: callListTitles.APPT_DETAILS,
    },
    {
      value: 'Patient.profile.preferredInfo.preferredProviders.dentist',
      displayName: 'Provider',
      expandable: true,
      aggregationKey: callListTitles.PREFERECES,
    },
    {
      value: 'Patient.profile.preferredInfo.preferredProviders.hygienist',
      displayName: 'Hygienist',
      expandable: true,
      aggregationKey: callListTitles.PREFERECES,
    },
    {
      value: 'Patient.profile.preferredInfo.preferredDayOfWeek',
      displayName: 'Day',
      expandable: true,
      aggregationKey: callListTitles.PREFERECES,
    },
    {
      value: 'Patient.profile.preferredInfo.preferredDayOfTime',
      displayName: 'Time',
      expandable: true,
      aggregationKey: callListTitles.PREFERECES,
    },
    {
      value: 'parentName',
      displayName: 'Responsible Party',
      expandable: true,
      aggregationKey: callListTitles.BASIC_DETAILS,
    },
    {
      value: 'contacted',
      displayName: 'Contacted Today',
      sortable: true,
      textCenter: true,
    },
    {
      value: 'isConfirmedNode',
      displayName: 'Confirmed',
      textCenter: true,
      sortable: true,
    },
    {
      value: 'apptNotes',
      displayName: 'Notes',
      expandable: true,
      differentRow: true,
      aggregationKey: callListTitles.APPT_NOTES,
    },
    {
      value: 'Procedure.procedureCode',
      displayName: 'Procedure Code',
      expandable: true,
      aggregationKey: callListTitles.APPT_DETAILS,
    },
    {
      value: 'dob',
      displayName: 'DOB',
      expandable: true,
      aggregationKey: callListTitles.BASIC_DETAILS,
    },
    {
      value: 'age',
      displayName: 'Age',
      expandable: true,
      aggregationKey: callListTitles.BASIC_DETAILS,
    },
    {
      value: 'multiplePhoneNode',
      displayName: 'Contact Number',
      expandable: true,
      aggregationKey: callListTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.email',
      displayName: 'Email',
      expandable: true,
      aggregationKey: callListTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.profile.contactInfo.preferredContactMethod',
      displayName: 'Method',
      expandable: true,
      aggregationKey: callListTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.profile.paymentInformation.insuranceInfo.partyName',
      displayName: 'Insurance Party',
      expandable: true,
      aggregationKey: callListTitles.PAYMENT_DETAILS,
    },
    {
      value: 'balance',
      displayName: 'Balance',
      expandable: true,
      aggregationKey: callListTitles.PAYMENT_DETAILS,
    },
    {
      value: 'Patient.profile.paymentInformation.modeOfPayment',
      displayName: 'Mode',
      expandable: true,
      aggregationKey: callListTitles.PAYMENT_DETAILS,
    },
    {
      value: 'contactNotesNode',
      displayName: 'Notes',
      expandable: true,
      differentRow: true,
      aggregationKey: callListTitles.CONTACT_NOTES,
    },
  ];

  export const getCallListFields = (
    listType: CallistParams
  ): TableTypes.TableHeader[] => {
      return listType.type === 'short-call'
        ? callListFields.filter((list) => list.value !== 'isConfirmedNode')
        : callListFields.filter((list) => list.value !== 'recallDate');
  };

  export const getAggregationField = (listType: CallistParams): any => {
    if (listType.type === 'call' || listType.type === 'short-call') {
      return reportAggregation;
    }
  };
}
