import { ChangeEvent, useEffect, useState } from 'react';
import classes from './index.module.css';

export interface CheckboxProps {
  checked?: boolean;
  isDisabled?: boolean;
  className?: string;
  propagateClick?: boolean;
  onCheck: (isChecked: boolean) => void;
}

const defaultProps = {
  checked: false,
  isDisabled: false,
  className: '',
  propagateClick: false,
  onCheck: () => {},
};

const Checkbox = ({
  checked,
  onCheck,
  className,
  isDisabled,
  propagateClick,
}: CheckboxProps): JSX.Element => {
  const [value, setValue] = useState(checked);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setValue(newValue);
    onCheck(newValue);
  };

  return (
    <input
      type="checkbox"
      data-testid="my-checkbox"
      checked={value}
      disabled={isDisabled}
      onChange={handleChange}
      onClick={(e) => (propagateClick ? {} : e.stopPropagation())}
      className={`${classes.checkbox} ${className}`}
    />
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
