import React, { useEffect, useState } from 'react';
import classes from './index.module.css';
import classNames from 'classnames';
import _uniqueId from 'lodash/uniqueId';

interface ToggleProps {
  onChange: (value: boolean) => void;
  optionLabels: string[];
  checked?: boolean;
  disabled?: boolean;
  id?: string;
}

const defaultProps: ToggleProps = {
  id: _uniqueId(),
  checked: false,
  disabled: false,
  optionLabels: ['No', 'Yes'],
  onChange: () => {},
};

const Toggle: React.FC<ToggleProps> = ({
  id,
  checked,
  onChange,
  disabled,
  optionLabels,
}: ToggleProps) => {
  const [value, setValue] = useState<boolean>(!!checked);

  useEffect(() => {
    setValue(!!checked);
  }, [checked]);

  const handleChange = (isChecked: boolean) => {
    setValue(isChecked);
    onChange(isChecked);
  };

  return (
    <>
      <span className={classes.label_1}>{optionLabels[0]}</span>
      <div
        className={classes.toggle_switch}
        id='toggleSwitch'
        onClick={(e) => e.stopPropagation()}
      >
        <input
          id={id}
          checked={value}
          type="checkbox"
          className={classes.toggle_switch_checkbox}
          onChange={(e) => handleChange(e.target.checked)}
          disabled={disabled}
        />
        {id ? (
          <label className={classes.toggle_switch_label} htmlFor={id}>
            <span
              className={
                disabled
                  ? classNames(
                      classes.toggle_switch_inner,
                      classes.toggle_switch_disabled
                    )
                  : classes.toggle_switch_inner
              }
            />
            <span
              className={
                disabled
                  ? classNames(
                      classes.toggle_switch_switch,
                      classes.toggle_switch_disabled
                    )
                  : classes.toggle_switch_switch
              }
            />
          </label>
        ) : null}
      </div>
      <span className={classes.label_2}>{optionLabels[1]}</span>
    </>
  );
};

Toggle.defaultProps = defaultProps;

export default Toggle;
