import dayjs from 'dayjs';
import { TableTypes } from '../../components/Table/tableTypes';

export namespace UnscheduledListConf {
  export type CallistParams = {
    type: 'unscheduledTreatment';
  };

  export const unscheduledTreatmentTitles = {
    BASIC_DETAILS: 'Basic Details',
    PAYMENT_DETAILS: 'Payment Details',
    CONTACT_DETAILS: 'Contact Details',
    CONTACT_NOTES: 'Contact Notes',
    APPT_NOTES: 'Last Appointment Notes',
  };

  export const unscheduledAggregation: TableTypes.Aggregation = {
    [unscheduledTreatmentTitles.BASIC_DETAILS]: {
      title: unscheduledTreatmentTitles.BASIC_DETAILS,
      align: 'vertical',
      colSpan: 3,
      rowNumber: 1,
    },
    [unscheduledTreatmentTitles.CONTACT_DETAILS]: {
      title: unscheduledTreatmentTitles.CONTACT_DETAILS,
      align: 'vertical',
      colSpan: 2,
      rowNumber: 1,
    },
    [unscheduledTreatmentTitles.PAYMENT_DETAILS]: {
      title: unscheduledTreatmentTitles.PAYMENT_DETAILS,
      align: 'vertical',
      colSpan: 3,
      rowNumber: 1,
    },
    [unscheduledTreatmentTitles.CONTACT_NOTES]: {
      title: unscheduledTreatmentTitles.CONTACT_NOTES,
      align: 'vertical',
      colSpan: 4,
      rowNumber: 2,
    },
    [unscheduledTreatmentTitles.APPT_NOTES]: {
      title: unscheduledTreatmentTitles.APPT_NOTES,
      align: 'vertical',
      colSpan: 4,
      rowNumber: 2,
    },
  };

  export const unscheduledTreatment: TableTypes.TableHeader[] = [
    {
      value: 'patientIdNode',
      displayName: 'Patient Id',
    },
    {
      value: 'Patient.name',
      displayName: 'Patient Name',
    },
    {
      value: 'Provider.name',
      displayName: 'Primary Doctor',
    },
    {
      value: 'dateOfPrescription',
      displayName: 'Date of Prescription',
      formatter: (date: string) => dayjs(date).format('MM/DD/YYYY'),
    },
    {
      value: 'prescribedTrt',
      displayName: 'Prescribed Treatment',
    },
    {
      value: 'Patient.lomaScore',
      displayName: 'LoMA Score',
      textCenter: true,
    },
    {
      value: 'contacted',
      displayName: 'Contacted Today',
      sortable: false,
      textCenter: true,
    },
    {
      value: 'contactNotesNode',
      displayName: 'Contact Notes',
      expandable: true,
      differentRow: true,
      aggregationKey: unscheduledTreatmentTitles.CONTACT_NOTES,
    },
    {
      value: 'parentName',
      displayName: 'Responsible Party',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.BASIC_DETAILS,
    },
    {
      value: 'dob',
      displayName: 'DOB',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.BASIC_DETAILS,
      // formatter: (date: string) => dayjs(date).format('MM/DD/YYYY'),
    },
    {
      value: 'age',
      displayName: 'Age',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.BASIC_DETAILS,
    },
    {
      value: 'apptNotes',
      displayName: '',
      expandable: true,
      differentRow: true,
      aggregationKey: unscheduledTreatmentTitles.APPT_NOTES,
    },
    {
      value: 'multiplePhoneNode',
      displayName: 'Contact Number',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.email',
      displayName: 'Email',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.profile.contactInfo.preferredContactMethod',
      displayName: 'Method',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.CONTACT_DETAILS,
    },
    {
      value: 'Patient.profile.paymentInformation.insuranceInfo.partyName',
      displayName: 'Insurance Party',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.PAYMENT_DETAILS,
    },
    {
      value: 'balance',
      displayName: 'Balance',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.PAYMENT_DETAILS,
    },
    {
      value: 'Patient.profile.paymentInformation.modeOfPayment',
      displayName: 'Mode',
      expandable: true,
      aggregationKey: unscheduledTreatmentTitles.PAYMENT_DETAILS,
    },
  ];

  export const getCallListFields = (): TableTypes.TableHeader[] => {
      return unscheduledTreatment;
  };

  export const getAggregationField = (): any => {
      return unscheduledAggregation;
  };
}
