import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import dayjs from 'dayjs';
import SchedulerGrid from '.';
import CalenderDatePicker from '../CalenderDatePicker';
import { numberToStringTime } from '../../utils/constants';
import { SignInContext } from '../../contexts/signin';
import Loader from '../Loader/Loader';
import classes from './CalendarView.module.css';
import Legend, { Align } from '../Legend/index';
import {
  legendDataforBooked,
  legendDataforCancelled,
} from '../Legend/LegendTestData';
import LegendProviderViewData from '../Legend/LegendProviderViewData';
import classNames from 'classnames';
import { ReactComponent as FullSizeIcon } from '../../images/FullSizeIcon.svg';
import Appointment from '../../services/Appointment';
// import { ApptView } from './schedulerTypes';
import Input from '../Input';

interface CalenderViewProps {
  isCancelled: boolean;
}

const initialState: any = {
  loading: true,
  appointmentData: [],
  providerData: [],
  unfilteredData: [],
  dataForScheduler: [],
};

const reducer = (prevState: any, action: any) => {
  switch (action.type) {
    case 'LOADING_FALSE': {
      return {
        ...prevState,
        loading: false,
      };
    }

    case 'SET_APPT_AND_PROVIDER': {
      return {
        ...prevState,
        appointmentData: action.value[0],
        providerData: action.value[1],
      };
    }

    case 'SET_UNFILTERED_DATA': {
      // console.log(" ......", prevState.appointmentData)
      const temp = prevState?.providerData.map((d: any) => {
        return {
          name: d.name,
          providerType: d.providerType,
          _id: d.id,
          availableTimeSlots: d.availableTimeSlots?.map((d: any) => {
            return {
              endTime: numberToStringTime(d.endTime),
              startTime: numberToStringTime(d.startTime),
              slotType: 'available',
              timeFormat: 24,
            };
          }),
          // adding key, "appointmentDetails" and its values inside object
          appointmentDetails: [
            {
              appointments: prevState?.appointmentData?.filter((appt: any) => {
                return appt.Provider.id === d.id;
              }),
              doctorId: d.providerId,
              name: d.name,
              _id: d.id,
            },
          ],
        };
      });

      return {
        ...prevState,
        unfilteredData: temp,
      };
    }

    case 'SORTED_DATA': {
      return {
        ...prevState,
        unfilteredData: action.value,
      };
    }

    case 'SET_LOADER_TRUE': {
      return {
        ...prevState,
        loading: true,
      };
    }
    default: {
      return prevState;
    }
  }
};

function NewCalendarView(props: CalenderViewProps) {
  const [allData, dispatch]: any = useReducer(reducer, initialState);

  const [dataForScheduler, setDataForScheduler] = useState<any>([]);
  const [reRenderData, setReRenderData] = useState(false);
  const [fullView, setFullView] = useState(false);
  const { authToken } = useContext(SignInContext);
  // const [apptView, setApptView] = useState<ApptView>('Primary');
  const apptView = 'Primary';
  const [appointmentService] = useState(new Appointment(authToken!));
  const [dataForCancelledAppt, setDataForCancelledAppt] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [provider, setProvider] = useState<string>('');
  const reRenderAppointments = () => {
    setReRenderData(!reRenderData);
  };
  
  const setCurrentDateOfSchedulerGrid = (val: Date) => {
    setCurrentDate(val);
  };

  const formattedDate = useMemo<string>(() => {
    if (currentDate) return dayjs(currentDate).format('YYYY-MM-DD');
    return '';
  }, [currentDate]);

  const getStartAndEndTime = useCallback((data: any) => {
    const providers: any = [...data];
    const startTime: number[] = [];
    const endTime: number[] = [];
    providers?.forEach((provider: any) => {
      provider.availableTimeSlots?.forEach((slot: any) => {
        startTime.push(slot.startTime);
        endTime.push(slot.endTime);
      });
    });
    function getClockTime(time: string) {
      if (time.length !== 4) {
        return `0${time[0]}:00`;
      } else {
        return `${time.slice(0, 2)}:00`;
      }
    }
    return [
      getClockTime(Math.min(...startTime).toString()),
      getClockTime(Math.max(...endTime).toString()),
    ];
  }, []);

  useEffect(() => {
    function getSchedulerData() {
      dispatch({ type: 'SET_LOADER_TRUE' });
      appointmentService
        .fetchGridData(
          dayjs(formattedDate).format('YYYY-MM-DD'),
          dayjs(formattedDate).format('dddd').toLowerCase(),
          props.isCancelled
        )
        .then((res) => {
          dispatch({
            type: 'SET_APPT_AND_PROVIDER',
            value: [
              res.data.listAppointments.items,
              res.data.listProviders.items,
            ],
          });
        })
        .catch((e: any) => {
          console.error('error: ', e);
        })
        .finally(() => {
          dispatch({ type: 'LOADING_FALSE' });
        });
    }
    if (formattedDate) {
      getSchedulerData();
    }
  }, [
    authToken,
    formattedDate,
    reRenderData,
    appointmentService,
    props.isCancelled,
  ]);

  useEffect(() => {
    const [sTime, eTime] = getStartAndEndTime(allData?.providerData);
    setStartTime(sTime);
    setEndTime(eTime);
    dispatch({ type: 'SET_UNFILTERED_DATA' });
  }, [
    getStartAndEndTime,
    // allData.appointmentData,
    allData.providerData,
    startTime,
    endTime,
  ]);

  useEffect(() => {
    const sorted_categories = allData?.unfilteredData?.sort(function (
      a: any,
      b: any
    ) {
      if (
        a.appointmentDetails[0].appointments.length ===
        b.appointmentDetails[0].appointments.length
      ) {
        return a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
          ? 1
          : -1;
      } else
        return (
          b.appointmentDetails[0].appointments.length -
          a.appointmentDetails[0].appointments.length
        );
    });
    dispatch({ type: 'SORTED_DATA', value: sorted_categories });
  }, [allData.unfilteredData]);

  const providerHandler = (val: string) => {
    setProvider(val);
  };

  useEffect(() => {
    if (provider.length === 0) setDataForScheduler(allData?.unfilteredData);
    else if (provider.toLowerCase().trim() === 'dentist') {
      setDataForScheduler(
        allData?.unfilteredData?.filter((val: any) => {
          return val.providerType.toLowerCase().trim() === 'dentist';
        })
      );
    } else if (provider.toLowerCase().trim() === 'hygienist') {
      setDataForScheduler(
        allData?.unfilteredData?.filter((val: any) => {
          return val.providerType.toLowerCase().trim() === 'hygienist';
        })
      );
    } else {
      setDataForScheduler(
        allData?.unfilteredData?.filter((val: any) => {
          return val.name
            .toLowerCase()
            .trim()
            .includes(provider.toLowerCase().trim());
        })
      );
    }
  }, [provider, allData.unfilteredData, currentDate]);

  useEffect(() => {
    setDataForCancelledAppt(
      dataForScheduler.filter((val: any) => {
        return !(val.appointmentDetails[0].appointments.length === 0);
      })
    );
  }, [dataForScheduler]);

  return (
    <div
      className={classNames({
        [classes.calenderFull]: fullView,
        [classes.calender]: !fullView,
      })}
    >
      <div
        className={classNames(
          'd-flex justify-content-between align-items-center flex-wrap',
          classes.calendarHeader
        )}
      >
        <div
          onClick={() => setFullView(!fullView)}
          className={classes.fullViewButton}
          id="maximizeMinimizeButton"
        >
          {fullView ? 'Minimize' : <FullSizeIcon />}
        </div>

        <div className={classes.searchProvider}>
          <Input
            placeholder="Search for provider"
            onValueChange={providerHandler}
          />
          {!props.isCancelled && (
            <div title="Total providers" className={classes.count}>
              {dataForScheduler.length}
            </div>
          )}
        </div>
        <div
          className={classNames(
            'd-flex align-items-center',
            classes.datePicker
          )}
        >
          <CalenderDatePicker
            minDate={props.isCancelled ? new Date() : new Date('01/01/2000')}
            maxDate={new Date('01/01/2099')}
            format={'MM/dd/yyyy'}
            value={currentDate}
            onValueChange={setCurrentDate}
          />
        </div>
        <div className={classes.practice_name}>Practice Name</div>
        <div className={classes.legendComponent}>
          {apptView === 'Primary' ? (
            <Legend
              align={Align.hor}
              legendData={
                props.isCancelled
                  ? [...legendDataforCancelled]
                  : [...legendDataforBooked]
              }
            />
          ) : (
            <Legend
              align={Align.hor}
              legendData={[...LegendProviderViewData]}
            />
          )}
        </div>
      </div>
      <>
        {allData?.loading ? (
          <div className={classes.loaderCenter}>
            <Loader type="round" />
          </div>
        ) : (
          <div
            className={classNames({
              [classes.schedulerGridContainerFull]: fullView,
            })}
          >
            <SchedulerGrid
              hourHeader="Time"
              minuteHeader="Slot"
              fullDayTime={{
                startTime: startTime || '',
                endTime: endTime || '',
                timeFormat: 24,
              }}
              rowTimeDuration={{
                hourDuration: 1,
                minuteDuration: 30,
              }}
              data={
                props.isCancelled
                  ? dataForCancelledAppt
                  : provider
                  ? dataForScheduler
                  : allData.unfilteredData
              }
              view={apptView}
              apptCardView={apptView}
              reRenderAppointments={reRenderAppointments}
              currentDate={currentDate}
              setCurrentDateOfSchedulerGrid={setCurrentDateOfSchedulerGrid}
              isCancelled={props.isCancelled}
              isFull={fullView}
            />
          </div>
        )}
      </>
    </div>
  );
}

export default NewCalendarView;
