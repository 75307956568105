export const SchedulerGridConf = {
  hoursDuration: 1, // hours difference to show
  slotsPerHour: 2, // 2 or 4
  minutesPerSlot: 30, //30
  totalWorkingHours: 12,
  rowHeight: {
    value: 45, // 45
    unit: 'px',
  },
  columWidth: {
    value: 179, // 179
    unit: 'px',
  },
  headerHeight: {
    value: 72, // 72
    unit: 'px',
  },
  time: {
    startTime: '06:00',
    endTime: '18:00',
    formate: 24, // 12 or 24
  },
  color: {
    primary: {
      available: 'rgba(122, 245, 178)',
      unavailable: '#DDF9E9',
    },
    secondary: {
      available: 'rgba(250, 247, 173)',
      unavailable: '#FFFDCF',
    },
  },
};

export const giveHeight = (sTime: string, eTime: string) => {
  const hours: number =
    parseInt(eTime[0] + eTime[1]) - parseInt(sTime[0] + sTime[1]);
  const minutes: number =
    parseInt(eTime[3] + eTime[4]) - parseInt(sTime[3] + sTime[4]) + hours * 60;
  const pixPerMinute =
    (SchedulerGridConf.slotsPerHour * SchedulerGridConf.rowHeight.value) / 60;
  const heightInpixels = pixPerMinute * minutes;

  return heightInpixels / SchedulerGridConf.hoursDuration;
};

export const getNumericTime_24 = (time: string) => {
  // 08:00 or 8:00
  if (time.length === 5) {
    return parseInt(`${time[0]}${time[1]}${time[3]}${time[4]}`);
  } else if (time.length === 4) {
    return parseInt(`${time[0]}${time[2]}${time[3]}`);
  }
};

export const getStringTime_24 = (time: number) => {
  // 830 or 1050 or 0900
  const tym = time.toString();
  if (tym.length === 3) {
    return `0${tym[0]}:${tym[1]}${tym[2]}`;
  } else if (tym.length === 4) {
    return `${tym[0]}${tym[1]}:${tym[2]}${tym[3]}`;
  }
};

export const getTimeDifference = (startTime: number, endTime: number) => {};
